/**
 * @Author: Mufsir
 * @Date:   18-May-2017 17:41 +05:30
 * @Project: Ezyprocure
 * @Filename: csv-export.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-12-04T10:12:32+05:30
 */



(function () {
    'use strict';

    angular.module('ezyprocureApp').factory('CSVExportService', CSVExportService);

    CSVExportService.$inject = ['$http', '$q', '$filter', 'toastr', 'API_CONSTANTS', 'GLOBAL_CONSTANTS', 'EXPORT_CONSTANTS', 'Auth', 'AuthServerProvider', 'BuyerAdhocPOFormService'];

    function CSVExportService($http, $q, $filter, toastr, API_CONSTANTS, GLOBAL_CONSTANTS, EXPORT_CONSTANTS, Auth, AuthServerProvider, BuyerAdhocPOFormService) {

        var service = {
            getIndividualAxsCsv: getIndividualAxsCsv,
            getConsolidatedAxsCsv: getConsolidatedAxsCsv,
            getConsentsListCsv: getConsentsListCsv,
            getPurchaseOrderCSV: getPurchaseOrderCSV,
            grnConsolidatedCSV: grnConsolidatedCSV,
            getConsolidatedPurchaseOrderCSV: getConsolidatedPurchaseOrderCSV,
            getGEOInvoiceCSV: getGEOInvoiceCSV,
            getBatchGEOInvoiceCSV: getBatchGEOInvoiceCSV,
            getQuickBooksCSV: getQuickBooksCSV,
            getAccPacCSV: getAccPacCSV,
            getBatchQuickBooksCSV: getBatchQuickBooksCSV,
            getXEROInvoiceCSV: getXEROInvoiceCSV,
            getGoodsReceiptNoteCSV: getGoodsReceiptNoteCSV,
            getCreditNoteCSV: getCreditNoteCSV,
            getGoodsReturnNoticeCSV: getGoodsReturnNoticeCSV,
            getStaffInterfaceCSV: getStaffInterfaceCSV,
            getBuyerERPCSVDataCSV: getBuyerERPCSVDataCSV,
            getBuyerPurchaseOrderReportCSV: getBuyerPurchaseOrderReportCSV,
            getBuyerGoodsReceiptNoteReportCSV: getBuyerGoodsReceiptNoteReportCSV,
            getBuyerGoodsReturnNoticeReportCSV: getBuyerGoodsReturnNoticeReportCSV,
            getBuyerInvoiceReportCSV: getBuyerInvoiceReportCSV,
            getBuyerPriceUpdateHistoryReportCSV: getBuyerPriceUpdateHistoryReportCSV,
            getSupplierPurchaseOrderReportCSV: getSupplierPurchaseOrderReportCSV,
            getSupplierGoodsReturnNoticeReportCSV: getSupplierGoodsReturnNoticeReportCSV,
            getSupplierInvoiceReportCSV: getSupplierInvoiceReportCSV,
            getSupplierPriceUpdateHistoryReportCSV: getSupplierPriceUpdateHistoryReportCSV,
            getSupplierGoodsReceiptNoteReportCSV: getSupplierGoodsReceiptNoteReportCSV,
            getSupplierCreditNoteReportCSV: getSupplierCreditNoteReportCSV,
            getSupplierPOReportCSV:getSupplierPOReportCSV,
            getAcceptedItemQuantity: getAcceptedItemQuantity,
            getTotalPurchaseVolumeCSV: getTotalPurchaseVolumeCSV,
            getTopSuppliersCSV: getTopSuppliersCSV,
            getTopBuyersCSV: getTopBuyersCSV,
            getBuyerGoodReceiveDataReportCSV: getBuyerGoodReceiveDataReportCSV,
            getitemPricingCSV: getitemPricingCSV,
            getTotalPurchaseVolumeReportCSV: getTotalPurchaseVolumeReportCSV,
            getBOPurchaseReportReportCSV: getBOPurchaseReportReportCSV,
            getBuyerExpensesExportReportCSV: getBuyerExpensesExportReportCSV,
            getBuyerPoSummaryCSV: getBuyerPoSummaryCSV,
            getTopPurchasedCSV: getTopPurchasedCSV,
            getBuyerAuditLogHistoryReportCSV: getBuyerAuditLogHistoryReportCSV,
            getCostingCSV: getCostingCSV,
            getPriceTrendsCSV: getPriceTrendsCSV,
            getPriceUpdateMovementCSV: getPriceUpdateMovementCSV,
            getBuyerTransactionVolumeSummaryCSV: getBuyerTransactionVolumeSummaryCSV,
            getSupplierTransactionVolumeSummaryCSV: getSupplierTransactionVolumeSummaryCSV,
            getConsolidatedInvoiceCSV: getConsolidatedInvoiceCSV,
            validateExport: validateExport,
            getFoodCostPrice: getFoodCostPrice,
            getFoodCostCSVData: getFoodCostCSVData,
            getSupplierItemPricingCSV: getSupplierItemPricingCSV,
            getSupplierStockReportByOutletsCSV: getSupplierStockReportByOutletsCSV,
            getSupplierStockReportByItemsCSV: getSupplierStockReportByItemsCSV,
            getTransactionSummaryReportsCSV: getTransactionSummaryReportsCSV,
            getNfrndsTransactionSummaryReportsCSV: getNfrndsTransactionSummaryReportsCSV,
            getPurchaseTrendCSV: getPurchaseTrendCSV,
            getSupplierPerformanceCSVData: getSupplierPerformanceCSVData,
            getNewItemLogCSVData: getNewItemLogCSVData,
            getGrVarianceCSVData: getGrVarianceCSVData,
            getPriceTrendsRawDataCSV: getPriceTrendsRawDataCSV,
            getMonthlyPriceTrendsReportsCSV: getMonthlyPriceTrendsReportsCSV,
            getQuarterlyPriceTrendsReportsCSV: getQuarterlyPriceTrendsReportsCSV,
            getDailyPriceTrendsReportsCSV: getDailyPriceTrendsReportsCSV,
            getPurchaseOrderListingCSV: getPurchaseOrderListingCSV,
            getStatementOfAccountReportCSV: getStatementOfAccountReportCSV,
            getStatementOfAccountReportNfrndsCSV: getStatementOfAccountReportNfrndsCSV,
            getExportToMyob: getExportToMyob,
            getQuickBooksSupplierInvoiceCSV: getQuickBooksSupplierInvoiceCSV,
            getPurchaseInvoicesJurnalCSV: getPurchaseInvoicesJurnalCSV,
            getSalesInvoicesJurnalCSV: getSalesInvoicesJurnalCSV,
            getWarehousePOCSVData: getWarehousePOCSVData,
            getWarehouseSOCSVData: getWarehouseSOCSVData,
            getWarehouseSRCSVData: getWarehouseSRCSVData,
            getWarehouseTOCSVData: getWarehouseTOCSVData,
            getBuyerPOCSVData: getBuyerPOCSVData,
            getBuyerSODCSVata: getBuyerSODCSVata,
            getBuyerSRCSVData: getBuyerSRCSVData,
            getBuyerTOCSVData: getBuyerTOCSVData,
            exportPurchaseInvoiceXeroCSV: exportPurchaseInvoiceXeroCSV,
            exportSalesInvoiceXeroCSV: exportSalesInvoiceXeroCSV,
            getOrderTemplateCSVDataById: getOrderTemplateCSVDataById,
            getBuyNowOrderTemplateCSVData: getBuyNowOrderTemplateCSVData,
            getMillionOrderTemplateCSVData: getMillionOrderTemplateCSVData,
            generateARMillionOrderCSVData: generateARMillionOrderCSVData,
            getPeppolInvoiceCSV: getPeppolInvoiceCSV,
            getPeppolPoCSV: getPeppolPoCSV,
            getSupplierConsolidatedInvoiceCSV: getSupplierConsolidatedInvoiceCSV,
            getSupplierItemMappingCSV: getSupplierItemMappingCSV,
            getExportToMyobFile: getExportToMyobFile,
            getUenConsolidatedCSV: getUenConsolidatedCSV,
            getExportReorderLevelReport: getExportReorderLevelReport,
            getForecastCSV: getForecastCSV,
            getSAGA50CSV:getSAGA50CSV,
            generateexportSAGACSVData: generateexportSAGACSVData,
            getGlCodeMappingsCsv: getGlCodeMappingsCsv,
            manageItemsCSV: manageItemsCSV,
            getAx2PaymentsCsv: getAx2PaymentsCsv,
            manageItemsCSVForInventory: manageItemsCSVForInventory,
            getQuickBooksItemsCSV:getQuickBooksItemsCSV,
            getBlanketOrderCSVDataById: getBlanketOrderCSVDataById,
            getConsolidatedPurchaseRequestCSV:getConsolidatedPurchaseRequestCSV,
            getStockTransferCSVData: getStockTransferCSVData,
            getEGiroFundTransferCSV: getEGiroFundTransferCSV,
            getReplicateItemsCSV:getReplicateItemsCSV,
            getPriceUpdateCSV: getPriceUpdateCSV,
            getConsolidatedCSVPO: getConsolidatedCSVPO,
            formatPurchaseOrderNumber: formatPurchaseOrderNumber,
            // getBnplReport:getBnplReport,
            // getBnplTransactionSummaryReportsCSV:getBnplTransactionSummaryReportsCSV,
            getPurchaseRequestListingCSV: getPurchaseRequestListingCSV,
            getStockNeedReportsCSVData:getStockNeedReportsCSVData,
            getSQLCSV:getSQLCSV,
            validateInvoiceStatus: validateInvoiceStatus,
            getSubscriptionManagementReportsCSVData:getSubscriptionManagementReportsCSVData,
            getBuyerSubscriptionListingCSV: getBuyerSubscriptionListingCSV,
            getSupplierSubscriptionListingCSV: getSupplierSubscriptionListingCSV,
            getAutoCountCSV:getAutoCountCSV
        };

        var accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return service;

        function getPriceUpdateCSV(priceUpdateItems) {
            var priceUpdateCSVDataArray = [];
            var priceUpdateCSVData = {};
            for (var i = 0; i < priceUpdateItems.length; i++) {
                var data = priceUpdateItems[i];
                priceUpdateCSVData.S_no = i + 1;
                priceUpdateCSVData.Item_Name = data.items.itemName;
                priceUpdateCSVData.Item_Code = data.items.itemCode;
                priceUpdateCSVData.Item_Group = data.items.itemGroup.name;
                priceUpdateCSVData.UOM = data.items.uom.name;
                priceUpdateCSVData.Supplier = data.items.supplier.companyName;
                priceUpdateCSVData.Business_Outlet = data.buyerBusiness.businessOutletName;
                priceUpdateCSVData.Effective_From = $filter('date')(data.effectiveStartDate, "dd/MM/yyyy", timeZone);
                priceUpdateCSVData.Effective_To = $filter('date')(data.effectiveEndDate, "dd/MM/yyyy", timeZone);
                priceUpdateCSVData.Updated_Date = $filter('date')(data.modifiedDate, "dd/MM/yyyy", timeZone);
                priceUpdateCSVData.Remark = data.remark;
                priceUpdateCSVData.Price = $filter('decimalNumber')(data.previousPrice ? data.previousPrice : 0, 4);
                priceUpdateCSVData.Updated_Price = $filter('decimalNumber')(data.updatedPrice ? data.updatedPrice : 0, 4);
                var temp = angular.copy(priceUpdateCSVData);
                priceUpdateCSVDataArray.push(temp);
            }
            return priceUpdateCSVDataArray;
        }
        function getIndividualAxsCsv(data) {
            var csvDataList = [];
            data.forEach(function(transaction, index) {
                var csvData = {};
                csvData.slNo = index + 1;
                csvData.transactionId = transaction.transactionId;
                csvData.transactionTimeStamp = $filter('date')(transaction.transactionTimeStamp, "dd/MM/yyyy HH:mm:ss", timeZone);
                csvData.supplier = transaction.supplier;
                csvData.brandName = transaction.brandName;
                csvData.issuer = transaction.issuer;
                csvData.issuedCountry = transaction.issuedCountry;
                csvData.issuedBank = transaction.issuedBank;
                csvData.accountType = transaction.accountType;
                csvData.invoiceNumber = transaction.invoiceNumber;
                csvData.amount = $filter('decimalNumber')(transaction.amount ? transaction.amount : null, 2);
                csvData.buyerBsfFee = $filter('decimalNumber')(transaction.buyerBsfFee ? transaction.buyerBsfFee : null, 2);
                csvData.buyerBsfGst = $filter('decimalNumber')(transaction.buyerBsfGst ? transaction.buyerBsfGst : null, 2);
                csvData.buyerTotal = $filter('decimalNumber')(transaction.buyerTotal ? transaction.buyerTotal : null, 2);
                csvData.supplierBsfFee = $filter('decimalNumber')(transaction.supplierBsfFee ? transaction.supplierBsfFee : null, 2);
                csvData.supplierBsfGst = $filter('decimalNumber')(transaction.supplierBsfGst ? transaction.supplierBsfGst : null, 2);
                csvData.supplierTotal = $filter('decimalNumber')(transaction.supplierTotal ? transaction.supplierTotal : null, 2);
                csvData.paymentModel = transaction.paymentModel;
                csvData.totalAmountPayable = $filter('decimalNumber')(transaction.totalAmountPayable ? transaction.totalAmountPayable : null, 2);
                csvData.remittanceAdvice = $filter('decimalNumber')(transaction.remittanceAdvice ? transaction.remittanceAdvice : null, 2);
                csvData.currency = transaction.currency;
                csvData.giroStatus = transaction.giroStatus ? 'GENERATED' : 'NOT GENERATED'
                csvDataList.push(csvData);
            })
            return csvDataList;
        }

        function getConsolidatedAxsCsv(data) {
            var csvDataList = [];
            data.forEach(function (transaction, index) {
                var csvData = {};
                csvData.slNo = index + 1;
                csvData.supplier = transaction.supplier;
                csvData.giroFileName = transaction.giroFileName;
                csvData.fileAccessAck = transaction.fileAccessAck || 'NA';
                csvData.transactionStatus = transaction.transactionStatus;
                csvData.amount = $filter('decimalNumber')(transaction.amount ? transaction.amount : null, 2);
                csvData.buyerBsfFee = $filter('decimalNumber')(transaction.buyerBsfFee ? transaction.buyerBsfFee : null, 2);
                csvData.buyerBsfGst = $filter('decimalNumber')(transaction.buyerBsfGst ? transaction.buyerBsfGst : null, 2);
                csvData.buyerTotal = $filter('decimalNumber')(transaction.buyerTotal ? transaction.buyerTotal : null, 2);
                csvData.supplierBsfFee = $filter('decimalNumber')(transaction.supplierBsfFee ? transaction.supplierBsfFee : null, 2);
                csvData.supplierBsfGst = $filter('decimalNumber')(transaction.supplierBsfGst ? transaction.supplierBsfGst : null, 2);
                csvData.supplierTotal = $filter('decimalNumber')(transaction.supplierTotal ? transaction.supplierTotal : null, 2);
                csvData.totalAmountPayable = $filter('decimalNumber')(transaction.totalAmountPayable ? transaction.totalAmountPayable : null, 2);
                csvData.remittanceAdvice = $filter('decimalNumber')(transaction.remittanceAdvice ? transaction.remittanceAdvice : null, 2);
                csvData.currency = transaction.currency;
                csvData.giroStatus = transaction.giroStatus ? 'GENERATED' : 'NOT GENERATED'
                csvDataList.push(csvData);
            });
            return csvDataList;
        }

        function getConsentsListCsv(consentsList) {
            var csvList = [];
            csvList = consentsList.map(function(consent, index) {
                var csvData = {
                    slNo: index + 1,
                    supplier: consent.supplier,
                    optInDate: $filter('date')(consent.optInDate, "dd/MM/yyyy HH:mm:ss", timeZone),
                    screenShot: consent.screenShot,
                    optInStatus: consent.optInStatus == 'ACCEPT' ? 'ACCEPTED' : consent.optInStatus == 'REJECT' ? 'REJECTED' : consent.optInStatus,
                    opsStatus: consent.opsStatus
                }
                return csvData;
            })
            return csvList;
        }

        function getBlanketOrderCSVDataById(blanketOrderData, blanketOrder){
            var blanketOrderArray = [];
            var blanketOrderObj = {};
            _.forEach(blanketOrderData.content, function(items){
                blanketOrderObj.blanketOrderNumber = blanketOrder.blanketOrderNumber;
                blanketOrderObj.validityStartDate = $filter('date')(blanketOrder.validityStartDate, "dd/MM/yyyy", timeZone);
                blanketOrderObj.validityEndDate = $filter('date')(blanketOrder.validityEndDate, "dd/MM/yyyy", timeZone);
                blanketOrderObj.itemCode = items.items.itemCode;
                blanketOrderObj.itemName = items.items.itemName;
                blanketOrderObj.quantity = items.quantity;
                blanketOrderObj.UOM = items.items.uom.name;
                blanketOrderObj.currency = items.items.currency;
                blanketOrderObj.price = items.price;
                blanketOrderObj.remarks = items.remarks;
                blanketOrderObj.prQuantity = items.prQuantity;
                blanketOrderObj.poQuantity = items.poQuantity;
                blanketOrderArray.push(blanketOrderObj);
                blanketOrderObj = {};
            });

            return blanketOrderArray;
        }

        function getAx2PaymentsCsv(data){
            var sattementsReportArray = [];
            var reportObj = {};
            _.forEach(data, function(each, index){
                reportObj.SNo = index + 1;
                reportObj.partnerCode = each.brand.buyer.buyerCode;
                reportObj.payerId = each.payerId;
                reportObj.payerName = each.payerName;
                reportObj.payerUEN = each.payerUEN;
                reportObj.payerEmail = each.payerEmail;
                reportObj.payeeName = each.payeeName;
                reportObj.payeeEmail = each.payeeEmail;
                reportObj.accountName = each.receivingAccountName;
                reportObj.accountNumber = each.receivingAccountNo;
                reportObj.bankName = each.bankName;
                reportObj.bankCode = each.bankCode;
                reportObj.swiftCode = each.swiftCode;
                reportObj.referenceNumber = each.referenceNumber;
                reportObj.amount = each.amount;
                reportObj.currency = each.currency;
                reportObj.purpose = each.purpose;
                reportObj.accessStatus = each.accessStatus;
                reportObj.giroType = each.giroType;
                reportObj.payType = each.payType;
                reportObj.fate =  each.fate;
                reportObj.fateRemarks =  each.fateRemarks;
                sattementsReportArray.push(reportObj);
                reportObj = {};
            });
            return sattementsReportArray;
        }

        function manageItemsCSV(data){
            var itemsArray = [];
            var itemsObj = {}
            _.forEach(data, function(item, index){
                itemsObj.slNo = index + 1;
                itemsObj.itemCode = item.itemCode ? item.itemCode : '';
                itemsObj.itemName = item.itemName ? item.itemName : '';
                itemsObj.itemGroup = item.itemGroup ? item.itemGroup.name : '';
                itemsObj.UOM = item.uom.name ? item.uom.name : '';
                itemsObj.currency = item.currency ? item.currency : '';
                itemsArray.push(itemsObj);
                itemsObj = {};
            });
            return itemsArray;
        }

        function manageItemsCSVForInventory(data){
            var itemsArray = [];
            var itemsObj = {}
            _.forEach(data, function(item, index){
                itemsObj.srNo = index + 1;
                itemsObj.ezyprocureItemId = item.id;
                itemsObj.defaultSupplierName = item.supplier.companyName;
                itemsObj.subCategoryName = "";
                itemsObj.itemCode = item.itemCode ? item.itemCode : '';
                itemsObj.itemName = item.itemName ? item.itemName : '';
                itemsObj.uom = item.uom.name ? item.uom.name : '';
                itemsObj.minLevel = '';
                itemsObj.maxLevel = '';
                itemsObj.reOrderLevel = '';
                itemsObj.reorderQuantity = '';
                itemsObj.defaultLocationCode = '';
                itemsObj.defaultWarehouseCode = '';
                itemsObj.defaultBinCode = '';
                itemsObj.batchManagement = '';
                itemsObj.col1 = '';
                itemsObj.col2 = '';
                itemsObj.col3 = '';
                itemsObj.col4 = '';
                itemsObj.col5 = '';
                itemsObj.col6 = '';
                itemsArray.push(itemsObj);
                itemsObj = {};
            });
            return itemsArray;
        }

        function getExportReorderLevelReport(data) {
            var month = new Date().getMonth() + 1;
            var reorderLevelReportArray = [];
            var reOrderLevelReportData = {};
            _.forEach(data, function (each) {
                reOrderLevelReportData.businessOutletName = each.locationName;
                reOrderLevelReportData.supplierType = 'ALLOCATED';
                reOrderLevelReportData.supplierCompanyName = each.supplierName;
                reOrderLevelReportData.purchaseOrderType = '';
                reOrderLevelReportData.itemCode = each.itemCode;
                reOrderLevelReportData.itemName = each.itemName;
                reOrderLevelReportData.itemGroup = '';
                reOrderLevelReportData.quantity = each.reorderQty;
                reOrderLevelReportData.deliveryDate = new Date().getDate() + 1 + '-' + month + '-' + new Date().getFullYear();;
                var temp = angular.copy(reOrderLevelReportData);
                reorderLevelReportArray.push(temp);
                reOrderLevelReportData = {};
            });
            return reorderLevelReportArray;
        }

        function getUenConsolidatedCSV(uenLogs) {
            var uenConsolidatedArray = [];
            var uenConsolidatedData = {};
            _.forEach(uenLogs, function (each) {
                uenConsolidatedData.Uen_Peppol_Id = each.uenId;
                uenConsolidatedData.Volume_Invoice_Sent = each.sentInvoices;
                uenConsolidatedData.Volume_Invoice_Received = each.receivedInvoices;
                uenConsolidatedData.Total_Transactions = each.receivedInvoices + each.sentInvoices;
                var temp = angular.copy(uenConsolidatedData);
                uenConsolidatedArray.push(temp);
                uenConsolidatedData = {};
            });
            return uenConsolidatedArray;
        }

        function getExportToMyob(ocrData) {
            var myobOcrCSVDataArray = [];
            var myobOcrCSVData = {};
            _.forEach(ocrData, function (item) {
                _.forEach(item.items, function (poItem) {
                    myobOcrCSVData.Co_Last_Name = item.buyerSupplierMapping ? item.buyerSupplierMapping.supplierIdentificationCode : '';
                    myobOcrCSVData.First_Name = '';
                    myobOcrCSVData.Purchase = getInvoiceNumber(item, false).substr(-8);
                    myobOcrCSVData.Date = getInvoiceDate(item, "dd/MM/yyyy");
                    myobOcrCSVData.Supplier_Invoice = getInvoiceNumber(item, false);
                    myobOcrCSVData.Inclusive = '';
                    myobOcrCSVData.Memo = 'EzyProcure PO No. ' + item.purchaseOrderNumber + '/ Inv No. ' + ((item.invoiceStatus === 'PROCESSED') ? (item.invoice.supplierInvoiceNumber) : '');
                    myobOcrCSVData.Description = poItem.itemDescription;
                    myobOcrCSVData.Account_Number = poItem.glCodeMappings ? poItem.glCodeMappings.glCodes.code : '';
                    myobOcrCSVData.Amount = poItem.totalPrice ? $filter('decimalNumber')(poItem.totalPrice, 2) : 0;
                    myobOcrCSVData.Inc_Tax_Amount = $filter('decimalNumber')(poItem.lineTotal ? poItem.lineTotal : 0, 2);
                    myobOcrCSVData.Job = '';
                    myobOcrCSVData.Tax_Code = item.supplier.tax === 'Active' ? 'TX'+item.gst : '';
                    myobOcrCSVData.Non_GST_Amount = '';
                    myobOcrCSVData.GST_Amount = $filter('decimalNumber')(poItem.lineGst ? poItem.lineGst : 0, 2);
                    myobOcrCSVData.Import_Duty_Amount = '';
                    myobOcrCSVData.Purchase_Status = '';
                    myobOcrCSVData.Currency_Code = '';
                    myobOcrCSVData.Exchange_Rate = '';
                    myobOcrCSVData.Terms_Payment_is_Due = '';
                    myobOcrCSVData.Discount_Days = '';
                    myobOcrCSVData.Balance_Due_Days = '';
                    myobOcrCSVData.Discount = '';
                    myobOcrCSVData.Amount_Paid = '';
                    myobOcrCSVData.Category = '';
                    myobOcrCSVData.Card_ID = '';
                    myobOcrCSVData.Record_ID = '';
                    var temp = angular.copy(myobOcrCSVData);
                    myobOcrCSVDataArray.push(temp);
                    myobOcrCSVData = {};
                });
                myobOcrCSVDataArray.push({});
            });
            return myobOcrCSVDataArray;

        }

        function getExportToMyobFile(ocrData) {
            var myobOcrCSVDataArray = [];
            var myobOcrCSVData = {};
            _.forEach(ocrData, function (item) {
                _.forEach(item.items, function (poItem) {
                    myobOcrCSVData.Co_Last_Name = '';
                    myobOcrCSVData.First_Name = '';
                    myobOcrCSVData.Address_Line_1 = item.buyer.companyName;
                    myobOcrCSVData.Address_Line_2 = item.supplier.companyAddress;
                    myobOcrCSVData.Address_Line_3 = '';
                    myobOcrCSVData.Address_Line_4 = '';
                    myobOcrCSVData.Permit = '';
                    myobOcrCSVData.Inclusive = '';
                    myobOcrCSVData.Purchase = formatPurchaseOrderNumber(item.purchaseOrderNumber);
                    myobOcrCSVData.Date = getInvoiceDate(item, "dd/MM/yyyy");
                    myobOcrCSVData.Supplier_Invoice = getInvoiceNumber(item, false);
                    myobOcrCSVData.Ship_Via = '';
                    myobOcrCSVData.Delivery_Status = 'P';
                    myobOcrCSVData.Item_Number = poItem.buyerItemCode ? poItem.buyerItemCode : poItem.itemCode;
                    myobOcrCSVData.Quantity = poItem.quantity ? poItem.quantity : 0;
                    myobOcrCSVData.Description = poItem.buyerItemName ? poItem.buyerItemName : poItem.itemName;
                    myobOcrCSVData.Price = poItem.price;
                    myobOcrCSVData.Inc_Tax_Price = $filter('decimalNumber')(item.supplier.tax === 'Active' ? (poItem.price*(100+item.gst)/100) : poItem.price, 2);
                    myobOcrCSVData.Discount = $filter('decimalNumber')((item.discount/item.poTotalAmount)*100, 2) + '%';
                    myobOcrCSVData.Total = $filter('decimalNumber')(poItem.totalPrice - (poItem.totalPrice*(item.discount/item.poTotalAmount)), 2); // Total after discount
                    myobOcrCSVData.Inc_Tax_Total = $filter('decimalNumber')(item.supplier.tax === 'Active' ? poItem.lineTotal : poItem.totalPrice - (poItem.totalPrice*(item.discount/item.poTotalAmount)), 2);                    myobOcrCSVData.Job = '';
                    myobOcrCSVData.Comment = '';
                    myobOcrCSVData.Jurnal_Memo = 'EzyProcure PO No. ' + item.purchaseOrderNumber + '/ Inv No. ' + ((item.invoiceStatus === 'PROCESSED') ? (item.invoice.supplierInvoiceNumber) : '');
                    myobOcrCSVData.Shipping_Date = '';
                    myobOcrCSVData.Tax_Code = item.supplier.tax === 'Active' ? 'TX' + item.gst : 'TX0';
                    myobOcrCSVData.Non_GST_Amount = $filter('decimalNumber')(0,2);
                    myobOcrCSVData.GST_Amount = $filter('decimalNumber')(item.supplier.tax === 'Active' ? poItem.lineGst : 0, 2);
                    myobOcrCSVData.Import_Duty_Amount = $filter('decimalNumber')(0,2);
                    myobOcrCSVData.Freight_Amount = '';
                    myobOcrCSVData.Inc_Tax_Freight_Amount = '';
                    myobOcrCSVData.Freight_Tax_Code = item.supplier.tax === 'Active' ? 'TX' + item.gst : 'TX0';
                    myobOcrCSVData.Freight_Non_GST_Amount = $filter('decimalNumber')(0,2);
                    myobOcrCSVData.Freight_GST_Amount = $filter('decimalNumber')(0,2);
                    myobOcrCSVData.Freight_Import_Duty_Amount = $filter('decimalNumber')(0,2);
                    myobOcrCSVData.Purchase_Status = 'B';
                    myobOcrCSVData.Currency_Code = '';
                    myobOcrCSVData.Exchange_Rate = '';
                    myobOcrCSVData.Terms_Payment_is_Due = '5';
                    myobOcrCSVData.Discount_Days = '1';
                    myobOcrCSVData.Balance_Due_Days = '30';
                    myobOcrCSVData.Per_Discount = $filter('decimalNumber')((item.discount/item.poTotalAmount)*100, 2);
                    myobOcrCSVData.Amount_Paid = $filter('decimalNumber')(0,2);
                    myobOcrCSVData.Category = item.buyerBusiness.businessOutletCode;
                    myobOcrCSVData.Order = '';
                    myobOcrCSVData.Received = poItem.quantity ? poItem.quantity : 0;
                    myobOcrCSVData.Billed = poItem.quantity ? poItem.quantity : 0;
                    myobOcrCSVData.Location_Id = '';
                    if(item.buyerSupplierMapping){
                        myobOcrCSVData.Card_ID = item.buyerSupplierMapping.supplierIdentificationCode ? item.buyerSupplierMapping.supplierIdentificationCode : '';
                    }else{
                        myobOcrCSVData.Card_ID = ''
                    }
                    myobOcrCSVData.Record_ID = '';
                    var temp = angular.copy(myobOcrCSVData);
                    myobOcrCSVDataArray.push(temp);
                    myobOcrCSVData = {};
                });
                myobOcrCSVDataArray.push({});
            });
            return myobOcrCSVDataArray;

        }

        function getPurchaseOrderCSV(purchaseOrderDetails, poDetailItems, csvFilter) {
            var purchaseOrderCSVDataArray = [];
            var purchaseOrderCSVData = {};
            // if(poDetailItems && poDetailItems.length > 0){
            //     poDetailItems.sort(function(a, b) {
            //         return a.buyerRequirement.id - b.buyerRequirement.id;
            //     });

            // }
            if(purchaseOrderDetails && purchaseOrderDetails.items.length > 0){
                purchaseOrderDetails.items.sort(function(a, b) {
                    if(!a.item && !b.item){
                        return a.buyerRequirement.id - b.buyerRequirement.id;
                    }
                    else{
                        return a.item.buyerRequirement.id - b.item.buyerRequirement.id;
                    }
                });
            }
            _.forEach(poDetailItems ? poDetailItems : purchaseOrderDetails.items, function (item) {
                if (item.item) {
                    item = item.item;
                }
                purchaseOrderCSVData.PONUmber = purchaseOrderDetails.purchaseOrderNumber;
                purchaseOrderCSVData.buyerCode = item.buyerIdentificationCode ? item.buyerIdentificationCode : item.buyerRequirement.buyerUser.buyer.buyerCode;
                purchaseOrderCSVData.businessOutletName = item.buyerRequirement.buyerBusiness.businessOutletName;
                purchaseOrderCSVData.supplier = item.buyerRequirement.items.supplier.companyName;
                if (item.buyerRequirement.processOrderStatus === 'ADHOC_PO_ON_BOARD' || item.buyerRequirement.processOrderStatus === 'ADHOC_PO_OFF_BOARD') {
                    purchaseOrderCSVData.orderDate = $filter('date')(item.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                }
                else {
                    purchaseOrderCSVData.orderDate = $filter('date')(item.buyerRequirement.createdDate, "dd/MM/yyyy", timeZone);
                }
                purchaseOrderCSVData.itemCode = item.buyerRequirement.items.itemCode;
                purchaseOrderCSVData.itemName = item.buyerRequirement.items.itemName;
                if (item.buyerRequirement.glCodeMappings === null) {
                    purchaseOrderCSVData.glSpecificItemGroups = "N/A";
                }
                else {
                    purchaseOrderCSVData.glSpecificItemGroups = item.buyerRequirement.glCodeMappings.glSpecificItemGroups.name;
                }
                purchaseOrderCSVData.uom = item.buyerRequirement.items.uom.name;
                purchaseOrderCSVData.quantityRequired = item.buyerRequirement.quantity;
                purchaseOrderCSVData.quantitySupplier = item.supplierResponses ? item.supplierResponses.quantity : item.buyerRequirement.quantity;
                if (item.buyerRequirement.items.uom.weightRequired === false) {
                    purchaseOrderCSVData.weight = "N/A";
                }
                else {
                    purchaseOrderCSVData.weight = item.supplierResponses ? item.supplierResponses.weight : item.buyerRequirement.weight;
                }

                if (accessForPriceDisplay) {
                    purchaseOrderCSVData.unitPrice = item.buyerRequirement.freeItem ? 'Free Item' : item.buyerRequirement.price;
                    if(item.adhocPoGstEnabled && item.processOrderStatus == 'ADHOC_PO_OFF_BOARD'){
                        purchaseOrderCSVData.unitPrice += GLOBAL_CONSTANTS.inclusiveOfTax;
                    }
                    purchaseOrderCSVData.amountRequired = item.buyerRequirement.items.uom.weightRequired || item.buyerRequirement.freeItem ? '---' : $filter('number')(item.buyerRequirement.quantity * item.buyerRequirement.price, 2);
                    if (item.buyerRequirement.freeItem) {
                        purchaseOrderCSVData.amountSupplier = 'Free Item';
                    }
                    else {
                        if (item.buyerRequirement.items.uom.weightRequired === true) {
                            purchaseOrderCSVData.amountSupplier = item.supplierResponses ? item.supplierResponses.weight * item.buyerRequirement.price : item.buyerRequirement.weight * item.buyerRequirement.price;
                            purchaseOrderCSVData.amountSupplier = $filter('number')(purchaseOrderCSVData.amountSupplier, 4);
                        }
                        else {
                            purchaseOrderCSVData.amountSupplier = item.supplierResponses ? item.supplierResponses.quantity * item.buyerRequirement.price : item.buyerRequirement.quantity * item.buyerRequirement.price;
                            purchaseOrderCSVData.amountSupplier = $filter('number')(purchaseOrderCSVData.amountSupplier, 4);
                        }
                    }
                }
                purchaseOrderCSVData.deliveryDateRequired = $filter('date')(item.buyerRequirement.deliveryDate, "dd/MM/yyyy", timeZone);
                purchaseOrderCSVData.deliveryDateSupplier = $filter('date')(item.supplierResponses ? item.supplierResponses.deliveryDate : item.buyerRequirement.deliveryDate, "dd/MM/yyyy", timeZone);
                purchaseOrderCSVData.specificRequest = item.buyerRequirement.specialRequest ? item.buyerRequirement.specialRequest : '';
                purchaseOrderCSVData.purchaseOrderDate = $filter('date')(item.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                purchaseOrderCSVData.remark = item.supplierResponses ? item.supplierResponses.supplierRemark : item.buyerRequirement.supplierResponse;
                var temp = angular.copy(purchaseOrderCSVData);
                purchaseOrderCSVDataArray.push(temp);
                purchaseOrderCSVData = {};
            });
            return purchaseOrderCSVDataArray;
        }

        // Gets all PO data for buyer
        function getBuyerPOCSVData(buyerPOData) {
            var poBuyerCSVDataArray = [];
            var poBuyerCSVData = {};
            _.forEach(buyerPOData, function (poData, index) {
                poBuyerCSVData.srNo = index + 1;
                poBuyerCSVData.documentNo = poData.documentNumber;
                poBuyerCSVData.DocumentDate = $filter('date')(poData.createdDate, "dd/MM/yyyy", timeZone);
                poBuyerCSVData.ContainerNo = poData.container;
                poBuyerCSVData.Warehouse = poData.warehouse;
                poBuyerCSVData.Remarks = poData.remarks;
                poBuyerCSVData.RejectedRemarks = poData.rejectRemarks;
                poBuyerCSVData.Status = poData.status;
                var temp = angular.copy(poBuyerCSVData);
                poBuyerCSVDataArray.push(temp);
            });
            return poBuyerCSVDataArray;
        }

        // Gets all SO data for buyer
        function getBuyerSODCSVata(buyerSOData) {
            var soBuyerCSVDataArray = [];
            var soBuyerCSVData = {};
            _.forEach(buyerSOData, function (soData, index) {
                soBuyerCSVData.SrNo = index + 1;
                soBuyerCSVData.Document_No = soData.jobDocumentNumber;
                soBuyerCSVData.Ship_To_Code = soData.shipToCode;
                soBuyerCSVData.Product = soData.itemName;
                soBuyerCSVData.Unit_of_Measure_Code = soData.uom;
                soBuyerCSVData.Seq_No = soData.jobDocumentNumber;
                soBuyerCSVData.Shipment_Method_Code = soData.shipmentMethodCode;
                soBuyerCSVData.Shipping_Mark = soData.shippingMark;
                soBuyerCSVData.Gen_Bus_Posting_Group = soData.genBusPostingGroup;
                soBuyerCSVData.CT = soData.ct;
                soBuyerCSVData.Order_Qty = $filter('number')(soData.orderQuantity, 2);
                soBuyerCSVData.Qty_Picked_UOM = soData.quantityPicked;
                soBuyerCSVData.Picked_UOM = soData.pickedUOM;
                soBuyerCSVData.Variance = soData.variance;
                soBuyerCSVData.Req_Delivery_Date = $filter('date')(soData.reqDeliveryDate, "dd/MM/yyyy", timeZone);
                soBuyerCSVData.RejectedRemarks = soData.jobRejectRemarks;
                var temp = angular.copy(soBuyerCSVData);
                soBuyerCSVDataArray.push(temp);
            });
            return soBuyerCSVDataArray;
        }

        // Gets all SR data for buyer
        function getBuyerSRCSVData(buyerSRData) {
            var srBuyerCSVDataArray = [];
            var srBuyerCSVData = {};
            _.forEach(buyerSRData, function (srItem, index) {
                srBuyerCSVData.srNo = index + 1;
                srBuyerCSVData.documentNo = srItem.jobDocumentNumber;
                srBuyerCSVData.DocumentDate = $filter('date')(srItem.jobCreatedDate, "dd/MM/yyyy", timeZone);
                srBuyerCSVData.Item_Code = srItem.itemCode;
                srBuyerCSVData.Item_Name = srItem.itemName;
                srBuyerCSVData.Production_Date = $filter('date')(srItem.productionDate, "dd/MM/yyyy", timeZone);
                srBuyerCSVData.Expiry_Date = $filter('date')(srItem.expiryDate, "dd/MM/yyyy", timeZone);
                srBuyerCSVData.Quantity = $filter('decimalNumber')(srItem.quantity, 2);
                srBuyerCSVData.Returned_Quantity = $filter('decimalNumber')(srItem.returnQuantity, 2);
                srBuyerCSVData.Warehouse = srItem.warehouse;
                srBuyerCSVData.Variance = srItem.variance;
                srBuyerCSVData.Status = srItem.jobStatus;
                srBuyerCSVData.Remarks = srItem.remarks;
                var temp = angular.copy(srBuyerCSVData);
                srBuyerCSVDataArray.push(temp);
            });
            return srBuyerCSVDataArray;
        }

        // Gets all TO data for buyer
        function getBuyerTOCSVData(buyerTOData) {
            var toBuyerCSVDataArray = [];
            var toBuyerCSVData = {};
            _.forEach(buyerTOData, function (toItem, index) {
                toBuyerCSVData.srNo = index + 1;
                toBuyerCSVData.documentNo = toItem.jobDocumentNumber;
                toBuyerCSVData.DocumentDate = $filter('date')(toItem.jobCreatedDate, "dd/MM/yyyy", timeZone);
                toBuyerCSVData.ToWarehouse = toItem.toWarehouse;
                toBuyerCSVData.Item_Code = toItem.itemCode;
                toBuyerCSVData.Item_Name = toItem.itemName;
                toBuyerCSVData.Quantity = $filter('decimalNumber')(toItem.quantity, 2);
                toBuyerCSVData.Transferred = $filter('decimalNumber')(toItem.transferredQuantity, 2);
                toBuyerCSVData.Received = $filter('decimalNumber')(toItem.receivedQuantity, 2);
                toBuyerCSVData.Variance = toItem.variance;
                toBuyerCSVData.Status = toItem.jobStatus;
                toBuyerCSVData.Remarks = toItem.remarks;
                var temp = angular.copy(toBuyerCSVData);
                toBuyerCSVDataArray.push(temp);
            });
            return toBuyerCSVDataArray;
        }

        // Gets all PO data for warehouse
        function getWarehousePOCSVData(poData) {
            var poCSVDataArray = [];
            var poCSVData = {};
            _.forEach(poData.lineItems, function (poItem, index) {
                poCSVData.itemCode = poItem.item.code;
                poCSVData.itemName = poItem.item.name;
                poCSVData.quantity = $filter('decimalNumber')(poItem.quantity, 2);
                poCSVData.uom = poItem.uom;
                poCSVData.productionDate = $filter('date')(poItem.productionDate, "yyyyMMdd");
                poCSVData.expiryDate = $filter('date')(poItem.expiryDate, "yyyyMMdd");
                poCSVData.plant = poItem.plant;
                poCSVData.remarks = poItem.remarks;
                poCSVData.productStatus = poItem.productStatus;
                var temp = angular.copy(poCSVData);
                poCSVDataArray.push(temp);
            });
            return poCSVDataArray;
        }

        // Gets all SR data for warehouse
        function getWarehouseSRCSVData(srData) {
            var srCSVDataArray = [];
            var srCSVData = {};
            _.forEach(srData.lineItems, function (srItem) {
                srCSVData.lineId = srItem.id;
                srCSVData.documentNo = srData.job.documentNumber;
                srCSVData.lineNo = srItem.lineNumber;
                srCSVData.itemName = srItem.item.name;
                srCSVData.itemCode = srItem.item.code;
                srCSVData.quantity = $filter('decimalNumber')(srItem.quantity, 2);
                srCSVData.returnedQuantity = $filter('decimalNumber')(srItem.returnQuantity, 2);
                srCSVData.uom = srItem.uom;
                srCSVData.deliveryDate = $filter('date')(srItem.deliveryDate, "yyyyMMdd", timeZone);
                srCSVData.expiryDate = $filter('date')(srItem.expiryDate, "yyyyMMdd", timeZone);
                srCSVData.productionDate = $filter('date')(srItem.productionDate, "yyyyMMdd", timeZone);
                srCSVData.remarks = srItem.remarks;
                srCSVData.soNo = srItem.salesOrderNumber;
                srCSVData.invoiceNo = srItem.invoiceNumber;
                srCSVData.productStatus = srItem.productStatus;
                var temp = angular.copy(srCSVData);
                srCSVDataArray.push(temp);
            });
            return srCSVDataArray;
        }

        // Gets all TO data for warehouse
        function getWarehouseTOCSVData(toData) {
            var toCSVDataArray = [];
            var toCSVData = {};
            _.forEach(toData.lineItems, function (toItem) {
                toCSVData.lineId = toItem.id;
                toCSVData.lineNo = toItem.lineNumber;
                toCSVData.itemCode = toItem.item.code;
                toCSVData.itemName = toItem.item.name;
                toCSVData.quantity = $filter('decimalNumber')(toItem.quantity, 2);
                toCSVData.transferredQuantity = $filter('decimalNumber')(toItem.transferredQuantity, 2);
                toCSVData.receivedQuantity = $filter('decimalNumber')(toItem.receivedQuantity, 2);
                toCSVData.uom = toItem.uom;
                toCSVData.remarks = toItem.remarks;
                toCSVData.containerNumber = toItem.containerNumber;
                toCSVData.expiryDate = toItem.expiryDate;
                toCSVData.productStatus = toItem.productStatus;
                var temp = angular.copy(toCSVData);
                toCSVDataArray.push(temp);
            });
            return toCSVDataArray;
        }

        // Gets all SO data for warehouse
        function getWarehouseSOCSVData(soData) {
            var soCSVDataArray = [];
            var soCSVData = {};
            _.forEach(soData, function (so, index) {
                soCSVData.Line_Id = so.id;
                soCSVData.Document_No = so.jobDocumentNumber;
                soCSVData.Line_No = so.lineNumber;
                soCSVData.Ship_to_Code = so.shipToCode;
                soCSVData.Product = so.itemName;
                soCSVData.Order_Qty = $filter('decimalNumber')(so.orderQuantity, 2);
                soCSVData.Unit_of_Measure_Code = so.uom;
                soCSVData.Seq_No = so.jobSequence;
                soCSVData.Shipment_Method_Code = so.shipmentMethodCode;
                soCSVData.Shipping_Mark = so.shippingMark;
                soCSVData.Gen_Bus_Posting_Group = so.genBusPostingGroup;
                soCSVData.CT = so.ct;
                soCSVData.Qty_Picked_UOM = so.quantityPicked;
                soCSVData.Picked_UOM = so.pickedUOM;
                soCSVData.Req_Delivery_Date = $filter('date')(so.reqDeliveryDate, "yyyyMMdd", timeZone);
                soCSVData.Warehouse = so.warehouse;
                var temp = angular.copy(soCSVData);
                soCSVDataArray.push(temp);
            });
            return soCSVDataArray;
        }

        //BuyerPurchaseOrder Report
        function getBuyerPurchaseOrderReportCSV(purchaseOrderItems) {
            var purchaseOrderCSVDataArray = [];
            var purchaseOrderCSVData = {};
            for (var i = 0; i < purchaseOrderItems.length; i++) {
                var data = purchaseOrderItems[i];
                purchaseOrderCSVData.S_no = i + 1;
                purchaseOrderCSVData.PO_Number = data.requirementNumber;
                purchaseOrderCSVData.PO_Date = $filter('date')(data.createdDate, "dd/MM/yyyy", timeZone);
                //purchaseOrderCSVData.PO_Type = data.purchaseOrderType;
                purchaseOrderCSVData.Brand_Name = data.buyerBusiness.brandName;
                purchaseOrderCSVData.Business_Outlet = data.buyerBusiness.businessOutletName;
                purchaseOrderCSVData.Supplier = data.items.supplier.companyName;
                purchaseOrderCSVData.PurchaseOrderStatus = data.processOrderStatus;
                var temp = angular.copy(purchaseOrderCSVData);
                purchaseOrderCSVDataArray.push(temp);
            }
            return purchaseOrderCSVDataArray;
        }

        //BuyerGoodsReceiptNoteReportCSV Report
        function getBuyerGoodsReceiptNoteReportCSV(goodsReceiptItems) {
            var GoodsReceiptCSVDataArray = [];
            var GoodsReceiptCSVData = {};
            var index = 0;
            for (var i = 0; i < goodsReceiptItems.length; i++) {
                var data = goodsReceiptItems[i];
                if (goodsReceiptItems.purchaseOrderStatus !== 'REJECTED') {
                    GoodsReceiptCSVData.S_no = index + 1;
                    GoodsReceiptCSVData.PO_Number = data.purchaseOrderNumber;
                    GoodsReceiptCSVData.PO_Date = $filter('date')(data.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                    GoodsReceiptCSVData.Business_Outlet = data.processOrder.buyerBusiness.businessOutletName;
                    GoodsReceiptCSVData.Supplier = data.processOrder.items.supplier.companyName;
                    if (data.goodsReturnStatus === null) {
                        GoodsReceiptCSVData.ReceivingStatus = data.purchaseOrderStatus;
                    } else if (data.goodsReturnStatus === 'RETURNED') {
                        GoodsReceiptCSVData.ReceivingStatus = data.goodsReturnStatus;
                    }
                    GoodsReceiptCSVData.DeliveryDate = $filter('date')(data.processOrder.deliveryDate, "dd/MM/yyyy", timeZone);
                    var temp = angular.copy(GoodsReceiptCSVData);
                    index++;
                }
                GoodsReceiptCSVDataArray.push(temp);
            }

            return GoodsReceiptCSVDataArray;
        }

        //BuyerGoodsReturnNoticeReportCSV Report
        function getBuyerGoodsReturnNoticeReportCSV(goodsReturnItems) {
            var GoodsReturnCSVDataArray = [];
            var GoodsReturnCSVData = {};
            for (var i = 0; i < goodsReturnItems.length; i++) {
                var data = goodsReturnItems[i].purchaseOrder;
                GoodsReturnCSVData.S_no = i + 1;
                GoodsReturnCSVData.Supplier = data.processOrder.items.supplier.companyName;
                GoodsReturnCSVData.PO_Number = data.purchaseOrderNumber;
                GoodsReturnCSVData.GRN_No = data.goodsReturnNotice.goodsReturnNumber;
                GoodsReturnCSVData.PO_Date = $filter('date')(data.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                GoodsReturnCSVData.Credit_Note_Status = data.goodsReturnNotice.debitNoteStatus;
                GoodsReturnCSVData.Goods_Return_Date = $filter('date')(data.goodsReturnNotice.goodsReturnDate, "dd/MM/yyyy", timeZone);
                var temp = angular.copy(GoodsReturnCSVData);
                GoodsReturnCSVDataArray.push(temp);
            }

            return GoodsReturnCSVDataArray;
        }

        //BuyerInvoiceReportCSV Report
        function getBuyerInvoiceReportCSV(InvoiceReportItems) {
            var InvoiceReportCSVDataArray = [];
            var InvoiceReportCSVData = {};
            var index = 0;
            for (var i = 0; i < InvoiceReportItems.length; i++) {
                var data = InvoiceReportItems[i].purchaseOrder;
                if (InvoiceReportItems.purchaseOrderStatus !== 'REJECTED') {
                    InvoiceReportCSVData.S_no = index + 1;
                    InvoiceReportCSVData.Invoice_Number = data.invoice.supplierInvoiceNumber;
                    InvoiceReportCSVData.Invoice_Date = $filter('date')(data.invoice.invoiceDate, "dd/MM/yyyy", timeZone);
                    InvoiceReportCSVData.Supplier = data.processOrder.items.supplier.companyName;
                    InvoiceReportCSVData.Business_Outlet = data.processOrder.buyerBusiness.businessOutletName;
                    InvoiceReportCSVData.Invoice_Status = data.invoiceStatus;
                    InvoiceReportCSVData.PO_Number = data.purchaseOrderNumber;
                    if (accessForPriceDisplay) {
                        InvoiceReportCSVData.Invoice_Amount = data.invoice.grandTotal;
                    }
                    InvoiceReportCSVData.Purchase_Order_Date =  $filter('date')(data.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                    InvoiceReportCSVData.Delivery_Date =  $filter('date')(data.processOrder.deliveryDate, "dd/MM/yyyy", timeZone);
                    InvoiceReportCSVData.Goods_Receipt_Date = $filter('date')(data.receivedDate, "dd/MM/yyyy", timeZone);
                    var temp = angular.copy(InvoiceReportCSVData);
                    index++;
                }
                InvoiceReportCSVDataArray.push(temp);
            }

            return InvoiceReportCSVDataArray;
        }

        //BuyerPriceUpdateReportCSV Report
        function getBuyerPriceUpdateHistoryReportCSV(PriceUpdateHistoryItems) {
            var PriceUpdateHistoryCSVDataArray = [];
            var PriceUpdateHistoryCSVData = {};
            var index = 0;
            for (var i = 0; i < PriceUpdateHistoryItems.length; i++) {
                var data = PriceUpdateHistoryItems[i];

                PriceUpdateHistoryCSVData.S_no = index + 1;
                PriceUpdateHistoryCSVData.Business_outlet = data.buyerBusiness.businessOutletName;
                PriceUpdateHistoryCSVData.Supplier = data.items.supplier.companyName;
                PriceUpdateHistoryCSVData.Item_Name = data.items.itemName;
                PriceUpdateHistoryCSVData.MOQ = data.items.moq;
                if (accessForPriceDisplay) {
                    PriceUpdateHistoryCSVData.Previous_Price = $filter('showsDecimal')(data.previousPrice);
                    PriceUpdateHistoryCSVData.Update_Price = $filter('showsDecimal')(data.updatedPrice);
                    PriceUpdateHistoryCSVData.currency = data.items.currency;
                }
                PriceUpdateHistoryCSVData.Remark = data.remark;
                PriceUpdateHistoryCSVData.Updated_Date = $filter('date')(data.modifiedDate, "dd/MM/yyyy", timeZone);
                PriceUpdateHistoryCSVData.Effective_Start_Date = $filter('date')(data.effectiveStartDate, "dd/MM/yyyy", timeZone);
                PriceUpdateHistoryCSVData.Effective_End_Date = $filter('date')(data.effectiveEndDate, "dd/MM/yyyy", timeZone);
                PriceUpdateHistoryCSVData.Price_Update_Status = data.priceUpdateStatus;

                var temp = angular.copy(PriceUpdateHistoryCSVData);
                index++;
                PriceUpdateHistoryCSVDataArray.push(temp);
            }

            return PriceUpdateHistoryCSVDataArray;
        }


        //BuyerAuditLogReportCSV Report
        function getBuyerAuditLogHistoryReportCSV(AuditLogHistoryItems, transactionType) {
            var AuditLogHistoryCSVDataArray = [];
            var AuditLogHistoryCSVData = {};
            var index = 0;
            for (var i = 0; i < AuditLogHistoryItems.length; i++) {
                var data = AuditLogHistoryItems[i];

                AuditLogHistoryCSVData.S_No = index + 1;
                AuditLogHistoryCSVData.dateTimeStamp = $filter('date')(data[2], "dd/MM/yyyy", timeZone);
                AuditLogHistoryCSVData.transactionType = data[5];
                AuditLogHistoryCSVData.transactionId = data[0];
                AuditLogHistoryCSVData.status = data[1];
                AuditLogHistoryCSVData.user = data[4];
                AuditLogHistoryCSVData.userName = data[6];
                AuditLogHistoryCSVData.notes = data[7];
                if (transactionType === "Purchase Request Item Approval") {
                    AuditLogHistoryCSVData.prNumber = data[8];
                }
                var temp = angular.copy(AuditLogHistoryCSVData);
                index++;
                AuditLogHistoryCSVDataArray.push(temp);
            }

            return AuditLogHistoryCSVDataArray;
        }


        //SupplierPurchaseOrderReportCSV
        function getSupplierPurchaseOrderReportCSV(purchaseOrderItems) {
            var purchaseOrderCSVDataArray = [];
            var purchaseOrderCSVData = {};
            for (var i = 0; i < purchaseOrderItems.length; i++) {
                var data = purchaseOrderItems[i].purchaseOrder;
                purchaseOrderCSVData.S_no = i + 1;
                purchaseOrderCSVData.PO_Number = data.purchaseOrderNumber;
                purchaseOrderCSVData.PO_Date = $filter('date')(data.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                purchaseOrderCSVData.PO_Type = data.purchaseOrderType;
                purchaseOrderCSVData.Brand_Name = data.processOrder.buyerBusiness.brandName;
                purchaseOrderCSVData.Business_Outlet = data.processOrder.buyerBusiness.businessOutletName;
                /*purchaseOrderCSVData.Supplier=data.processOrder.items.supplier.companyName;*/
                purchaseOrderCSVData.PurchaseOrderStatus = data.purchaseOrderStatus;

                var temp = angular.copy(purchaseOrderCSVData);
                purchaseOrderCSVDataArray.push(temp);
            }

            return purchaseOrderCSVDataArray;
        }

        //SupplierGoodsReturnNoticeReportCSV
        function getSupplierGoodsReturnNoticeReportCSV(goodsReturnItems) {
            var GoodsReturnCSVDataArray = [];
            var GoodsReturnCSVData = {};
            for (var i = 0; i < goodsReturnItems.length; i++) {
                var data = goodsReturnItems[i].purchaseOrder;
                GoodsReturnCSVData.S_no = i + 1;
                GoodsReturnCSVData.Buyer = data.processOrder.buyerUser.buyer.companyName;
                GoodsReturnCSVData.PO_Number = data.purchaseOrderNumber;
                GoodsReturnCSVData.GRN_No = data.goodsReturnNotice.goodsReturnNumber;
                GoodsReturnCSVData.PO_Date = $filter('date')(data.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                GoodsReturnCSVData.Credit_Note_Status = data.goodsReturnNotice.debitNoteStatus;
                GoodsReturnCSVData.Goods_Return_Date = $filter('date')(data.goodsReturnNotice.goodsReturnDate, "dd/MM/yyyy", timeZone);
                var temp = angular.copy(GoodsReturnCSVData);
                GoodsReturnCSVDataArray.push(temp);
            }

            return GoodsReturnCSVDataArray;
        }

        function getSupplierPOReportCSV(purchaseOrderDetails) {
            var purchaseOrderCSVDataArray = [];
            var purchaseOrderCSVData = {};
            _.forEach(purchaseOrderDetails, function (poItem) {
                purchaseOrderCSVData.poNUmber = poItem.purchaseOrderNumber;
                purchaseOrderCSVData.businessOutlet = poItem.businessOutletName;
                purchaseOrderCSVData.buyer = poItem.buyerCompanyName;
                purchaseOrderCSVData.poDate = $filter('date')(poItem.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                purchaseOrderCSVData.itemCode = poItem.itemCode;
                purchaseOrderCSVData.itemName = poItem.itemName;
                purchaseOrderCSVData.uom = poItem.uomName;
                purchaseOrderCSVData.quantityRequired = poItem.poQuantity;
                purchaseOrderCSVData.quantitySupplier = poItem.receivedQuantity;
                if (accessForPriceDisplay) {
                    purchaseOrderCSVData.unitPrice = poItem.freeItem  ? 'Free Item' : poItem.unitPrice;
                    purchaseOrderCSVData.totalPrice = poItem.freeItem ? 'Free Item' : $filter('decimalNumber')(poItem.totalPrice, 2);
                    purchaseOrderCSVData.currency = poItem.currency;
                }
                purchaseOrderCSVData.receivedDate = $filter('date')(poItem.receivedDate, "dd/MM/yyyy", timeZone);
                purchaseOrderCSVData.specificRequest = poItem.specialRequest ? poItem.specialRequest : 'NA';
                purchaseOrderCSVData.remark = poItem.supplierRemark ? poItem.supplierRemark : 'NA';
                purchaseOrderCSVData.deliveryDate = $filter('date')(poItem.deliveryDate, "dd/MM/yyyy", timeZone);
                var temp = angular.copy(purchaseOrderCSVData);
                purchaseOrderCSVDataArray.push(temp);
                purchaseOrderCSVData = {};
            });

            return purchaseOrderCSVDataArray;
        }

        //SupplierInvoiceReportCSV
        function getSupplierInvoiceReportCSV(invoiceReportItems) {
            var invoiceReportCSVDataArray = [];
            var invoiceReportCSVData = {};
            var index = 0;
            for (var i = 0; i < invoiceReportItems.length; i++) {
                var data = invoiceReportItems[i];
                for (var j = 0; j < data.items.length; j++) {
                    if (data.purchaseOrderStatus !== 'REJECTED') {
                        invoiceReportCSVData.S_no = index + 1;
                        invoiceReportCSVData.Invoice_Date = $filter('date')(data.invoiceDate, "dd/MM/yyyy", timeZone);
                        invoiceReportCSVData.Invoice_Number = data.invoice;
                        invoiceReportCSVData.PO_Number = data.purchaseOrderNumber;
                        invoiceReportCSVData.PO_Date = $filter('date')(data.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                        invoiceReportCSVData.Buyer_Code = data.buyerIdentificationCode;
                        invoiceReportCSVData.Buyer_Name = data.buyerCompanyName;
                        invoiceReportCSVData.Business_Outlet_Name = data.businessOutletName;
                        invoiceReportCSVData.Item_Code = data.items[j].itemCode;
                        invoiceReportCSVData.Item_Name = data.items[j].itemName;
                        invoiceReportCSVData.itemGroup = data.items[j].itemGroup.name;
                        if (data.items[j].weightRequired === true) {
                            invoiceReportCSVData.Quantity = data.items[j].acceptedWeight;
                        }
                        else {
                            invoiceReportCSVData.Quantity = data.items[j].acceptedQuantity;
                        }
                        invoiceReportCSVData.UOM = data.items[j].uom;
                        if (accessForPriceDisplay) {
                            invoiceReportCSVData.Unit_Price = data.items[j].price;
                            invoiceReportCSVData.amount = data.items[j].buyerRequirement.amount;
                        }
                        invoiceReportCSVData.Delivery_Date = $filter('date')(data.items[j].buyerRequirement.deliveryDate, "dd/MM/yyyy", timeZone);
                        invoiceReportCSVData.Goods_Receipt_Date = $filter('date')(data.items[j].receivedDate, "dd/MM/yyyy", timeZone);
                        var temp = angular.copy(invoiceReportCSVData);
                        index++;
                        invoiceReportCSVDataArray.push(temp);
                    }
                }


            }

            return invoiceReportCSVDataArray;
        }

        //SupplierPriceUpdateHistoryReportCSV
        function getSupplierPriceUpdateHistoryReportCSV(PriceUpdateHistoryItems) {
            var PriceUpdateHistoryCSVDataArray = [];
            var PriceUpdateHistoryCSVData = {};
            var index = 0;
            for (var i = 0; i < PriceUpdateHistoryItems.length; i++) {
                var data = PriceUpdateHistoryItems[i];

                PriceUpdateHistoryCSVData.S_no = index + 1;
                PriceUpdateHistoryCSVData.Buyer = data.buyer.companyName;
                PriceUpdateHistoryCSVData.BusinessOutlet = data.buyerBusiness.businessOutletName;
                PriceUpdateHistoryCSVData.Item_Name = data.items.itemName;
                PriceUpdateHistoryCSVData.MOQ = data.items.moq;
                if (accessForPriceDisplay) {
                    PriceUpdateHistoryCSVData.Previous_Price = $filter('showsDecimal')(data.previousPrice);
                    PriceUpdateHistoryCSVData.Update_Price = $filter('showsDecimal')(data.updatedPrice);
                    PriceUpdateHistoryCSVData.currency = data.items.currency;
                }
                PriceUpdateHistoryCSVData.Remark = data.remark;
                PriceUpdateHistoryCSVData.BuyerRemark = data.buyerRemark;
                PriceUpdateHistoryCSVData.Updated_Date = $filter('date')(data.modifiedDate, "dd/MM/yyyy", timeZone);
                PriceUpdateHistoryCSVData.Effective_Start_Date = $filter('date')(data.effectiveStartDate, "dd/MM/yyyy", timeZone);
                PriceUpdateHistoryCSVData.Effective_End_Date = $filter('date')(data.effectiveEndDate, "dd/MM/yyyy", timeZone);
                PriceUpdateHistoryCSVData.Price_Update_Status = data.priceUpdateStatus;

                var temp = angular.copy(PriceUpdateHistoryCSVData);
                index++;
                PriceUpdateHistoryCSVDataArray.push(temp);
            }

            return PriceUpdateHistoryCSVDataArray;
        }

        //SupplierGoodsReceiptNoteReportCSV
        function getSupplierGoodsReceiptNoteReportCSV(goodsReceiptItems) {
            var GoodsReceiptCSVDataArray = [];
            var GoodsReceiptCSVData = {};
            var index = 0;
            for (var i = 0; i < goodsReceiptItems.length; i++) {
                var data = goodsReceiptItems[i];
                if (goodsReceiptItems.purchaseOrderStatus !== 'REJECTED') {
                    GoodsReceiptCSVData.S_no = index + 1;
                    GoodsReceiptCSVData.PO_Number = data.purchaseOrderNumber;
                    GoodsReceiptCSVData.PO_Date = $filter('date')(data.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                    GoodsReceiptCSVData.Buyer = data.processOrder.buyerBusiness.brandName;
                    GoodsReceiptCSVData.Business_Outlet = data.processOrder.buyerBusiness.businessOutletName;
                    if (data.goodsReturnStatus === null) {
                        GoodsReceiptCSVData.ReceivingStatus = data.purchaseOrderStatus;
                    } else if (data.goodsReturnStatus === 'RETURNED') {
                        GoodsReceiptCSVData.ReceivingStatus = data.goodsReturnStatus;
                    }
                    GoodsReceiptCSVData.DeliveryDate = $filter('date')(data.processOrder.deliveryDate, "dd/MM/yyyy", timeZone);
                    var temp = angular.copy(GoodsReceiptCSVData);
                    index++;
                }
                GoodsReceiptCSVDataArray.push(temp);
            }

            return GoodsReceiptCSVDataArray;
        }
        //SupplierCreditNoteReportCSV
        function getSupplierCreditNoteReportCSV(creditNoteItems) {
            var CreditNoteCSVDataArray = [];
            var CreditNoteCSVData = {};
            var index = 0;
            for (var i = 0; i < creditNoteItems.length; i++) {
                var data = creditNoteItems[i].purchaseOrder;
                CreditNoteCSVData.S_no = index + 1;
                CreditNoteCSVData.PO_Number = data.purchaseOrderNumber;
                CreditNoteCSVData.Invoice_Number = data.invoice ? data.invoice.supplierInvoiceNumber : '' ;
                CreditNoteCSVData.Created_Date = data.processOrder.createdDate;
                CreditNoteCSVData.Brand_Name = data.processOrder.buyerBusiness.brandName;
                CreditNoteCSVData.Customer_Name = data.processOrder.buyerBusiness.businessOutletName;
                var temp = angular.copy(CreditNoteCSVData);
                index++;

                CreditNoteCSVDataArray.push(temp);
            }
            return CreditNoteCSVDataArray;
        }

        function getAcceptedItemQuantity(item) {
            if (item.uom.weightRequired) return item.acceptedWeight;
            else return item.acceptedQuantity;
        }

        function getTotalAmountIncludingGst(itemPrice, gst) {
            if (gst) {
                return itemPrice + itemPrice * (gst / 100)
            } else {
                gst = JSON.parse(localStorage.getItem("gst-config"));
                return itemPrice + itemPrice * (gst / 100)
            }
        }

        function getInvoiceTax(invoiceDetails) {
            var invoiceTax = "NA";
            if (invoiceDetails.ocrGst) {
                invoiceTax = invoiceDetails.ocrGst;
            } else {
                invoiceTax = invoiceDetails.gst;
            }
            return invoiceTax;
        }

        function getInvoiceAmount(invoiceDetails) {
            var invoiceAmount = "NA";
            if (invoiceDetails.ocrInvoiceAmount) {
                invoiceAmount = invoiceDetails.ocrInvoiceAmount;
            } else if (invoiceDetails.invoiceStatus === 'PROCESSED') {
                invoiceAmount = invoiceDetails.invoice.grandTotal;
            }
            return invoiceAmount;
        }

        function getInvoiceNumber(invoiceDetails, canBeEmpty) {
            var invoiceNumber = canBeEmpty ? '' : "NA";
            if (invoiceDetails.ocrInvoiceNumber) {
                invoiceNumber = invoiceDetails.ocrInvoiceNumber;
            } else if (invoiceDetails.invoiceStatus === 'PROCESSED') {
                invoiceNumber = invoiceDetails.invoice.hardcopyInvoiceNumber ? invoiceDetails.invoice.hardcopyInvoiceNumber : invoiceDetails.invoice.supplierInvoiceNumber;
            }
            return invoiceNumber.toString();
        }

        function getInvoiceDate(invoiceDetails, dateFormat) {
            var invoiceDate = "NA";
            if (invoiceDetails.ocrInvoiceDate) {
                invoiceDate = $filter('date')(invoiceDetails.ocrInvoiceDate, dateFormat, timeZone);
            } else if (invoiceDetails.invoiceStatus === 'PROCESSED') {
                invoiceDate = $filter('date')(invoiceDetails.invoice.invoiceDate, dateFormat, timeZone);
            }
            return invoiceDate;
        }

        function getInvoiceDueDate(invoiceDetails, dateFormat) {
            var invoiceDueDate = "NA";
            var invoiceDate;
            if (invoiceDetails.ocrInvoiceDate) {
                invoiceDate = new Date(invoiceDetails.ocrInvoiceDate);
            } else if (invoiceDetails.invoiceStatus === 'PROCESSED') {
                invoiceDate = new Date(invoiceDetails.invoice.invoiceDate);
            }
            if (invoiceDate) {
                var dueDate = new Date(moment(invoiceDate).add(invoiceDetails.supplier.paymentDueDays, 'days'));
                invoiceDueDate = $filter('date')(dueDate, dateFormat, timeZone);
            }
            return invoiceDueDate;
        }

        function getSpecificInvoiceDueDate(invoiceDetails, dateFormat, numberOfDays) {
          var invoiceDueDate = "NA";
          var invoiceDate;
          if (invoiceDetails.ocrInvoiceDate) {
              invoiceDate = new Date(invoiceDetails.ocrInvoiceDate);
          } else if (invoiceDetails.invoiceStatus === 'PROCESSED') {
              invoiceDate = new Date(invoiceDetails.invoice.invoiceDate);
          }
          if (invoiceDate) {
            var dueDate = new Date(moment(invoiceDate).add(numberOfDays, 'days'));
            invoiceDueDate = $filter('date')(dueDate, dateFormat, timeZone);
          }
          return invoiceDueDate;
        }

        function getQuickBooksItemsCSV(quickBookItems){
             var quickBooksItemsCSVData = {};
            var quickBooksItemsCSVArray = [];
            for (var i = 0; i < quickBookItems.length; i++) {
                for(var j = 0; j < quickBookItems[i].items.length; j++) {
                quickBooksItemsCSVData.billNo = quickBookItems[i].ocrInvoiceNumber;
                if(quickBookItems[i].supplier.visibilityType == "ADHOC_OFF_BOARD_SUPPLIER"){
                    quickBooksItemsCSVData.supplier =  quickBookItems[i].supplier.companyName;
                }else{
                    quickBooksItemsCSVData.supplier = quickBookItems[i].buyerSupplierMapping.supplierIdentificationCode ? quickBookItems[i].buyerSupplierMapping.supplierIdentificationCode : quickBookItems[i].supplier.companyName;
                }
                quickBooksItemsCSVData.billDate = getInvoiceDate(quickBookItems[i], "dd/MM/yyyy");
                quickBooksItemsCSVData.dueDate = getSpecificInvoiceDueDate(quickBookItems[i], "dd/MM/yyyy", 30);
                quickBooksItemsCSVData.terms = '';
                quickBooksItemsCSVData.location = quickBookItems[i].buyerBusiness.organizationNumber;
                var deliveryDate = moment(quickBookItems[i].items[0].deliveryDate).utc().format("YYYY-MM-DD");
                if(j == 0){
                    quickBooksItemsCSVData.memo = API_CONSTANTS.ezyProcureBase + 'api/external/invoice/image/' + quickBookItems[i].purchaseOrderNumber + '/' + deliveryDate + '?token=' + AuthServerProvider.getToken();
                } else {
                quickBooksItemsCSVData.memo = '';
                }
                quickBooksItemsCSVData.account = quickBookItems[i].items[j].glCodeMappings.glSpecificItemGroups ? quickBookItems[i].items[j].glCodeMappings.glCodes.code : '';
                quickBooksItemsCSVData.lineDescrption = quickBookItems[i].items[j].itemName;
                if(quickBookItems[i].items[j].uom.weightRequired){
                    quickBooksItemsCSVData.lineAmount =  _.round(quickBookItems[i].items[j].acceptedWeight * quickBookItems[i].items[j].price, 2);
                }
                else {
                    quickBooksItemsCSVData.lineAmount = _.round(quickBookItems[i].items[j].acceptedQuantity * quickBookItems[i].items[j].price, 2);
                }
                if(quickBookItems[i].supplier.gst != 0 && quickBookItems[i].supplier.tax == 'Active'){
                    quickBooksItemsCSVData.lineTaxCode = quickBookItems[i].items[j].gst + '% TX';
                }else {
                      quickBooksItemsCSVData.lineTaxCode = '0% OP';
                }
                if(quickBookItems[i].supplier.visibilityType == "ADHOC_OFF_BOARD_SUPPLIER"){
                   if(quickBookItems[i].supplier.gst != 0 && quickBookItems[i].supplier.tax == 'Active'){
                       quickBooksItemsCSVData.lineTaxAmount = _.round(quickBooksItemsCSVData.lineAmount -(quickBooksItemsCSVData.lineAmount/(1+(quickBookItems[i].items[j].gst/100))), 2);
                       quickBooksItemsCSVData.lineAmount=quickBooksItemsCSVData.lineAmount-quickBooksItemsCSVData.lineTaxAmount;
                          }else {
                           quickBooksItemsCSVData.lineTaxAmount = 0;
                        }
                }else {
                       if(quickBookItems[i].supplier.gst != 0 && quickBookItems[i].supplier.tax == 'Active'){
                           quickBooksItemsCSVData.lineTaxAmount = _.round(quickBooksItemsCSVData.lineAmount * (quickBookItems[i].items[j].gst/100), 2);
                        }else {
                         quickBooksItemsCSVData.lineTaxAmount = 0;
                         }
                }

                quickBooksItemsCSVData.currency = quickBookItems[i].currency;
                var temp = angular.copy(quickBooksItemsCSVData);
                quickBooksItemsCSVArray.push(temp);
                quickBooksItemsCSVData = {};
                }
            }
            return quickBooksItemsCSVArray;
        }

        function getQuickBooksCSV(invoiceDetails) {
            var quickBooksInvoiceCSVData = {};
            var quickBooksInvoiceCSVArray = [];
            for (var i = 0; i < invoiceDetails.items.length; i++) {
                quickBooksInvoiceCSVData.billNo = getInvoiceNumber(invoiceDetails, true);
                quickBooksInvoiceCSVData.supplier = invoiceDetails.supplier.companyName;
                quickBooksInvoiceCSVData.billDate = getInvoiceDate(invoiceDetails, "dd/MM/yyyy");
                quickBooksInvoiceCSVData.dueDate = getInvoiceDueDate(invoiceDetails, "dd/MM/yyyy");
                quickBooksInvoiceCSVData.terms = invoiceDetails.supplier.paymentDueDays;
                quickBooksInvoiceCSVData.mailingAddressLine1 = invoiceDetails.supplier.companyAddress;
                quickBooksInvoiceCSVData.mailingAddressLine2 = "";
                quickBooksInvoiceCSVData.mailingAddressLine3 = "";
                quickBooksInvoiceCSVData.mailingAddressCity = "";
                quickBooksInvoiceCSVData.mailingAddressPostalCode = "";
                quickBooksInvoiceCSVData.mailingAddressCountry = "SG";
                quickBooksInvoiceCSVData.mailingAddressCountrySubdivisionCode = "";
                quickBooksInvoiceCSVData.memo = invoiceDetails.purchaseOrderNumber;
                quickBooksInvoiceCSVData.globalTaxCalculation = invoiceDetails.supplier.tax === "Active" ? "TaxInclusive" : "TaxExclusive";
                quickBooksInvoiceCSVData.expenseAccount = invoiceDetails.items[i].glCodeMappings.glSpecificItemGroups ? invoiceDetails.items[i].glCodeMappings.glSpecificItemGroups.name : '';
                quickBooksInvoiceCSVData.expenseDescription = invoiceDetails.invoiceStatus === "PROCESSED" ? invoiceDetails.invoice.invoiceComment : "";                
                var val = ((invoiceDetails.items[i].poAmount)*(invoiceDetails.gst/100))+(invoiceDetails.items[i].poAmount);
                quickBooksInvoiceCSVData.expenseLineAmount = $filter('decimalNumber')(val , 2);
                quickBooksInvoiceCSVData.expenseBillableStatus = "";
                quickBooksInvoiceCSVData.expenseMarkupPercent = "";
                quickBooksInvoiceCSVData.expenseCustomer = "";
                quickBooksInvoiceCSVData.expenseClass = invoiceDetails.items[i].glCodeMappings.glSpecificItemGroups ? invoiceDetails.items[i].glCodeMappings.glSpecificItemGroups.name : '';
                quickBooksInvoiceCSVData.expenseTaxCode = "TX("+ invoiceDetails.gst +"%)";
                var taxAmount = (invoiceDetails.items[i].poAmount)*(invoiceDetails.gst/100);
                quickBooksInvoiceCSVData.expenseAccountTaxAmount = $filter('decimalNumber')(taxAmount,2);
                quickBooksInvoiceCSVData.ProductService = "";
                quickBooksInvoiceCSVData.ProductServiceDescription = "";
                quickBooksInvoiceCSVData.ProductServiceQuantity = "";
                quickBooksInvoiceCSVData.ProductServiceRate = "";
                quickBooksInvoiceCSVData.ProductServiceAmount = "";
                quickBooksInvoiceCSVData.ProductServiceBillableStatus = "";
                quickBooksInvoiceCSVData.ProductServiceTaxCode = "";
                quickBooksInvoiceCSVData.ProductServiceTaxAmount = "";
                quickBooksInvoiceCSVData.ProductServiceMarkupPercent = "";
                quickBooksInvoiceCSVData.BillableCustomerProductService = "";
                quickBooksInvoiceCSVData.ProductServiceClass = "";
                quickBooksInvoiceCSVData.Location = invoiceDetails.buyerBusiness.address;
                quickBooksInvoiceCSVData.CurrencyCode = "";
                quickBooksInvoiceCSVData.ExchangeRate = "";
                var temp = angular.copy(quickBooksInvoiceCSVData);
                quickBooksInvoiceCSVArray.push(temp);
                quickBooksInvoiceCSVData = {};
            }
            return quickBooksInvoiceCSVArray;
        }

        function getGEOInvoiceCSV(invoiceDetails) {
            var runningNo = 1;
            var GEOInvoiceCSVDataArray = [];
            var GEOInvoiceCSVData = {};
            //Exclude Items that were not Accepted
            invoiceDetails.items = _.reject(invoiceDetails.items, function (item) {
                return (getAcceptedItemQuantity(item) === 0 || _.isNull(item.glCodeMappings));
            });
            _.forEach(invoiceDetails.items, function (item) {
                //hard-coding documentCode with SINV
                GEOInvoiceCSVData.documentCode = "SINV";
                GEOInvoiceCSVData.orgNo = $filter('limitTo')(invoiceDetails.buyerBusiness.organizationNumber, EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.orgNo, 0);
                GEOInvoiceCSVData.locNo = $filter('limitTo')(invoiceDetails.buyerBusiness.locationNumber, EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.locNo, 0);
                GEOInvoiceCSVData.purchaserNo = "";
                GEOInvoiceCSVData.documentDate = getInvoiceDate(invoiceDetails, "dd/MM/yyyy");
                if (invoiceDetails.buyerSupplierMapping && invoiceDetails.buyerSupplierMapping.supplierIdentificationCode)
                    GEOInvoiceCSVData.accountCode = $filter('limitTo')(invoiceDetails.buyerSupplierMapping.supplierIdentificationCode, EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.accountCode, 0);
                else
                    GEOInvoiceCSVData.accountCode = '';
                GEOInvoiceCSVData.trade = "";
                GEOInvoiceCSVData.transportation = "";
                GEOInvoiceCSVData.shipTo = $filter('limitTo')(invoiceDetails.buyerBusiness.businessOutletName, EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.shipTo, 0);
                GEOInvoiceCSVData.shippingAddress1 = $filter('limitTo')(invoiceDetails.buyerBusiness.shippingAddress, EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.shippingAddress1, 0);
                GEOInvoiceCSVData.shippingAddress2 = $filter('limitTo')(invoiceDetails.buyerBusiness.shippingPostalCode, EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.shippingAddress2, 0);
                GEOInvoiceCSVData.shippingAddress3 = "";
                GEOInvoiceCSVData.shippingAddress4 = "";
                GEOInvoiceCSVData.shippingAddressTelNo = $filter('limitTo')(invoiceDetails.buyerBusiness.phoneNumber, EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.shippingAddressTelNo, 0);
                GEOInvoiceCSVData.shippingAddressFaxNo = "";
                GEOInvoiceCSVData.projectCode = "";
                GEOInvoiceCSVData.taxCode = invoiceDetails.supplier.tax === "Inactive" ? "INTAX0" : "INTAX1";
                GEOInvoiceCSVData.taxRate = invoiceDetails.gst;
                GEOInvoiceCSVData.currencyCode = item.currency;
                GEOInvoiceCSVData.currencyRate = 1;
                GEOInvoiceCSVData.tradeTerms = "";
                GEOInvoiceCSVData.yourReference = $filter('limitTo')(getInvoiceNumber(invoiceDetails, true), EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.yourReference, 0);
                GEOInvoiceCSVData.ourReference = $filter('limitTo')(item.glCodeMappings.purchaseOrderTypes.code + invoiceDetails.purchaseOrderNumber, EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.ourReference, 0);
                GEOInvoiceCSVData.departmentCode = $filter('limitTo')(invoiceDetails.buyerBusiness.departmentCode, EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.departmentCode, 0);
                GEOInvoiceCSVData.remarks = $filter('limitTo')("", EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.remarks, 0);
                GEOInvoiceCSVData.itemNo = runningNo;
                GEOInvoiceCSVData.itemFlag = "N";
                GEOInvoiceCSVData.stockCode = $filter('limitTo')(item.glCodeMappings.glSpecificItemGroups.name, EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.stockCode, 0);
                GEOInvoiceCSVData.description = $filter('limitTo')(item.itemName, EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.description, 0);
                GEOInvoiceCSVData.qty = $filter('decimalNumber')(getAcceptedItemQuantity(item), 3);
                GEOInvoiceCSVData.UOM = $filter('limitTo')(item.uom.name, EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.UOM, 0);
                GEOInvoiceCSVData.UOMRatio = 1;
                GEOInvoiceCSVData.stockQty = $filter('decimalNumber')(getAcceptedItemQuantity(item), 3);
                GEOInvoiceCSVData.salesPrice = $filter('decimalNumber')(item.price, 2);
                GEOInvoiceCSVData.netPrice = $filter('decimalNumber')(item.price, 2);
                GEOInvoiceCSVData.itemRemarks = $filter('limitTo')(item.remark, EXPORT_CONSTANTS.GEO_MAX_CHAR_LIMIT.itemRemarks, 0);
                GEOInvoiceCSVData.acCode = item.glCodeMappings.glCodes.code;
                GEOInvoiceCSVData.extraDelim = "";

                runningNo += 1;
                var temp = angular.copy(GEOInvoiceCSVData);
                GEOInvoiceCSVDataArray.push(temp);
                GEOInvoiceCSVData = {};
            });
            return GEOInvoiceCSVDataArray;
        }

        function getGoodsReceiptNoteCSV(grnDetails,userType) {
            var goodsReceiptNoteCSVArray = [];
            var goodsReceiptNoteCSVData = {};
            _.forEach(grnDetails.items, function (item) {
                goodsReceiptNoteCSVData.itemCode = item.itemCode;
                goodsReceiptNoteCSVData.itemName = item.itemName;
                if(userType == 'supplier') {
                    goodsReceiptNoteCSVData.itemGroup = item.itemGroupName;
                }
                goodsReceiptNoteCSVData.uom = item.uom.name;
                goodsReceiptNoteCSVData.quantityPO = item.quantity;
                goodsReceiptNoteCSVData.quantityAccepted = item.acceptedQuantity;
                goodsReceiptNoteCSVData.weightPO = item.weight;
                goodsReceiptNoteCSVData.weightAccepted = item.acceptedWeight;
                if (accessForPriceDisplay) {
                    goodsReceiptNoteCSVData.unitPrice = $filter('showsDecimal')(item.price);
                    if(grnDetails.adhocPOGstEnabled && grnDetails.processOrderStatus == 'ADHOC_PO_OFF_BOARD'){
                        goodsReceiptNoteCSVData.unitPrice = item.price + GLOBAL_CONSTANTS.inclusiveOfTax;
                    }
                    if (item.freeItem) {
                        goodsReceiptNoteCSVData.amountPO = 'Free Item';
                        goodsReceiptNoteCSVData.amountAccepted = 'Free Item';
                    }
                    else {
                        goodsReceiptNoteCSVData.amountPO = item.poAmount;
                        goodsReceiptNoteCSVData.amountAccepted = item.acceptAmount;
                    }
                }
                goodsReceiptNoteCSVData.delivery_date_po = $filter('date')(item.deliveryDate, "dd/MM/yyyy h:mm a", timeZone);
                if (grnDetails.processOrderStatus === 'ADHOC_PO_ON_BOARD' || grnDetails.processOrderStatus === 'ADHOC_PO_OFF_BOARD') {
                    goodsReceiptNoteCSVData.delivery_date_accepted = $filter('date')(item.receivedDate, "dd/MM/yyyy h:mm a", timeZone);
                }
                else {
                    goodsReceiptNoteCSVData.delivery_date_accepted = $filter('date')(item.receivedDate, "dd/MM/yyyy h:mm a", timeZone);
                }
                goodsReceiptNoteCSVData.specific_request = item.specificRequest;
                goodsReceiptNoteCSVData.remark = item.remark;
                goodsReceiptNoteCSVData.purchase_order_date = $filter('date')(grnDetails.purchaseOrderDate, "dd/MM/yyyy h:mm a", timeZone);
                goodsReceiptNoteCSVData.delivery_date = $filter('date')(item.deliveryDate, "dd/MM/yyyy h:mm a", timeZone);
                goodsReceiptNoteCSVData.goods_receipt_date = $filter('date')(item.receivedDate, "dd/MM/yyyy h:mm a", timeZone);
                var temp = angular.copy(goodsReceiptNoteCSVData);
                goodsReceiptNoteCSVArray.push(temp);
                goodsReceiptNoteCSVData = {};
            });
            return goodsReceiptNoteCSVArray;
        }

        function getAccPacCSV(poDetails) {
            var getAccPacCSVArray = [];
            var getAccPacCSVData = {};
            var poobjectcheck = [];
            for (var i = 0; i < poDetails.length; i++) {
                poobjectcheck.push({
                    ponum        : poDetails[i].poNum,
                    gstlinetotal : poDetails[i].lineGST
                })
            }
            for (var i = 0; i < poDetails.length; i++) {
                getAccPacCSVData.Company_Id = poDetails[i].companyId ? poDetails[i].companyId : '';
                getAccPacCSVData.Transaction_Type = poDetails[i].transactionType;
                getAccPacCSVData.Unique_Sys_Id = 'EzyProcure';
                getAccPacCSVData.Vendor_Code = poDetails[i].vendorCode;
                getAccPacCSVData.Vendor_Name = poDetails[i].vendorName;
                getAccPacCSVData.Header_Desc = poDetails[i].headerDesc;
                getAccPacCSVData.Doc_Date =  "'" + $filter('date')(poDetails[i].docDate, "dd/MM/yyyy", timeZone) + "'";
                getAccPacCSVData.Tax = poDetails[i].tax;
                getAccPacCSVData.Posting_Date = "'" + $filter('date')(poDetails[i].postingDate, "dd/MM/yyyy", timeZone) + "'";
                getAccPacCSVData.Apply_Doc = poDetails[i].applyDoc;
                getAccPacCSVData.Inv_Amt = poDetails[i].invAmt;
                getAccPacCSVData.Doc_Num = poDetails[i].docNum;
                getAccPacCSVData.PO_Num = poDetails[i].poNum;
                getAccPacCSVData.Outlet_Code = poDetails[i].outletCode;
                getAccPacCSVData.GL_Code = poDetails[i].glCode;
                getAccPacCSVData.GL_Name = poDetails[i].glName;
                getAccPacCSVData.Tax_Class = poDetails[i].taxClass;
                getAccPacCSVData.Line_Total_inc_GST = poDetails[i].lineTotalincGST;
                getAccPacCSVData.Line_Total_exc_GST = poDetails[i].lineTotalexcGST;
                getAccPacCSVData.Line_GST = poDetails[i].lineGST;
                getAccPacCSVData.Inv_Tax_Amt = poDetails[i].lineGST;
                var linecalc = 0;
                for (var j = 0; j < poobjectcheck.length; j++) {
                    if(poobjectcheck[j].ponum == poDetails[i].poNum){
                        linecalc += poobjectcheck[j].gstlinetotal;
                        getAccPacCSVData.Inv_Tax_Amt = linecalc;
                    }
                }
                var temp = angular.copy(getAccPacCSVData);
                getAccPacCSVArray.push(temp);
                getAccPacCSVData = {};
            };
            return getAccPacCSVArray;
        }

        function getXEROInvoiceCSV(invoiceDetails) {
            var XEROInvoiceCSVDataArray = [];
            var XEROInvoiceCSVData = {};
            if(invoiceDetails.items.length > 0){
                invoiceDetails.items.sort(function(a, b) {
                    return a.purchaseOrderId - b.purchaseOrderId;
                });
            }
                _.forEach(invoiceDetails.items, function (item) {
                    XEROInvoiceCSVData.ContactName = invoiceDetails.buyerBusiness.businessOutletName;
                    XEROInvoiceCSVData.EmailAddress = invoiceDetails.buyerBusiness.email;
                    XEROInvoiceCSVData.POAddressLine1 = invoiceDetails.buyerBusiness.shippingAddress;
                    XEROInvoiceCSVData.POAddressLine2 = invoiceDetails.buyerBusiness.shippingPostalCode;
                    XEROInvoiceCSVData.POAddressLine3 = "";
                    XEROInvoiceCSVData.POAddressLine4 = "";
                    XEROInvoiceCSVData.POCity = "";
                    XEROInvoiceCSVData.PORegion = "";
                    XEROInvoiceCSVData.POPostalCode = invoiceDetails.buyerBusiness.postalCode;
                    XEROInvoiceCSVData.POCountry = "Singapore";
                    XEROInvoiceCSVData.InvoiceNumber = invoiceDetails.invoice.supplierInvoiceNumber;
                    XEROInvoiceCSVData.Reference = '';
                    XEROInvoiceCSVData.InvoiceDate = $filter('date')(invoiceDetails.invoice.invoiceDate, "dd/MM/yyyy", timeZone);
                    XEROInvoiceCSVData.DueDate = "";
                    XEROInvoiceCSVData.Total = invoiceDetails.invoice.invoiceTotal;
                    XEROInvoiceCSVData.InventoryItemCode = "";
                    XEROInvoiceCSVData.Description = item.itemDescription;
                    XEROInvoiceCSVData.Quantity = item.acceptedQuantity;
                    XEROInvoiceCSVData.UnitAmount = $filter('showsDecimal')(item.price);
                    XEROInvoiceCSVData.AccountCode = "";
                    XEROInvoiceCSVData.TaxType = invoiceDetails.supplier.tax == "Inactive" ? "No Tax (0%)" : "Standard-Rated Purchases (" + invoiceDetails.gst + "%)";
                    XEROInvoiceCSVData.TaxAmount = "";
                    XEROInvoiceCSVData.TrackingName1 = "";
                    XEROInvoiceCSVData.TrackingOption1 = "";
                    XEROInvoiceCSVData.TrackingName2 = "";
                    XEROInvoiceCSVData.TrackingOption2 = "";
                    XEROInvoiceCSVData.Currency = item.currency;
                    XEROInvoiceCSVData.BrandingTheme = '';
                    var temp = angular.copy(XEROInvoiceCSVData);
                    XEROInvoiceCSVDataArray.push(temp);
                    XEROInvoiceCSVData = {};
                });
                return XEROInvoiceCSVDataArray;
        }

        //Get Batch QuickBooks data
        function getBatchQuickBooksCSV(batchQuickBookDetails, actionType, userType) {
            switch (userType) {
                case 'BUYER': {
                    var quickBookBuyerReportList = [];
                    //Exclude Items that were not Accepted
                    batchQuickBookDetails.items = _.reject(batchQuickBookDetails.items, function (item) {
                        return (getAcceptedItemQuantity(item) === 0 || _.isNull(item.glCodeMappings));
                    });
                    _.forEach(batchQuickBookDetails, function (batchQuickBookData) {
                        if (batchQuickBookData.items.length > 0) {
                            var tempBatchQuickBookData = angular.copy(batchQuickBookData);
                            var quickBookData = (actionType === 'QUICKBOOKS') ? getQuickBooksCSV(tempBatchQuickBookData) : exportPurchaseInvoiceXeroCSV(tempBatchQuickBookData);
                            quickBookBuyerReportList.push(quickBookData);
                        }
                    });
                    return quickBookBuyerReportList;
                }
                    break;
                case 'SUPPLIER':
                    {
                        var quickBookSupplierReportList = [];
                        // batchQuickBookDetails = _.reject(batchQuickBookDetails, function(batchQuickBookDetails) {
                        //   return (_.isNull(batchQuickBookDetails.glCode));
                        // });
                        _.forEach(batchQuickBookDetails, function (batchQuickBookData) {
                            var tempBatchQuickBookData = angular.copy(batchQuickBookData);
                            var quickBookData = (actionType === 'QuickBooks') ? getQuickBooksSupplierInvoiceCSV(tempBatchQuickBookData) : exportSalesInvoiceXeroCSV(tempBatchQuickBookData);
                            quickBookSupplierReportList.push(quickBookData);
                        });
                        return quickBookSupplierReportList;
                    }
            }
        }

        //Get Batch GEO data
        function getBatchGEOInvoiceCSV(batchInvoiceDetails) {
            var GEOarrayList = [];
            _.forEach(batchInvoiceDetails, function (invoiceData) {
                if (invoiceData.items[0].glCodeMappings) {
                    var tempBatchInvoiceData = angular.copy(invoiceData);
                    var GEOarray = getGEOInvoiceCSV(tempBatchInvoiceData);
                    //flatten response from service
                    _.forEach(GEOarray, function (item) {
                        GEOarrayList.push(item);
                    });
                }
            });
            return GEOarrayList;
        }

        //Get Consolidated PurchaseOrder CSV
        function getConsolidatedPurchaseOrderCSV(consolidatedPurchaseOrderDetails) {
            var POarrayList = [];
            _.forEach(consolidatedPurchaseOrderDetails, function (poData) {
                poData.items = poData.itemsDetails;
                _.forEach(poData.items, function (individualItem) {
                    if (individualItem.item.freeItem) {
                        individualItem.item.requiredAmount = 'Free Item';
                        individualItem.item.supplierAmount = 'Free Item';
                    }
                    else {
                        individualItem.item.requiredAmount = individualItem.requiredAmount;
                        individualItem.item.supplierAmount = individualItem.supplierAmount;
                    }
                    individualItem = individualItem.item;
                });
                var POarray = getConsolidatedCSVPO(poData);
                //flatten response from service
                _.forEach(POarray, function (item) {
                    POarrayList.push(item);
                });
            });
            return POarrayList;
        }

        function grnConsolidatedCSV(consolidatedGrnDetails, userType) {
            var consolidatedGrnCSVDataArray = [];
            var consolidatedGrnCSVData = {};
            //forConsolidatedDetails
            for (var i = 0; i < consolidatedGrnDetails.length; i++) {
                var data = consolidatedGrnDetails[i];
                //forItems
                for (var j = 0; j < data.items.length; j++) {
                    var itemData = data.items[j];
                    consolidatedGrnCSVData.poNumber = data.purchaseOrderNumber;
                    consolidatedGrnCSVData.supplier = data.supplier.companyName;
                    if(userType == 'buyer') {
                        consolidatedGrnCSVData.contactNumber = data.supplier.phone;
                    }
                    consolidatedGrnCSVData.buyer = data.buyerUser.buyer.companyName;
                    consolidatedGrnCSVData.buyerCode = data.buyerIdentificationCode ? data.buyerIdentificationCode: 'N/A';
                    consolidatedGrnCSVData.businessOutlet = data.businessOutlet;
                    consolidatedGrnCSVData.poStatus = data.purchaseOrderStatus;
                    consolidatedGrnCSVData.itemCode = itemData.itemCode;
                    consolidatedGrnCSVData.itemName = itemData.itemName;
                    if(userType == 'supplier') {
                        consolidatedGrnCSVData.itemGroup = itemData.itemGroupName;
                    }
                    consolidatedGrnCSVData.uom = itemData.uom.name;
                    consolidatedGrnCSVData.poQuantity = itemData.quantity;
                    if (data.purchaseOrderStatus === 'PROCESSED') {
                        consolidatedGrnCSVData.acceptedQuantity = itemData.acceptedQuantity;
                    } else {
                        consolidatedGrnCSVData.acceptedQuantity = '---';
                    }
                    if (itemData.uom.weightRequired === true) {
                        consolidatedGrnCSVData.poWeight = itemData.weight;
                    } else {
                        consolidatedGrnCSVData.poWeight = 'N/A';
                    }
                    if (data.purchaseOrderStatus === 'PROCESSED') {
                        if (itemData.uom.weightRequired === true) {
                            consolidatedGrnCSVData.acceptedWeight = itemData.acceptedWeight;
                        } else {
                            consolidatedGrnCSVData.acceptedWeight = 'N/A';
                        }
                    } else {
                        consolidatedGrnCSVData.acceptedWeight = 'N/A';
                    }
                    if (accessForPriceDisplay) {
                        consolidatedGrnCSVData.unitPrice = ($filter('showsDecimal')(itemData.price)) + ' ' + itemData.currency;
                        if(data.adhocPOGstEnabled && data.processOrderStatus == 'ADHOC_PO_OFF_BOARD'){
                            consolidatedGrnCSVData.unitPrice += GLOBAL_CONSTANTS.inclusiveOfTax;
                        }
                        if (itemData.freeItem) {
                            consolidatedGrnCSVData.poItem = 'Free Item';
                        } else {
                            if (itemData.uom.weightRequired === true) {
                                if (itemData.uom.moqPrice === true && itemData.uom.moqPrice !== null) {
                                    consolidatedGrnCSVData.poAmount = $filter('number')(((itemData.weight / itemData.moq) * itemData.price), 2) + ' ' + itemData.currency;
                                } else {
                                    consolidatedGrnCSVData.poAmount = $filter('number')((itemData.weight * itemData.price), 2) + ' ' + itemData.currency;
                                }
                            } else {
                                if (itemData.uom.weightRequired === false) {
                                    if (itemData.uom.moqPrice === true && itemData.uom.moqPrice !== null) {
                                        consolidatedGrnCSVData.poAmount = $filter('number')(((itemData.quantity / itemData.moq) * itemData.price), 2) + ' ' + itemData.currency;
                                    } else {
                                        consolidatedGrnCSVData.poAmount = $filter('number')((itemData.quantity * itemData.price), 2) + ' ' + itemData.currency;
                                    }
                                }
                            }
                        }
                        if (itemData.freeItem) {
                            consolidatedGrnCSVData.poItem = 'Free Item';
                        } else {
                            if (data.purchaseOrderStatus === 'PROCESSED') {
                                if (itemData.uom.weightRequired === true) {
                                    if (itemData.uom.moqPrice === true && itemData.uom.moqPrice !== null) {
                                        consolidatedGrnCSVData.acceptedAmount = $filter('number')(((itemData.acceptedWeight / itemData.moq) * itemData.price), 2) + ' ' + itemData.currency;
                                    } else {
                                        consolidatedGrnCSVData.acceptedAmount = $filter('number')((itemData.acceptedWeight * itemData.price), 2) + ' ' + itemData.currency;
                                    }
                                } else {
                                    if (itemData.uom.weightRequired === false) {
                                        if (itemData.uom.moqPrice === true && itemData.uom.moqPrice !== null) {
                                            consolidatedGrnCSVData.acceptedAmount = $filter('number')(((itemData.acceptedQuantity / itemData.moq) * itemData.price), 2) + ' ' + itemData.currency;
                                        } else {
                                            consolidatedGrnCSVData.acceptedAmount = $filter('number')((itemData.acceptedQuantity * itemData.price), 2) + ' ' + itemData.currency;
                                        }
                                    }
                                }
                            } else {
                                consolidatedGrnCSVData.acceptedAmount = 'N/A';
                            }
                        }
                    }
                    consolidatedGrnCSVData.poDate = $filter('date')(itemData.deliveryDate, "dd/MM/yyyy", timeZone);
                    if (data.purchaseOrderStatus === 'PROCESSED') {
                        consolidatedGrnCSVData.acceptedDate = $filter('date')(itemData.receivedDate, "dd/MM/yyyy HH:mm:ss", timeZone);
                    } else {
                        consolidatedGrnCSVData.acceptedDate = 'N/A';
                    }
                    if (itemData.remark !== null) {
                        consolidatedGrnCSVData.remark = itemData.remark;
                    } else {
                        consolidatedGrnCSVData.remark = '---';
                    }
                    var temp = angular.copy(consolidatedGrnCSVData);
                    consolidatedGrnCSVDataArray.push(temp);
                }
                consolidatedGrnCSVData = {};
            }
            return consolidatedGrnCSVDataArray;
        }
        function getCreditNoteCSV(creditNoteDetails, creditNoteType) {
            var creditNoteCSVArray = [];
            var creditNoteCSVData = {};
            _.forEach(creditNoteDetails.items, function (item) {
                if (creditNoteType === 'CREDIT_NOTE' && item.goodsReturnNotice !== null) {
                    creditNoteCSVData.itemCode = item.itemCode;
                    creditNoteCSVData.itemName = item.itemName;
                    creditNoteCSVData.uom = item.uom.name;
                    creditNoteCSVData.quantity = item.goodsReturnNotice.creditNoteQuantity ? item.goodsReturnNotice.creditNoteQuantity : item.goodsReturnNotice.rejectedQuantity;
                    if (item.uom.weightRequired === false) {
                        creditNoteCSVData.weight = "N/A";
                    }
                    else {
                        creditNoteCSVData.weight = item.goodsReturnNotice.creditNoteWeight;
                    }
                    if (accessForPriceDisplay) {
                        creditNoteCSVData.unitPrice = $filter('showsDecimal')(item.price);
                        creditNoteCSVData.amount = $filter('number')(item.goodsReturnNotice.creditNoteAmount, 2);
                    }
                }
                if (creditNoteType === 'AD_HOC_CREDIT_NOTE' && item.adHocCreditNote !== null) {
                    creditNoteCSVData.itemCode = item.itemCode;
                    creditNoteCSVData.itemName = item.itemName;
                    creditNoteCSVData.uom = item.uom.name;
                    creditNoteCSVData.quantity = item.adHocCreditNote.quantity ? item.adHocCreditNote.quantity : item.acceptedQuantity;
                    if (item.uom.weightRequired === false) {
                        creditNoteCSVData.weight = "N/A";
                    }
                    else {
                        creditNoteCSVData.weight = item.adHocCreditNote.weight;
                    }
                    if (accessForPriceDisplay) {
                        creditNoteCSVData.unitPrice = $filter('showsDecimal')(item.price);
                        creditNoteCSVData.amount = $filter('number')(item.adHocCreditNote.amount, 2);
                    }
                }
                var temp = angular.copy(creditNoteCSVData);
                creditNoteCSVArray.push(temp);
                creditNoteCSVData = {};
            });
            return creditNoteCSVArray;
        }

        function getGoodsReturnNoticeCSV(goodsReturnDetails) {
            var goodsReturnNoticeCSVArray = [];
            var goodsReturnNoticeCSVData = {};
            _.forEach(goodsReturnDetails, function (details) {
                goodsReturnNoticeCSVData.itemCode = details.processOrder.items.itemCode;
                goodsReturnNoticeCSVData.itemName = details.processOrder.items.itemName;
                goodsReturnNoticeCSVData.uom = details.processOrder.items.uom.name;
                goodsReturnNoticeCSVData.quantityAccepted = details.acceptedQuantity;
                goodsReturnNoticeCSVData.quantityReturned = details.goodsReturnNotice.rejectedQuantity;
                if (details.processOrder.items.uom.weightRequired === false) {
                    goodsReturnNoticeCSVData.weight = "N/A";
                }
                else {
                    goodsReturnNoticeCSVData.weight = details.goodsReturnNotice.rejectedQuantity;
                }
                if (accessForPriceDisplay) {
                    goodsReturnNoticeCSVData.unitPrice = $filter('showsDecimal')(details.processOrder.price);
                    goodsReturnNoticeCSVData.goodsReturnAmount = $filter('number')((details.goodsReturnNotice.rejectedQuantity * details.processOrder.price), 2);
                }
                goodsReturnNoticeCSVData.date_received = $filter('date')(details.receivedDate, "dd/MM/yyyy", timeZone);
                goodsReturnNoticeCSVData.date_returned = $filter('date')(details.goodsReturnNotice.goodsReturnDate, "dd/MM/yyyy", timeZone);
                goodsReturnNoticeCSVData.remark = details.goodsReturnNotice.buyerRemark;
                var temp = angular.copy(goodsReturnNoticeCSVData);
                goodsReturnNoticeCSVArray.push(temp);
                goodsReturnNoticeCSVData = {};
            });
            return goodsReturnNoticeCSVArray;
        }

        function getStaffInterfaceCSV(purchaseOrderList) {
            var staffInterfaceCSVDataArray = [];
            var staffInterfaceCSVData = {};
            _.forEach(purchaseOrderList, function (data) {
                staffInterfaceCSVData.Po_numb = data.poNumber;
                staffInterfaceCSVData.supplier = data.supplier;
                staffInterfaceCSVData.invoiceStatus = data.invoiceStatus;
                staffInterfaceCSVData.ocrAcceptanceStatus = data.ocrAcceptanceStatus;
                staffInterfaceCSVData.hardcopy_Invoice_OCR_Result = data.hardCopyInvoiceStatus;
                staffInterfaceCSVData.e_InvoiceOCRResult = data.eInvoiceStatus;
                staffInterfaceCSVData.brandName = data.brandName;
                staffInterfaceCSVData.businessOutletName = data.businessOutletName;
                staffInterfaceCSVData.poDate = $filter('date')(data.poDate, "dd/MM/yyyy", timeZone);
                staffInterfaceCSVData.poAmount = $filter('number')(data.netPOAmount, 2);
                staffInterfaceCSVData.grnAmount = data.grnamount + data.otherCharges[Object.keys(data.otherCharges)];
                staffInterfaceCSVData.grnDate = $filter('date')(data.grndate, "dd/MM/yyyy", timeZone);
                staffInterfaceCSVData.hardCopyInvNumber = data.hardCopyInvoiceNumber;
                staffInterfaceCSVData.hardCopyInvAmnt = data.hardCopyInvoiceAmount;
                staffInterfaceCSVData.hardCopyInvDate = $filter('date')(data.hardCopyInvoiceDate, "dd/MM/yyyy", timeZone);
                staffInterfaceCSVData.addedGst = data.addedGST;
                staffInterfaceCSVData.eInvoiceAmount = data.eInvoiceAmount;
                staffInterfaceCSVData.eInvoiceNumber = data.eInvoiceNumber;
                staffInterfaceCSVData.ocrResponseReason = data.ocrResponseReason;
                staffInterfaceCSVData.ocrResponseComment = data.ocrResponseComment;
                staffInterfaceCSVData.purchaseOrderType = data.purchaseOrderType;
                var temp = angular.copy(staffInterfaceCSVData);
                staffInterfaceCSVDataArray.push(temp);
                staffInterfaceCSVData = {};
            });
            return staffInterfaceCSVDataArray;
        }

        function getStatementOfAccountReportNfrndsCSV(statementofAccountReports, view) {
            var statementofAccountReportsCSVDataArray = [];
            statementofAccountReports.forEach(function(element, index) {
                var statementofAccountReportsCSVData = {};
                statementofAccountReportsCSVData.sNo = index + 1;
                statementofAccountReportsCSVData.sgebizTransactionId = element.transactionId || '';
                statementofAccountReportsCSVData.ddlnTransactionId = element.ddlnTransactionId;
                statementofAccountReportsCSVData.destinationAccountNumber = element.destinationAccountNum;
                statementofAccountReportsCSVData.destinationAccountHolder = element.destinationHolderName
                statementofAccountReportsCSVData.currency = element.currency || 'IDR';
                statementofAccountReportsCSVData.nominalAmount = $filter('number')(element.nominalAmount, 2);
                statementofAccountReportsCSVData.transferFee = $filter('number')(element.transferFee, 2);
                statementofAccountReportsCSVData.nominalAmountAndTransferFee = $filter('number')(element.nominalAmount + element.transferFee, 2);
                statementofAccountReportsCSVData.description = element.description || '';
                statementofAccountReportsCSVData.recipientBankCode = element.bankCode || '';
                statementofAccountReportsCSVData.recipientBankName = element.recBankName || '';
                statementofAccountReportsCSVData.recipientBankBranchName = element.recBankBranch || '';
                statementofAccountReportsCSVData.recipientaBankCity = element.recBankCity || '';
                statementofAccountReportsCSVData.receiveEmailNotification = element.emailNotify || ''
                statementofAccountReportsCSVData.recepientEmailId = element.recEmail || '';
                statementofAccountReportsCSVData.paymentStatus = element.paymentStatus;
                statementofAccountReportsCSVData.remarks = element.remarks;
                statementofAccountReportsCSVDataArray.push(statementofAccountReportsCSVData);
            });
            return statementofAccountReportsCSVDataArray;
        }

        function getStatementOfAccountReportCSV(statementofAccountReports, view, currency) {
            var statementofAccountReportsCSVDataArray = [];
            var statementofAccountReportsCSVData = {};
            _.forEach(statementofAccountReports, function (data, i) {
                statementofAccountReportsCSVData.S_No = i + 1;
                statementofAccountReportsCSVData.transactionId = (view === 'Consolidated') ? 'Consolidated' : data.transactionId;
                statementofAccountReportsCSVData.transactionTimeStamp = $filter('date')(data.transactionTimeStamp, "dd/MM/yyyy", timeZone);
                statementofAccountReportsCSVData.supplier = data.supplier;
                statementofAccountReportsCSVData.buyerName = (view === 'Consolidated') ? 'Consolidated' : data.buyerName;
                statementofAccountReportsCSVData.brandName = (view === 'Consolidated') ? 'Consolidated' : data.brandName;
                statementofAccountReportsCSVData.poNumber = (view === 'Consolidated') ? 'Consolidated' : data.poNumber;
                statementofAccountReportsCSVData.eInvoiceOrHardCopyInvoiceNumber = (view === 'Consolidated') ? 'Consolidated' : data.invoiceNumber;
                statementofAccountReportsCSVData.invoiceAmount = $filter('number')(data.amount, 2);
                statementofAccountReportsCSVData.buyerBsfFeePercent = ($filter('number')(data.buyerBsfFeePercent, 2))+"%";
                statementofAccountReportsCSVData.buyerBsfFee = $filter('number')(data.buyerBsfFee, 2);
                // statementofAccountReportsCSVData.buyerBsfGst = $filter('number')(data.buyerBsfGst, 2);
                statementofAccountReportsCSVData.buyerTotal = $filter('number')(data.buyerTotal, 2);
                statementofAccountReportsCSVData.supplierBsfFeePercent = $filter('number')(data.supplierBsfFeePercent, 2);
                statementofAccountReportsCSVData.supplierBsfFee = $filter('number')(data.supplierBsfFee, 2);
                // statementofAccountReportsCSVData.supplierBsfGst = $filter('number')(data.supplierBsfGst, 2);
                statementofAccountReportsCSVData.supplierTotal = $filter('number')(data.supplierTotal, 2);
                statementofAccountReportsCSVData.mdrPercentage = ($filter('number')(data.mdrPercentage, 2))+"%";
                statementofAccountReportsCSVData.mdr = $filter('number')(data.mdr, 2);
                statementofAccountReportsCSVData.paymentModel = $filter('number')(data.paymentModel, 2);
                statementofAccountReportsCSVData.epd = $filter('number')(data.epd, 2);
                if(currency === 'USD') {
                    statementofAccountReportsCSVData.ttCharge = $filter('number')(data.ttCharge, 2);
                }
                statementofAccountReportsCSVData.buyerPaidAmount = $filter('number')(data.totalAmountPayable, 2);
                statementofAccountReportsCSVData.remittanceAdvice = $filter('number')(data.remittanceAdvice, 2);
                statementofAccountReportsCSVData.Remittance_Date = (view === 'Consolidated') ? 'Consolidated' : $filter('date')(data['remittanceDate'], "dd/MM/yyyy");
                statementofAccountReportsCSVData.adminFee = $filter('number')(data.adminFee, 2);
                statementofAccountReportsCSVData.giroStatus = data.giroStatus;
                statementofAccountReportsCSVData.issuer = data.issuer;
                statementofAccountReportsCSVData.issuedCountryissuedCountry = data.issuedCountry;
                statementofAccountReportsCSVData.issuedBank = data.issuedBank;
                var temp = angular.copy(statementofAccountReportsCSVData);
                statementofAccountReportsCSVDataArray.push(temp);
                statementofAccountReportsCSVData = {};
            });
            return statementofAccountReportsCSVDataArray;
        }

        function getBuyerERPCSVDataCSV(buyerERPCSVDataList) {
            var buyerERPCSVDataArray = [];
            var buyerERPCSVData = {};
            _.forEach(buyerERPCSVDataList, function (data) {
                buyerERPCSVData.itemId = data.items.id;
                buyerERPCSVData.supplierName = data.supplierName;
                buyerERPCSVData.supplierItemCode = data.supplierItemCode;
                buyerERPCSVData.supplierItemName = data.supplierItemName;
                buyerERPCSVData.UOM = data.uom;
                buyerERPCSVData.supplierItemGroup = data.supplierItemGroup;
                buyerERPCSVData.buyerItemCode = data.buyerItemCode;
                buyerERPCSVData.buyerItemName = data.buyerItemName;
                buyerERPCSVData.buyerUOM = data.buyerUom;
                buyerERPCSVData.inventoryUOM = data.inventoryUom;
                buyerERPCSVData.buyerItemGroup = data.buyerItemGroup;
                buyerERPCSVData.parentItem = data.parentItem;
                buyerERPCSVData.barcode = data.barcode;
                buyerERPCSVData.ignoreBuying = data.ignoreBuying;
                buyerERPCSVData.defaultSupplier = data.defaultSupplier;
                var temp = angular.copy(buyerERPCSVData);
                buyerERPCSVDataArray.push(temp);
                buyerERPCSVData = {};
            });
            return buyerERPCSVDataArray;
        }

        /**
         * [getTotalPurchaseVolumeCSV get CSV for TotalPurchaseVolume Business Report]
         * @param  {[type]} PriceUpdateItems [price update items]
         * @param  {[type]} dateRange        [date range selected]
         * @param  {[type]} glGroups         [column headers - Gl groups]
         * @param  {[type]} totalPerBo       [total costing per BO]
         * @param  {[type]} totalGl          [total GL]
         * @return {[type]}                  [CSV object]
         */
        function getTotalPurchaseVolumeCSV(PriceUpdateItems, dateRange, glGroups, totalPerBo, totalGl) {
            var TotalPurchaseVolumeCSVDataArray = [];
            var outletList = _.orderBy(Object.keys(PriceUpdateItems), [], ['asc']);

            var filteredOutlets = _.filter(outletList, function (outlet) { return !(outlet === 'Total'); });
            _.map(filteredOutlets, function (outlet, i) {
                if (outlet !== 'Total') {
                    var temp = angular.copy(getTotalPurchaseVolumeCSVData(i, PriceUpdateItems, outlet, glGroups, dateRange, totalPerBo, totalGl));
                    TotalPurchaseVolumeCSVDataArray.push(temp);
                }
            });
            var temp = angular.copy(getTotalPurchaseVolumeCSVData(filteredOutlets.length, PriceUpdateItems, 'Total', glGroups, dateRange, totalPerBo, totalGl));
            TotalPurchaseVolumeCSVDataArray.push(temp);
            return TotalPurchaseVolumeCSVDataArray;
        }


        /**
         * [getTotalPurchaseVolumeCSVData description]
         * @param  {[type]} index            [row entry]
         * @param  {[type]} PriceUpdateItems [price update items]
         * @param  {[type]} outlet           [row outlet name]
         * @param  {[type]} glGroups         [column entry - gl group]
         * @param  {[type]} dateRange        [date range selected]
         * @param  {[type]} totalPerBo       [total costing per BO]
         * @param  {[type]} totalGl          [total GL]
         * @return {[type]}                  [each row entry]
         */
        function getTotalPurchaseVolumeCSVData(index, PriceUpdateItems, outlet, glGroups, dateRange, totalPerBo, totalGl) {
            var TotalPurchaseVolumeCSVData = {};

            TotalPurchaseVolumeCSVData.S_no = index + 1;
            TotalPurchaseVolumeCSVData.Business_Outlet = outlet;
            TotalPurchaseVolumeCSVData.Date_Range = dateRange;
            for (var j = 0; j < glGroups.length; j++) {
                if (!PriceUpdateItems[outlet][glGroups[j]]) {
                    PriceUpdateItems[outlet][glGroups[j]] = 0.00;
                }
                TotalPurchaseVolumeCSVData[glGroups[j]] = $filter('number')(PriceUpdateItems[outlet][glGroups[j]], 2);
            }
            if (outlet === 'Total') {
                TotalPurchaseVolumeCSVData.Total_Cost_Per_Bo = $filter('number')(totalGl, 2);
            } else {
                TotalPurchaseVolumeCSVData.Total_Cost_Per_Bo = $filter('number')(totalPerBo[index], 2);
            }
            return TotalPurchaseVolumeCSVData;
        }

        function getTopSuppliersCSV(TopSupplierItems, from, to) {
            var TopSuppliersCSVDataArray = [];
            var TopSuppliersCSVData = {};
            var index = 0;
            for (var i = 0; i < TopSupplierItems.length; i++) {
                var data = TopSupplierItems[i];
                TopSuppliersCSVData.S_no = index + 1;
                TopSuppliersCSVData.Supplier = data.supplier;
                TopSuppliersCSVData.Value = $filter('number')(data.value, 2);
                TopSuppliersCSVData.FromDate = $filter('date')(from, 'dd/MM/yyyy', timeZone);
                TopSuppliersCSVData.ToDate = $filter('date')(to, 'dd/MM/yyyy', timeZone);
                TopSuppliersCSVData.Percentage = $filter('number')(data.percentage, 2) + '%';

                var temp = angular.copy(TopSuppliersCSVData);
                index++;
                TopSuppliersCSVDataArray.push(temp);
            }
            return TopSuppliersCSVDataArray;
        }
        function getTopBuyersCSV(PriceUpdateItems) {
            var TopBuyersCSVDataArray = [];
            var TopBuyersCSVData = {};
            var index = 0;
            for (var i = 0; i < PriceUpdateItems.length; i++) {
                var data = PriceUpdateItems[i];

                TopBuyersCSVData.Si_No = index + 1;
                TopBuyersCSVData.Buyer_name = data[1];
                TopBuyersCSVData.Item_Code = data[3];
                TopBuyersCSVData.Item_Name = data[4];
                TopBuyersCSVData.Item_Group = data[5];
                TopBuyersCSVData.Quantity = data[6];
                TopBuyersCSVData.Value = data[7];

                var temp = angular.copy(TopBuyersCSVData);
                index++;
                TopBuyersCSVDataArray.push(temp);
            }
            return TopBuyersCSVDataArray;
        }


        function getBuyerGoodReceiveDataReportCSV(viewType,purchaseOrderItems, metaData) {
            var purchaseOrderCSVDataArray = [];
            var purchaseOrderCSVData = {};
            for (var i = 0; i < purchaseOrderItems.length; i++) {
                var data = purchaseOrderItems[i];
                purchaseOrderCSVData.S_no = i + 1;
                purchaseOrderCSVData.GRN_Date_Range = metaData.dateRange;
                if(viewType == 'outletLevel'){
                    purchaseOrderCSVData.outletCode = data.outletCode;
                    purchaseOrderCSVData.outletName = data.outletName;
                }
                purchaseOrderCSVData.Item_Name = data.itemName;
                purchaseOrderCSVData.buyer_Item_Code = data.buyerItemCode;
                purchaseOrderCSVData.Item_Code = data.itemCode;
                purchaseOrderCSVData.Item_Group = data.itemGroup;
                purchaseOrderCSVData.GL_Group = data.glSpecificItemGroups;
                purchaseOrderCSVData.GL_Code = data.glCode;
                purchaseOrderCSVData.UOM = data.uom;
                purchaseOrderCSVData.Price = $filter('showsDecimal')(data.unitPrice);
                purchaseOrderCSVData.Supplier = data.supplier;
                purchaseOrderCSVData.Qty = $filter('number')(data.quantity, 2);
                purchaseOrderCSVData.Amt = $filter('number')(data.amount, 2);
               // purchaseOrderCSVData.Business_Outlets = metaData.businessOutlets;
                var temp = angular.copy(purchaseOrderCSVData);
                purchaseOrderCSVDataArray.push(temp);
            }
            return purchaseOrderCSVDataArray;
        }

        function getTotalPurchaseVolumeReportCSV(purchaseOrderItems) {
            var purchaseOrderCSVDataArray = [];
            var purchaseOrderCSVData = {};
            for (var i = 0; i < purchaseOrderItems.length; i++) {
                var data = purchaseOrderItems[i];
                purchaseOrderCSVData.S_no = i + 1;
                purchaseOrderCSVData.GRN_Date = $filter('date')(data.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                purchaseOrderCSVData.GRN_DOC = data.purchaseOrderNumber;
                purchaseOrderCSVData.Item_Name = data.processOrder.items.itemName;
                purchaseOrderCSVData.Item_Group = data.processOrder.items.itemGroup.name;
                purchaseOrderCSVData.Business_Outlet = data.processOrder.buyerBusiness.businessOutletName;
                purchaseOrderCSVData.Supplier = data.processOrder.items.supplier.companyName;
                purchaseOrderCSVData.Quantity = data.processOrder.quantity;

                var temp = angular.copy(purchaseOrderCSVData);
                purchaseOrderCSVDataArray.push(temp);
            }
            return purchaseOrderCSVDataArray;
        }
        function getBOPurchaseReportReportCSV(purchaseOrderItems) {
            var purchaseOrderCSVDataArray = [];
            var purchaseOrderCSVData = {};
            for (var i = 0; i < purchaseOrderItems.length; i++) {
                var data = purchaseOrderItems[i];
                purchaseOrderCSVData.S_no = i + 1;
                purchaseOrderCSVData.Business_Outlet = data.processOrder.buyerBusiness.businessOutletName;
                purchaseOrderCSVData.Item_Name = data.processOrder.items.itemName;
                purchaseOrderCSVData.Item_Group = data.processOrder.items.itemGroup.name;
                purchaseOrderCSVData.Quantity = data.processOrder.quantity;
                purchaseOrderCSVData.Value = ((data.processOrder.quantity) * (data.processOrder.price));
                //purchaseOrderCSVData.Business_Outlet=data.processOrder.buyerBusiness.businessOutletName;
                //purchaseOrderCSVData.Supplier=data.processOrder.items.supplier.companyName;
                //purchaseOrderCSVData.PurchaseOrderStatus=data.purchaseOrderStatus;

                var temp = angular.copy(purchaseOrderCSVData);
                purchaseOrderCSVDataArray.push(temp);
            }

            return purchaseOrderCSVDataArray;
        }

        function getBuyerExpensesExportReportCSV(InvoiceReportItems) {
            var ExportExpensesReportCSVDataArray = [];
            var ExportExpensesReportCSVData = {};
            var index = 0;
            for (var i = 0; i < InvoiceReportItems.length; i++) {
                var data = InvoiceReportItems[i];
                if (InvoiceReportItems.purchaseOrderStatus !== 'REJECTED') {
                    ExportExpensesReportCSVData.S_no = index + 1;
                    ExportExpensesReportCSVData.Business_Outlet = data.processOrder.buyerBusiness.businessOutletName;
                    ExportExpensesReportCSVData.Invoice_Number = data.invoice.supplierInvoiceNumber;
                    ExportExpensesReportCSVData.Invoice_Date = $filter('date')(data.invoice.invoiceDate, "dd/MM/yyyy", timeZone);
                    ExportExpensesReportCSVData.Total = data.invoice.grandTotal;
                    var temp = angular.copy(ExportExpensesReportCSVData);
                    index++;
                }
                ExportExpensesReportCSVDataArray.push(temp);
            }

            return ExportExpensesReportCSVDataArray;
        }
        function getBuyerPoSummaryCSV(purchaseOrderItems) {
            var purchaseOrderCSVDataArray = [];
            var purchaseOrderCSVData = {};
            for (var i = 0; i < purchaseOrderItems.length; i++) {
                var data = purchaseOrderItems[i];
                purchaseOrderCSVData.S_no = i + 1;
                purchaseOrderCSVData.PO_Number = data.purchaseOrderNumber;
                purchaseOrderCSVData.Requirement_Number = data.processOrder.requirementNumber;
                purchaseOrderCSVData.PO_Date = $filter('date')(data.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                purchaseOrderCSVData.PO_Type = data.purchaseOrderType;
                purchaseOrderCSVData.Business_Outlet = data.processOrder.buyerBusiness.businessOutletName;
                purchaseOrderCSVData.Supplier = data.processOrder.items.supplier.companyName;
                purchaseOrderCSVData.PO_Status = data.purchaseOrderStatus;
                purchaseOrderCSVData.Grn_No = data.purchaseOrderNumber;
                //purchaseOrderCSVData.Invoice=date.invoice.invoiceNumber;
                if (data.invoice !== null) {
                    purchaseOrderCSVData.Invoice = data.invoice.supplierInvoiceNumber;
                }
                else {
                    purchaseOrderCSVData.Invoice = '---';

                }

                var temp = angular.copy(purchaseOrderCSVData);
                purchaseOrderCSVDataArray.push(temp);
            }

            return purchaseOrderCSVDataArray;
        }

        function getTopPurchasedCSV(PriceUpdateItems) {
            var TopPurchaseItemsCSVDataArray = [];
            var TopPurchaseItemsCSVData = {};
            var index = 0;
            for (var i = 0; i < PriceUpdateItems.length; i++) {
                var data = PriceUpdateItems[i];


                TopPurchaseItemsCSVData.Current_Year_Rank = index + 1;
                TopPurchaseItemsCSVData.ItemName = data[1];
                TopPurchaseItemsCSVData.ItemCode = data[6];
                TopPurchaseItemsCSVData.Item_Group = data[2];
                TopPurchaseItemsCSVData.Quantity = data[3];
                TopPurchaseItemsCSVData.Value = data[5];

                var temp = angular.copy(TopPurchaseItemsCSVData);
                index++;
                TopPurchaseItemsCSVDataArray.push(temp);
            }
            return TopPurchaseItemsCSVDataArray;
        }
        function getitemPricingCSV(PriceUpdateItems) {
            var ItemPricingCSVDataArray = [];
            var ItemPricingCSVData = {};
            var index = 0;
             for (var i = 0; i < PriceUpdateItems.length; i++) {
                var supplierdata = PriceUpdateItems[i];
                var businessOutletTemp = "";
                 var priceTemp = "";
                for (var j = 0; j < supplierdata.businessOutlets.length; j++) {
                    var outletdata = supplierdata.businessOutlets[j];
                    var outletprice = supplierdata.prices[j];
                    ItemPricingCSVData.S_No = index + 1;
                    ItemPricingCSVData.Item_Name = supplierdata.itemName;
                    ItemPricingCSVData.BuyerItem_Code = supplierdata.buyerItemCode;
                    ItemPricingCSVData.SupplierItem_Code = supplierdata.itemCode;
                    ItemPricingCSVData.UOM = supplierdata.uom;
                    ItemPricingCSVData.Item_Group = supplierdata.itemGroupName;
                    ItemPricingCSVData.Supplier = supplierdata.supplierName;
                    if(supplierdata.uniqPrice == null){

                        businessOutletTemp = outletdata;
                        priceTemp = $filter('number')(outletprice, 4);
                    }
                    else{
                        var businessOutletTemp = "";
                        var priceTemp = "";
                        if(supplierdata.uniqPrice == supplierdata.price){
                            for(var j = 0; j < supplierdata.businessOutlets.length; j++){
                                businessOutletTemp += supplierdata.businessOutlets[j] + ', ';
                            }
                        }
                        priceTemp = $filter('number') (supplierdata.uniqPrice, 4);
                    }
                    ItemPricingCSVData.BusinessOutlet = businessOutletTemp;
                    ItemPricingCSVData.Price = priceTemp;
                    ItemPricingCSVData.BusinessOutlet = ItemPricingCSVData.BusinessOutlet.replace(/,\s*$/, "");
                    ItemPricingCSVData.Currency = supplierdata.currency;
                    var temp = angular.copy(ItemPricingCSVData);
                    index++;
                    ItemPricingCSVDataArray.push(temp);
                }
            }
            return ItemPricingCSVDataArray;
        }

        function getCostingCSV(priceUpdateItems, metaData, selected) {
            var topSuppliersCSVDataArray = [];
            var topSuppliersCSVData = {};
            for (var i = 0; i < priceUpdateItems.length; i++) {
                var data = priceUpdateItems[i];
                topSuppliersCSVData.S_no = i + 1;
                if (selected === 'SUB_ITEM_GROUP') {
                    topSuppliersCSVData.Sub_Item_Group = data.itemGroup;
                } else if (selected === 'MAIN_ITEM_GROUP') {
                    topSuppliersCSVData.Item_Group = data.mainItemGroup;
                }
                else if (selected === 'ITEM_NAME') {
                    topSuppliersCSVData.Item_Name = data.itemName;
                    topSuppliersCSVData.Item_Group = data.itemGroup;
                    topSuppliersCSVData.buyer_Item_Code = data.buyerItemCode;
                    topSuppliersCSVData.Item_Code = data.itemCode;
                    topSuppliersCSVData.UOM = data.uom;
                    topSuppliersCSVData.Supplier = data.supplier;
                    topSuppliersCSVData.Quantity = $filter('number')(data.quantity, 2);

                }
                topSuppliersCSVData.Value = $filter('number')(data.amount, 2);
                if (data.businessOutlets) {
                    topSuppliersCSVData.Business_Outlets = _.join(data.businessOutlets, ',');
                }
                else {
                    topSuppliersCSVData.Business_Outlets = metaData.businessOutlets == '' ? data.businessOutletName : metaData.businessOutlets;
                }
                topSuppliersCSVData.Date_Range = metaData.dateRange;
                var temp = angular.copy(topSuppliersCSVData);
                topSuppliersCSVDataArray.push(temp);
            }
            return topSuppliersCSVDataArray;
        }

        function getPriceTrendsCSV(PriceUpdateItems) {
            var TopSuppliersCSVDataArray = [];
            var TopSuppliersCSVData = {};
            var index = 0;
            for (var i = 0; i < PriceUpdateItems.length; i++) {
                var data = PriceUpdateItems[i];
                TopSuppliersCSVData.S_no = index + 1;
                TopSuppliersCSVData.Business_Outlet = data[4];
                TopSuppliersCSVData.supplier = data[5];
                TopSuppliersCSVData.buyer_Item_Code = data[12];
                TopSuppliersCSVData.Item_Code = data[0];
                TopSuppliersCSVData.Item_Name = data[1];
                TopSuppliersCSVData.Item_Group = data[6];
                TopSuppliersCSVData.price = $filter('showsDecimal')(data[2]);
                TopSuppliersCSVData.from_date = $filter('date')(data[7], "dd/MM/yyyy ", timeZone);
                if (data[8] !== null) {
                    TopSuppliersCSVData.to_date = $filter('date')(data[8], "dd/MM/yyyy ", timeZone);
                } else {
                    TopSuppliersCSVData.to_date = '---';
                }

                var temp = angular.copy(TopSuppliersCSVData);
                index++;
                TopSuppliersCSVDataArray.push(temp);
            }
            return TopSuppliersCSVDataArray;
        }

        function getPriceUpdateMovementCSV(PriceUpdateItems) {
            var TopBuyersCSVDataArray = [];
            var TopBuyersCSVData = {};
            var index = 0;
            for (var i = 0; i < PriceUpdateItems.length; i++) {
                var data = PriceUpdateItems[i];

                TopBuyersCSVData.Si_No = index + 1;
                TopBuyersCSVData.Business_Outlet = data[0];
                TopBuyersCSVData.brandName = data[7];
                TopBuyersCSVData.Item_Code = data[5];
                TopBuyersCSVData.Item_Name = data[1];
                TopBuyersCSVData.Item_Group = data[6];
                TopBuyersCSVData.updatedPrice = $filter('showsDecimal')(data[2]);
                TopBuyersCSVData.from_date = $filter('date')(data[3], "dd/MM/yyyy ");
                if (data[4] !== null) {
                    TopBuyersCSVData.to_date = $filter('date')(data[4], "dd/MM/yyyy ");
                } else {
                    TopBuyersCSVData.to_date = '---';
                }

                var temp = angular.copy(TopBuyersCSVData);
                index++;
                TopBuyersCSVDataArray.push(temp);
            }
            return TopBuyersCSVDataArray;
        }


        function getBuyerTransactionVolumeSummaryCSV(transactionVolume, mnt, year, type) {
            var buyerTransactionCSVDataArray = [];
            var buyerTransactionCSVData = {};
            for (var i = 0; i < transactionVolume.length; i++) {
                var data = transactionVolume[i];
                buyerTransactionCSVData.Rank = i + 1;
                buyerTransactionCSVData.Buyer_ID = data.id;
                buyerTransactionCSVData.Company_Name = data.companyName;
                buyerTransactionCSVData.Count = data.poCount;
                buyerTransactionCSVData.Total_Amount = data.totalAmount;
                buyerTransactionCSVData.Month = mnt;
                buyerTransactionCSVData.Year = year;
                buyerTransactionCSVData.Visibility_Type = type;
                var temp = angular.copy(buyerTransactionCSVData);
                buyerTransactionCSVDataArray.push(temp);
            }
            return buyerTransactionCSVDataArray;
        }

        function getSupplierTransactionVolumeSummaryCSV(transactionVolume, mnt, year, type) {
            var suppleirTransactionCSVDataArray = [];
            var supplierTransactionCSVData = {};
            for (var i = 0; i < transactionVolume.length; i++) {
                var data = transactionVolume[i];
                supplierTransactionCSVData.Rank = i + 1;
                supplierTransactionCSVData.Supplier_ID = data.id;
                supplierTransactionCSVData.Company_Name = data.companyName;
                supplierTransactionCSVData.Count = data.poCount;
                supplierTransactionCSVData.Total_Amount = data.totalAmount;
                supplierTransactionCSVData.Month = mnt;
                supplierTransactionCSVData.Year = year;
                supplierTransactionCSVData.Visibility_Type = type;
                var temp = angular.copy(supplierTransactionCSVData);
                suppleirTransactionCSVDataArray.push(temp);
            }
            return suppleirTransactionCSVDataArray;
        }

        function getConsolidatedInvoiceCSV(invoiceReport, userRole) {
            var consolidatedInvoiceCSVDataArray = [];
            var consolidatedInvoiceCSVData = {};
            for (var i = 0; i < invoiceReport.length; i++) {
                for (var j = 0; j < invoiceReport[i].items.length; j++) {
                    var data = invoiceReport[i];
                    consolidatedInvoiceCSVData.PO_No = data.purchaseOrderNumber;
                    if (userRole === 'Supplier')
                        consolidatedInvoiceCSVData.Buyer = data.buyerCompanyName;
                    else if (userRole === 'Buyer')
                        consolidatedInvoiceCSVData.Supplier = data.items[0].supplier;
                    consolidatedInvoiceCSVData.Business_Outlet = data.businessOutletName;
                    consolidatedInvoiceCSVData.Invoice_Number = data.invoice;
                    consolidatedInvoiceCSVData.hardCopyInvoiceNumber = data.hardCopyInvoiceNumber ? data.hardCopyInvoiceNumber : 'N/A';
                    consolidatedInvoiceCSVData.PO_Delivery_date = $filter('date')(data.items[j].buyerRequirement.deliveryDate, "dd/MM/yyyy ", timeZone);
                    consolidatedInvoiceCSVData.Accepted_Date = data.items[j].receivedDate ? $filter('date')(data.items[j].receivedDate, "dd/MM/yyyy ", timeZone) : "----";
                    if (accessForPriceDisplay) {
                        consolidatedInvoiceCSVData.Delivery_Charge = data.deliveryCharge;
                        consolidatedInvoiceCSVData.Sub_total = data.poTotalAmount;
                        consolidatedInvoiceCSVData.Discount_Percentage = data.discountPercent ? data.discountPercent : 0;
                        consolidatedInvoiceCSVData.Discount_Amount = data.discount ? data.discount : 0;
                        consolidatedInvoiceCSVData.GST = data.gst;
                        consolidatedInvoiceCSVData.Grand_Total = data.invoiceGrandTotal;
                    }
                    consolidatedInvoiceCSVData.Remark = data.items[j].remark;
                    var temp = angular.copy(consolidatedInvoiceCSVData);
                    consolidatedInvoiceCSVDataArray.push(temp);
                }
            }
            return consolidatedInvoiceCSVDataArray;
        }

        /**
         * [getSupplierConsolidatedInvoiceCSV Gets the suppliers invoice csv]
         */
        function getSupplierConsolidatedInvoiceCSV(invoiceReport) {
            var consolidatedInvoiceCSVDataArray = [];
            var consolidatedInvoiceCSVData = {};
            var deliveryDate = [];
            var receivedDate = [];
            for (var i = 0; i < invoiceReport.length; i++) {
                var data = invoiceReport[i];
                consolidatedInvoiceCSVData.S_No = i + 1;
                consolidatedInvoiceCSVData.E_invoice_Date = $filter('date')(data.invoiceDate, "dd/MM/yyyy ", timeZone);
                consolidatedInvoiceCSVData.PO_Number = data.purchaseOrderNumber;
                consolidatedInvoiceCSVData.Buyer_Code = data.buyerIdentificationCode;
                consolidatedInvoiceCSVData.Buyer_Name = data.buyerCompanyName;
                consolidatedInvoiceCSVData.Business_Outlet_Name = data.businessOutletName;
                consolidatedInvoiceCSVData.E_Invoice_Number = data.invoice;
                consolidatedInvoiceCSVData.Hardcopy_Invoice_Number = data.hardCopyInvoiceNumber ? data.hardCopyInvoiceNumber : 'N/A';
                _.map(invoiceReport[i].items, function (item) {
                    deliveryDate.push($filter('date')(item.buyerRequirement.deliveryDate, "dd/MM/yyyy ", timeZone));
                    receivedDate.push($filter('date')(item.receivedDate, "dd/MM/yyyy ", timeZone));
                });
                consolidatedInvoiceCSVData.PO_Delivery_Date = _.join(_.uniq(deliveryDate), ',');
                consolidatedInvoiceCSVData.Goods_Receipt_Date = _.join(_.uniq(receivedDate), ',');
                if (accessForPriceDisplay) {
                    consolidatedInvoiceCSVData.Delivery_Charge = data.deliveryCharge;
                    consolidatedInvoiceCSVData.Sub_total = data.poTotalAmount;
                    consolidatedInvoiceCSVData.Discount_Percentage = data.discountPercent ? data.discountPercent : 0;
                    consolidatedInvoiceCSVData.Discount_Amount = data.discount ? data.discount : 0;
                    consolidatedInvoiceCSVData.GST = data.gst;
                    consolidatedInvoiceCSVData.Grand_Total = data.invoiceGrandTotal;
                }
                consolidatedInvoiceCSVData.Remarks = data.buyerRemark;
                var temp = angular.copy(consolidatedInvoiceCSVData);
                consolidatedInvoiceCSVDataArray.push(temp);
                receivedDate = [];
                deliveryDate = [];
            }
            return consolidatedInvoiceCSVDataArray;
        }

        function validateExport(poList, actionType) {
            var validSelection = true;
            _.forEach(poList, function (po, key) {
                if (validSelection) {
                    if (po.purchaseOrder != undefined && po.purchaseOrder.ocrAcceptanceStatus !== 'ACCEPTED') {
                        validSelection = false;
                        toastr.error('Only Accepted POs can be Exported', '' + actionType + ' Export Error');
                    } else if (po.purchaseOrder == undefined) {
                        _.forEach(po.items, function (item, itemId) {
                            if ((!item.glCodeMappings && actionType === 'GEO') || (!item.glCodeMappings && actionType === 'QuickBooks') || (!item.glCodeMappings && actionType === 'XERO_CSV')) {
                                validSelection = false;
                                toastr.error('No GL data for PO(' + po.purchaseOrderNumber + '). Item Group is not mapped to PO Type, GL Code and GL Item Group.', '' + actionType + ' Export Error');
                            }
                        });
                    }
                }
            });
            return validSelection;
        }

        /**
         * [getFoodCostPrice get individual FoodCostPrice for an itemGroup, for a supplier on particular date]
         * @param  {[List]} foodCostData [foodCostData]
         * @param  {[String]} itemGroup    [itemGroup]
         * @param  {[String]} column       [supplier column]
         * @param  {[String]} row          [date row]
         * @return {[Integer]}              [For itemGroup, price for supplier on particular date]
         */
        function getFoodCostPrice(foodCostData, outlet, itemGroup, column, row) {
            var value = 0;
            if (foodCostData[outlet][itemGroup][column][row])
                value = foodCostData[outlet][itemGroup][column][row];
            return value;
        }

        /**
         * [getFoodCostCSVData Export data for selected outlet and itemgroup]
         * @param  {[List]} foodCostData [foodCostData]
         * @param  {[Object]} metaData     [columns, rows, selected outlet and itemgroup]
         * @return {[List]}              [CSV Data]
         */
        function getFoodCostCSVData(foodCostData, metaData) {
            var foodCostCSVDataArray = [];
            _.forEach(metaData.rows, function (row) {
                var foodCostCSVData = {};
                _.forEach(metaData.columns, function (column) {
                    if (column === "Date") {
                        //Set date rows like 01,02,03,.. etc in 'Date' column
                        foodCostCSVData[column] = row;
                    }
                    else if (column == "Currency") {
                        foodCostCSVData[column] = 'SGD';
                    }
                    else {
                        //Set foodCostPrice purchased for an outlet's, itemGroup, by a supplier on a particular date
                        foodCostCSVData[column] = $filter('decimalNumber')(getFoodCostPrice(foodCostData, metaData.outlet, metaData.itemGroup, column, row), 2);
                    }
                });
                foodCostCSVDataArray.push(foodCostCSVData);
            });
            return foodCostCSVDataArray;
        }

        function getSupplierItemPricingCSV(SupplierPriceUpdateItems) {
            var supplierItemPricingCSVDataArray = [];
            var supplierItemPricingCSVData = {};
            var index = 0;
            for (var i = 0; i < SupplierPriceUpdateItems.length; i++) {
                var buyerData = SupplierPriceUpdateItems[i];
                for (var j = 0; j < buyerData.items.length; j++) {
                    var data = buyerData.items[j];
                    var businessOutletTemp = "";
                    var priceTemp = "";
                    for (var k = 0; k < data.itemBusinessMappingDTOS.length; k++) {
                        var dataInner = data.itemBusinessMappingDTOS[k];
                        supplierItemPricingCSVData.S_No = index + 1;
                        supplierItemPricingCSVData.Item_Name = data.itemName;
                        supplierItemPricingCSVData.Item_Code = data.itemCode;
                        supplierItemPricingCSVData.UOM = data.uom;
                        supplierItemPricingCSVData.Item_Group = data.itemGroupName;
                        supplierItemPricingCSVData.Buyer = buyerData.buyerName;

                        if(data.uniqPrice == null){

                            businessOutletTemp = dataInner.businessOutlet;
                            priceTemp = $filter('number')(dataInner.price, 4);
                        }
                        else{
                            var businessOutletTemp = "";
                            var priceTemp = "";
                            if(data.uniqPrice == dataInner.price){
                                for(var k=0; k < data.itemBusinessMappingDTOS.length; k++){
                                    businessOutletTemp += data.itemBusinessMappingDTOS[k].businessOutlet + ', ';
                                }
                            }
                            priceTemp = $filter('number') (data.price, 4);
                        }
                        supplierItemPricingCSVData.BusinessOutlet = businessOutletTemp;
                        supplierItemPricingCSVData.Price = priceTemp;
                        supplierItemPricingCSVData.BusinessOutlet = supplierItemPricingCSVData.BusinessOutlet.replace(/,\s*$/, "");
                        supplierItemPricingCSVData.Currency = data.currency;
                        var temp = angular.copy(supplierItemPricingCSVData);
                        index++;
                        supplierItemPricingCSVDataArray.push(temp);
                    }
                }
            }
           return supplierItemPricingCSVDataArray;
        }

        function getSupplierStockReportByOutletsCSV(SupplierStockReportItems) {
            var supplierStockReportCSVDataArray = [];
            var supplierStockReportCSVData = {};
            var index = 0;
            for (var i = 0; i < SupplierStockReportItems.length; i++) {
                var stockData = SupplierStockReportItems[i];
                for (var j = 0; j < stockData.items.length; j++) {
                    var data = stockData.items[j];
                    supplierStockReportCSVData.S_No = index + 1;
                    supplierStockReportCSVData.Item_Name = data.itemName;
                    supplierStockReportCSVData.Item_Code = data.itemCode;
                    supplierStockReportCSVData.UOM = data.uom;
                    supplierStockReportCSVData.packaging = data.packaging;
                    supplierStockReportCSVData.BusinessOutlet = stockData.outletName;
                    if (supplierStockReportCSVData.weightRequired) {
                        supplierStockReportCSVData.Quantity = data.weight;
                    }
                    else {
                        supplierStockReportCSVData.Quantity = data.quantity;
                    }
                    var temp = angular.copy(supplierStockReportCSVData);
                    index++;
                    supplierStockReportCSVDataArray.push(temp);
                }
            }
            return supplierStockReportCSVDataArray;
        }

        function getSupplierStockReportByItemsCSV(SupplierStockReportItems) {
            var supplierStockReportCSVDataArray = [];
            var supplierStockReportCSVData = {};
            var index = 0;
            for (var i = 0; i < SupplierStockReportItems.length; i++) {
                var data = SupplierStockReportItems[i];
                supplierStockReportCSVData.S_No = index + 1;
                supplierStockReportCSVData.Item_Name = data.itemName;
                supplierStockReportCSVData.Item_Code = data.itemCode;
                supplierStockReportCSVData.UOM = data.uom;
                supplierStockReportCSVData.packaging = data.packaging;
                if (data.outlets.length > 0) {
                    supplierStockReportCSVData.BusinessOutlet = _.join(data.outlets, ',');
                }
                else {
                    supplierStockReportCSVData.BusinessOutlet = "----";
                }
                if (data.weightRequired) {
                    supplierStockReportCSVData.Quantity = data.weight;
                }
                else {
                    supplierStockReportCSVData.Quantity = data.quantity;
                }
                var temp = angular.copy(supplierStockReportCSVData);
                index++;
                supplierStockReportCSVDataArray.push(temp);
            }
            return supplierStockReportCSVDataArray;
        }
        function getPurchaseTrendCSV(priceTrendItems, metaData, selectedList) {
            var priceTrendCSVDataArray = [];
            var priceTrendCSVData = {};
            priceTrendCSVData.S_no = 0;
            if (selectedList == 'Month') {
                _.each(priceTrendItems, function (outlet, outletkey) {
                    _.map(outlet, function (year, yearkey) {
                        for (var i = 0; i < year.length; i++) {
                            priceTrendCSVData.S_no++;
                            priceTrendCSVData.Business_Outlets = outletkey;
                            priceTrendCSVData.Month = year[i].month + " " + yearkey;
                            priceTrendCSVData.Month_Purchase_Value = year[i].value;
                            priceTrendCSVData.Date_Range = metaData;
                            var temp = angular.copy(priceTrendCSVData);
                            priceTrendCSVDataArray.push(temp);
                        }
                    })
                })
            }
            else {
                _.each(priceTrendItems, function (outlet, outletkey) {
                    _.map(outlet, function (year, yearkey) {
                        for (var i = 0; i < year.length; i++) {
                            priceTrendCSVData.S_no++;
                            priceTrendCSVData.Business_Outlets = outletkey;
                            priceTrendCSVData.Quarter = year[i].q + " " + yearkey;
                            priceTrendCSVData.Quarter_Purchase_Value = year[i].value;
                            priceTrendCSVData.Date_Range = metaData;
                            var temp = angular.copy(priceTrendCSVData);
                            priceTrendCSVDataArray.push(temp);
                        }
                    })
                })
            }
            return priceTrendCSVDataArray;
        }

        function getForecastCSV(priceTrendItems, metaData, selectedList) {
            var priceTrendCSVDataArray = [];
            var priceTrendCSVData = {};
            priceTrendCSVData.S_no = 0;
            if (selectedList == 'Month') {
                _.each(priceTrendItems, function (outlet, outletkey) {
                    _.map(outlet, function (year, yearkey) {
                        for (var i = 0; i < year.length; i++) {
                            priceTrendCSVData.S_no++;
                            priceTrendCSVData.Business_Outlets = outletkey;
                            priceTrendCSVData.Month = year[i].month + " " + yearkey;
                            priceTrendCSVData.Month_Forecast_Value = year[i].value;
                            priceTrendCSVData.Date_Range = metaData;
                            var temp = angular.copy(priceTrendCSVData);
                            priceTrendCSVDataArray.push(temp);
                        }
                    })
                })
            }
            else {
                _.each(priceTrendItems, function (outlet, outletkey) {
                    _.map(outlet, function (year, yearkey) {
                        for (var i = 0; i < year.length; i++) {
                            priceTrendCSVData.S_no++;
                            priceTrendCSVData.Business_Outlets = outletkey;
                            priceTrendCSVData.Quarter = year[i].q + " " + yearkey;
                            priceTrendCSVData.Quarter_Forecast_Value = year[i].value;
                            priceTrendCSVData.Date_Range = metaData;
                            var temp = angular.copy(priceTrendCSVData);
                            priceTrendCSVDataArray.push(temp);
                        }
                    })
                })
            }
            return priceTrendCSVDataArray;
        }

        function getNfrndsTransactionSummaryReportsCSV (transactionSummaryReports, userType) {
            var transactionSummaryReportsArray = [];
            transactionSummaryReports.forEach(function(element, index) {
                var transactionSummaryReportsDetailsData = {};
                transactionSummaryReportsDetailsData.sNo = index + 1;
                transactionSummaryReportsDetailsData.invoiceNumber = element.invoiceNumber;
                transactionSummaryReportsDetailsData.feeId = element.feeId;
                transactionSummaryReportsDetailsData.feeAmount = element.feeAmount;
                transactionSummaryReportsDetailsData.currency = element.feeCurrency;
                transactionSummaryReportsDetailsData.paymentRef = element.custRef;
                transactionSummaryReportsDetailsData.brandName = element.brandName;
                transactionSummaryReportsDetailsData.paymentDate = element.paymentDate;
                transactionSummaryReportsDetailsData.invoiceAmount = element.amount;
                transactionSummaryReportsDetailsData.supplierName = element.supplier;
                transactionSummaryReportsDetailsData.paymentStatus = element.paymentStatus;
                transactionSummaryReportsDetailsData.remittanceStatus = element.transactionStatus;
                transactionSummaryReportsDetailsData.remarks = element.remarks;

                transactionSummaryReportsArray.push(transactionSummaryReportsDetailsData);
            });

            return transactionSummaryReportsArray;
        }

        function getTransactionSummaryReportsCSV(transactionSummaryReports, userType) {
            var transactionSummaryReportsArray = [];
            var transactionSummaryReportsDetailsData = {};
            var index = 0;
            if(userType === 'supplier') {
                for (var i = 0; i < transactionSummaryReports.length; i++) {
                    transactionSummaryReportsDetailsData.S_No = index + 1;
                    transactionSummaryReportsDetailsData.Invoice_No = transactionSummaryReports[i]['invoiceNumber'];
                    transactionSummaryReportsDetailsData.PO_Number = transactionSummaryReports[i]['poNumber'] != "null" ? transactionSummaryReports[i]['poNumber'] : 'N/A';
                    transactionSummaryReportsDetailsData.Customer_Reference = transactionSummaryReports[i]['custRef'] ? transactionSummaryReports[i]['custRef'] : 'N/A';
                    transactionSummaryReportsDetailsData.Payment_Date = $filter('date')(transactionSummaryReports[i]['transactionTimeStamp'], "dd/MM/yyyy");
                    transactionSummaryReportsDetailsData.Buyer_Name = transactionSummaryReports[i]['buyerName'];
                    transactionSummaryReportsDetailsData.Brand_Name = transactionSummaryReports[i]['brandName'];
                    transactionSummaryReportsDetailsData.Invoice_Amount = $filter('decimalNumber')(transactionSummaryReports[i]['amount'], 2);
                    transactionSummaryReportsDetailsData.Supplier_BSF_Fee = $filter('decimalNumber')(transactionSummaryReports[i]['supplierTotal'], 2);
                    // transactionSummaryReportsDetailsData.Admin_Fee = $filter('decimalNumber')(transactionSummaryReports[i]['adminFee'], 2);
                    transactionSummaryReportsDetailsData.Epd = $filter('decimalNumber')(transactionSummaryReports[i]['epd'], 2);
                    transactionSummaryReportsDetailsData.Remittance_Amount = $filter('decimalNumber')(transactionSummaryReports[i]['remittanceAdvice'], 2);
                    transactionSummaryReportsDetailsData.Payment_Status = transactionSummaryReports[i]['paymentStatus'];
                    transactionSummaryReportsDetailsData.Remittance_Status = transactionSummaryReports[i]['transactionStatus'];
                    transactionSummaryReportsDetailsData.Remittance_Date = $filter('date')(transactionSummaryReports[i]['remittanceDate'], "dd/MM/yyyy");
                    transactionSummaryReportsDetailsData.PaymentMethod = transactionSummaryReports[i]['paymentMethod'];

                    var temp = angular.copy(transactionSummaryReportsDetailsData);
                    index++;
                    transactionSummaryReportsArray.push(temp);
                  }
            } else {
              for (var i = 0; i < transactionSummaryReports.length; i++) {
                transactionSummaryReportsDetailsData.S_No = index + 1;
                transactionSummaryReportsDetailsData.Invoice_No = transactionSummaryReports[i]['invoiceNumber'];
                transactionSummaryReportsDetailsData.PO_Number = transactionSummaryReports[i]['poNumber'] != "null" ? transactionSummaryReports[i]['poNumber'] : 'N/A';
                transactionSummaryReportsDetailsData.Customer_Reference = transactionSummaryReports[i]['custRef'] ? transactionSummaryReports[i]['custRef'] : 'N/A';
                transactionSummaryReportsDetailsData.Brand_Name = transactionSummaryReports[i]['brandName'];
                transactionSummaryReportsDetailsData.Business_Outlet = transactionSummaryReports[i]['poNumber'] != "null" ? transactionSummaryReports[i]['businessOutletName'] : 'N/A';
                transactionSummaryReportsDetailsData.Payment_Date = $filter('date')(transactionSummaryReports[i]['transactionTimeStamp'], "dd/MM/yyyy");
                transactionSummaryReportsDetailsData.Invoice_Amount = $filter('decimalNumber')(transactionSummaryReports[i]['amount'], 2);
                if (userType === 'buyer') {
                    transactionSummaryReportsDetailsData.Supplier_Name = transactionSummaryReports[i]['supplier'];
                    transactionSummaryReportsDetailsData.Card_Number = transactionSummaryReports[i]['cardNumber'];
                    transactionSummaryReportsDetailsData.Buyer_BSF_Fee = $filter('decimalNumber')(transactionSummaryReports[i]['buyerBsfFee'], 2);
                    transactionSummaryReportsDetailsData.TT_Charge = $filter('decimalNumber')(transactionSummaryReports[i]['ttCharge'], 2);
                    // transactionSummaryReportsDetailsData.Buyer_BSF_Total = $filter('decimalNumber')(transactionSummaryReports[i]['buyerTotal'], 2);
                }
                if (userType === 'supplier') {
                    transactionSummaryReportsDetailsData.Supplier_BSF_Fee = $filter('decimalNumber')(transactionSummaryReports[i]['supplierBsfFee'], 2);
                    // transactionSummaryReportsDetailsData.Supplier_BSF_gst = $filter('decimalNumber')(transactionSummaryReports[i]['supplierBsfGst'], 2);
                    // transactionSummaryReportsDetailsData.Supplier_BSF_Total = $filter('decimalNumber')(transactionSummaryReports[i]['supplierTotal'], 2);
                    transactionSummaryReportsDetailsData.Remittance_Amount = $filter('decimalNumber')(transactionSummaryReports[i]['remittanceAdvice'], 2);
                }
                transactionSummaryReportsDetailsData.Epd = $filter('decimalNumber')(transactionSummaryReports[i]['epd'], 2);
                transactionSummaryReportsDetailsData.Total_Amount_payable = $filter('decimalNumber')(transactionSummaryReports[i]['totalAmountPayable'], 2);
                transactionSummaryReportsDetailsData.Payment_Status = transactionSummaryReports[i]['paymentStatus'];
                transactionSummaryReportsDetailsData.Remittance_Status = transactionSummaryReports[i]['transactionStatus'];
                transactionSummaryReportsDetailsData.Remittance_Date = $filter('date')(transactionSummaryReports[i]['remittanceDate'], "dd/MM/yyyy");

                var temp = angular.copy(transactionSummaryReportsDetailsData);
                index++;
                transactionSummaryReportsArray.push(temp);
              }
            }
            return transactionSummaryReportsArray;
        }

        function getSupplierPerformanceCSVData(SupplierPerformanceDetails, fromDate, toDate) {
            var SupplierPerformanceDetailsArray = [];
            var SupplierPerformanceData = {};
            var index = 0;
            for (var i = 0; i < SupplierPerformanceDetails.length; i++) {
                SupplierPerformanceData.S_No = index + 1;
                SupplierPerformanceData.Supplier = SupplierPerformanceDetails[i].supplier;
                SupplierPerformanceData.Date_Range = $filter('date')(fromDate, 'dd/MM/yyyy', timeZone) + " to " + $filter('date')(toDate, 'dd/MM/yyyy', timeZone);
                SupplierPerformanceData.Total_Quantity_Purchased = $filter('number')(SupplierPerformanceDetails[i].quantityPurchased, 3);
                SupplierPerformanceData.Total_Quantity_Received = $filter('number')(SupplierPerformanceDetails[i].quantityReceived, 3);
                SupplierPerformanceData.Success_Rate = $filter('number')(SupplierPerformanceDetails[i].successRate, 2) + "%";

                var temp = angular.copy(SupplierPerformanceData);
                index++;
                SupplierPerformanceDetailsArray.push(temp);
            }
            return SupplierPerformanceDetailsArray;
        }

        function getNewItemLogCSVData(newItemLogs) {
            var newItemLogCSVDataArray = [];
            var newItemLogCSVData = {};
            _.forEach(newItemLogs, function (newItemLog, index) {
                newItemLogCSVData.S_No = index + 1;
                newItemLogCSVData.buyerItemCode = newItemLog.buyerItemCode;
                newItemLogCSVData.itemCode = newItemLog.itemCode;
                newItemLogCSVData.itemName = newItemLog.itemName;
                newItemLogCSVData.itemGroupName = newItemLog.itemGroupName;
                newItemLogCSVData.supplierName = newItemLog.supplierName;
                newItemLogCSVData.uom = newItemLog.uom;
                newItemLogCSVData.price = $filter('showsDecimal')(newItemLog.price);
                newItemLogCSVData.dateAdded = $filter('date')(newItemLog.dateAdded, "dd/MM/yyyy", timeZone);
                newItemLogCSVData.approvedBy = (newItemLog.approvedBy === 'N/A') ? 'Adhoc Supplier' : newItemLog.approvedBy;
                var temp = angular.copy(newItemLogCSVData);
                newItemLogCSVDataArray.push(temp);
                newItemLogCSVData = {};
            });
            return newItemLogCSVDataArray;
        }

        function getGrVarianceCSVData(grVarianceItems) {
            var grVarianceRateCSVArray = [];
            var grVarianceRateCSVObj = {};
            for (var i = 0; i < grVarianceItems.length; i++) {
                grVarianceRateCSVObj.S_No = i + 1;
                grVarianceRateCSVObj.Item_Name = grVarianceItems[i].itemName;
                grVarianceRateCSVObj.Buyer_Item_Name = grVarianceItems[i].buyerItemCode;
                grVarianceRateCSVObj.Uom = grVarianceItems[i].uomName;
                grVarianceRateCSVObj.Price = grVarianceItems[i].price;
                grVarianceRateCSVObj.Supplier = grVarianceItems[i].companyName;
                grVarianceRateCSVObj.PR_No = grVarianceItems[i].prNumber;
                grVarianceRateCSVObj.PR_Quantity = grVarianceItems[i].prQuantity;
                grVarianceRateCSVObj.PO_No = grVarianceItems[i].poNumber;
                grVarianceRateCSVObj.PO_Quantity = grVarianceItems[i].poQuantity;
                grVarianceRateCSVObj.GR_Quantity = grVarianceItems[i].grQuantity;
                grVarianceRateCSVObj.GR_Rate = grVarianceItems[i].grVariance;
                grVarianceRateCSVObj.GR_Date = $filter('date')(grVarianceItems[i].grDate, "dd/MM/yyyy", timeZone);
                grVarianceRateCSVObj.GR_Action_ID = !grVarianceItems[i].grActionId ? 'N/A' : grVarianceItems[i].grActionId;
                grVarianceRateCSVObj.Business_Outlet = grVarianceItems[i].businessOutletName;
                grVarianceRateCSVArray.push(angular.copy(grVarianceRateCSVObj));
            }
            return grVarianceRateCSVArray;
        }

        function getGlCodeMappingsCsv(glCodeMappings) {
            var glCodeMappingsCsvData = [];
            glCodeMappingsCsvData = glCodeMappings.map(function (mapping, index) {
                return {
                    "slNo": index + 1,
                    "purchaseOrderType": mapping.purchaseOrderTypes.name,
                    "itemGroup": mapping.itemGroup.name,
                    "glCode": mapping.glCodes.code,
                    "glSpecificeItemGroup": mapping.glSpecificItemGroups.name
                }
            });
            return glCodeMappingsCsvData;
        }

        //get CSV report for Price Trends - Raw Data
        function getPriceTrendsRawDataCSV(PriceTrendsRawDataItems) {
            var priceTrendsRawDataArray = [];
            var priceTrendsRawData = {};
            var index = 0;
            for (var i = 0; i < PriceTrendsRawDataItems.length; i++) {
                var data = PriceTrendsRawDataItems[i];
                priceTrendsRawData.S_No = index + 1;
                priceTrendsRawData.Item_Group = data.itemGroup;
                priceTrendsRawData.Item_Name = data.itemName;
                priceTrendsRawData.Item_Code = data.itemCode;
                priceTrendsRawData.Supplier_Name = data.supplier;
                priceTrendsRawData.Price = $filter('showsDecimal')(data.price) + 'SGD';
                priceTrendsRawData.Quantity = data.quantity;
                priceTrendsRawData.Date = data.dateChange;
                var temp = angular.copy(priceTrendsRawData);
                index++;
                priceTrendsRawDataArray.push(temp);
            }
            return priceTrendsRawDataArray;
        }

        //get CSV report for Price Trends - Monthly Data
        function getMonthlyPriceTrendsReportsCSV(PriceTrendsItems, metaData) {
            var priceTrendsDataArray = [];
            _.forEach(metaData.time, function (row, index) {
                var priceTrendsData = {};
                priceTrendsData.S_No = index + 1;
                _.forEach(PriceTrendsItems, function (column, keys) {
                    if (index <= (12 - (moment(metaData.fromDate).month() + 1))) {
                        priceTrendsData.month = row + ' ' + moment(metaData.fromDate).year();
                        if (column[moment(metaData.fromDate).year()][row] !== 0) {
                            priceTrendsData[keys] = $filter('decimalNumber')(column[moment(metaData.fromDate).year()][row], 2) + 'SGD';
                        }
                        else {
                            priceTrendsData[keys] = column[moment(metaData.toDate).year()][row];
                        }
                    }
                    else {
                        priceTrendsData.month = row + ' ' + moment(metaData.toDate).year();
                        if (column[moment(metaData.toDate).year()][row] !== 0) {
                            priceTrendsData[keys] = $filter('decimalNumber')(column[moment(metaData.toDate).year()][row], 2) + 'SGD';
                        }
                        else {
                            priceTrendsData[keys] = column[moment(metaData.toDate).year()][row];
                        }
                    }
                })
                var temp = angular.copy(priceTrendsData);
                priceTrendsDataArray.push(temp);
            })
            return priceTrendsDataArray;
        }

        //get CSV report for Price Trends - Quarterly Data
        function getQuarterlyPriceTrendsReportsCSV(PriceTrendsItems, metaData) {
            var priceTrendsDataArray = [];
            _.forEach(metaData.time, function (row, index) {
                var priceTrendsData = {};
                priceTrendsData.S_No = index + 1;
                _.forEach(PriceTrendsItems, function (column, keys) {
                    if (index <= (4 - metaData.time[0])) {
                        priceTrendsData.quarter = 'Q' + row + ' ' + moment(metaData.fromDate).year();
                        if (column[moment(metaData.fromDate).year()][row] !== 0 && column[moment(metaData.fromDate).year()][row] !== null) {
                            priceTrendsData[keys] = $filter('decimalNumber')(column[moment(metaData.fromDate).year()][row], 2) + 'SGD';
                        }
                        else {
                            priceTrendsData[keys] = 0;
                        }
                    }
                    else {
                        priceTrendsData.quarter = 'Q' + row + ' ' + moment(metaData.toDate).year();
                        if (column[moment(metaData.toDate).year()][row] !== 0 && column[moment(metaData.toDate).year()][row] !== null) {
                            priceTrendsData[keys] = $filter('decimalNumber')(column[moment(metaData.toDate).year()][row], 2) + 'SGD';
                        }
                        else {
                            priceTrendsData[keys] = 0;
                        }
                    }
                })
                var temp = angular.copy(priceTrendsData);
                priceTrendsDataArray.push(temp);
            })
            return priceTrendsDataArray;
        }

        //get CSV report for Price Trends - Day view Data
        function getDailyPriceTrendsReportsCSV(PriceTrendsItems, metaData) {
            var priceTrendsDataArray = [];

            _.forEach(PriceTrendsItems, function (itemData, key) {
                var priceTrendsData = {};
                _.forEach(itemData, function (itemYearData) {
                    _.forEach(itemYearData, function (priceData) {
                        priceTrendsData.itemCode = priceData.itemCode;
                        priceTrendsData.itemName = priceData.itemName;
                        priceTrendsData.itemGroup = priceData.itemGroup;
                        priceTrendsData.supplier = priceData.supplier;
                        priceTrendsData.price = $filter('showsDecimal')(priceData.price) + 'SGD';
                        priceTrendsData.dateChange = $filter('date')(priceData.dateChange, "dd/MM/yyyy", timeZone);
                        var temp = angular.copy(priceTrendsData);
                        priceTrendsDataArray.push(temp);
                    })
                })

            })
            return priceTrendsDataArray;
        }



        function getPurchaseOrderListingCSV(purchaseOrderDetails) {
            var purchaseOrderCSVDataArray = [];
            var purchaseOrderCSVData = {};
            _.forEach(purchaseOrderDetails, function (poItem) {
                purchaseOrderCSVData.poNUmber = poItem.purchaseOrderNumber;
                purchaseOrderCSVData.businessOutletName = poItem.businessOutletName;
                purchaseOrderCSVData.supplier = poItem.companyName;
                purchaseOrderCSVData.poDate = $filter('date')(poItem.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                purchaseOrderCSVData.itemCode = poItem.itemCode;
                purchaseOrderCSVData.itemName = poItem.itemName;
                purchaseOrderCSVData.uom = poItem.uomName;
                purchaseOrderCSVData.poQuantity = poItem.poQuantity;
                purchaseOrderCSVData.receivedQuantity = poItem.receivedQuantity;
                if (accessForPriceDisplay) {
                    purchaseOrderCSVData.unitPrice = poItem.freeItem ? 'NA' : poItem.unitPrice;
                    purchaseOrderCSVData.totalPrice = poItem.freeItem ? 'NA' : $filter('decimalNumber')(poItem.totalPrice, 2);
                    purchaseOrderCSVData.currency = poItem.currency;
                }
                purchaseOrderCSVData.receivedDate = $filter('date')(poItem.receivedDate, "dd/MM/yyyy", timeZone);
                purchaseOrderCSVData.specialRequest = poItem.specialRequest ? poItem.specialRequest : 'NA';
                purchaseOrderCSVData.supplierRemark = poItem.supplierRemark ? poItem.supplierRemark : 'NA';
                purchaseOrderCSVData.purchaseOrderDate = $filter('date')(poItem.purchaseOrderDate, "dd/MM/yyyy HH:mm:ss", timeZone);
                purchaseOrderCSVData.deliveryDate = $filter('date')(poItem.deliveryDate, "dd/MM/yyyy", timeZone);
                var temp = angular.copy(purchaseOrderCSVData);
                purchaseOrderCSVDataArray.push(temp);
                purchaseOrderCSVData = {};
            });

            return purchaseOrderCSVDataArray;
        }

        /**
         * [getQuickBooksSupplierInvoiceCSV Export data for invoices selected]
         * @param  {[Object]} batchQuickBookData     [data to be exported]
         * @return {[List]}   quickBookArrayList    [list of CSV data]
         */
        function getQuickBooksSupplierInvoiceCSV(batchQuickBookData) {
            var quickBooksInvoiceCSVData = {};
            quickBooksInvoiceCSVData.invoiceNumber = batchQuickBookData.invoiceNumber;
            quickBooksInvoiceCSVData.customer = batchQuickBookData.customerName;
            quickBooksInvoiceCSVData.invoiceDate = $filter('date')(batchQuickBookData.invoiceDate, 'dd/MM/yyyy', timeZone);
            quickBooksInvoiceCSVData.dueDate = $filter('date')(batchQuickBookData.dueDate, 'dd/MM/yyyy', timeZone);
            quickBooksInvoiceCSVData.shippingDate = $filter('date')(batchQuickBookData.shippingDate, 'dd/MM/yyyy', timeZone);
            quickBooksInvoiceCSVData.shipVia = "";
            quickBooksInvoiceCSVData.trackingNo = "";
            quickBooksInvoiceCSVData.terms = batchQuickBookData.terms;
            quickBooksInvoiceCSVData.billingAddressLine1 = batchQuickBookData.billingAddress;
            quickBooksInvoiceCSVData.billingAddressLine2 = "";
            quickBooksInvoiceCSVData.billingAddressLine3 = "";
            quickBooksInvoiceCSVData.billingAddressCity = "";
            quickBooksInvoiceCSVData.billingAddressPostalCode = batchQuickBookData.billingPostalCode;
            quickBooksInvoiceCSVData.billingAddressCountry = batchQuickBookData.billingCountry;
            quickBooksInvoiceCSVData.billingAddressCountrySubdivisionCode = "";
            quickBooksInvoiceCSVData.shippingAddressLine1 = batchQuickBookData.shippingAddress;
            quickBooksInvoiceCSVData.shippingAddressLine2 = "";
            quickBooksInvoiceCSVData.shippingAddressLine3 = "";
            quickBooksInvoiceCSVData.shippingAddressCity = "";
            quickBooksInvoiceCSVData.shippingAddressPostalCode = batchQuickBookData.shippingPostalCode;
            quickBooksInvoiceCSVData.shippingAddressCountry = batchQuickBookData.shippingCountry;
            quickBooksInvoiceCSVData.shippingAddressCountrySubdivisionCode = "";
            quickBooksInvoiceCSVData.memo = batchQuickBookData.poNumber;
            quickBooksInvoiceCSVData.messagedisplayedoninvoice = batchQuickBookData.invoiceComment;
            quickBooksInvoiceCSVData.email = batchQuickBookData.buyerEmail;
            quickBooksInvoiceCSVData.globalTaxCalculation = batchQuickBookData.tax != null ? "TaxExclusive" : "";
            quickBooksInvoiceCSVData.shipping = "";
            quickBooksInvoiceCSVData.shippingTaxCode = "";
            quickBooksInvoiceCSVData.discountAmount = batchQuickBookData.discount;
            quickBooksInvoiceCSVData.discountPercent = "";
            quickBooksInvoiceCSVData.discountAccount = "";
            quickBooksInvoiceCSVData.serviceDate = "";
            quickBooksInvoiceCSVData.productService = batchQuickBookData.glCode === null || batchQuickBookData.glCode == "" ? batchQuickBookData.itemCode : batchQuickBookData.glCode;
            quickBooksInvoiceCSVData.productServiceDescription = batchQuickBookData.glCode == null || batchQuickBookData.glCode == "" ? batchQuickBookData.productName : batchQuickBookData.itemCode + "-" + batchQuickBookData.productName;
            quickBooksInvoiceCSVData.productServiceQuantity = batchQuickBookData.quantity;
            quickBooksInvoiceCSVData.productServiceRate = batchQuickBookData.unitPrice;
            quickBooksInvoiceCSVData.productServiceAmount = batchQuickBookData.itemTotalAmount;
            quickBooksInvoiceCSVData.productServiceTaxCode = batchQuickBookData.tax != null ? batchQuickBookData.gst + '% SR('+ batchQuickBookData.gst + '%)' : "";
            quickBooksInvoiceCSVData.productServiceTaxAmount = $filter('decimalNumber')(batchQuickBookData.addedGst, 4);
            quickBooksInvoiceCSVData.productServiceClass = "";
            quickBooksInvoiceCSVData.deposit = "";
            quickBooksInvoiceCSVData.location = "";
            quickBooksInvoiceCSVData.showSubTotal = batchQuickBookData.subTotal;
            quickBooksInvoiceCSVData.customFieldValue1 = "";
            quickBooksInvoiceCSVData.customFieldValue2 = "";
            quickBooksInvoiceCSVData.customFieldValue3 = "";
            quickBooksInvoiceCSVData.currencyCode = "";
            quickBooksInvoiceCSVData.exchangeRate = "";
            quickBooksInvoiceCSVData.printStatus = "";
            quickBooksInvoiceCSVData.emailStatus = "";
            return quickBooksInvoiceCSVData;
        }


        // Jurnal export for OCR - purchase orders
        function getPurchaseInvoicesJurnalCSV(invoiceData) {
            var purchaseInvoicesJurnalDataArray = [];
            var purchaseInvoicesJurnalCSVData = {};
            _.forEach(invoiceData, function (invoice) {
                purchaseInvoicesJurnalCSVData.Vendor = invoice.vendor;
                purchaseInvoicesJurnalCSVData.Email = invoice.email;
                purchaseInvoicesJurnalCSVData.BillingAddress = invoice.billingAddress;
                purchaseInvoicesJurnalCSVData.ShippingAddress = invoice.shippingAddress;
                purchaseInvoicesJurnalCSVData.PurchaseDate = $filter('date')(invoice.purchaseDate, "dd/MM/yyyy");
                purchaseInvoicesJurnalCSVData.DueDate = $filter('date')(invoice.dueDate, "dd/MM/yyyy");
                purchaseInvoicesJurnalCSVData.ShippingDate = $filter('date')(invoice.shippingDate, "dd/MM/yyyy");
                purchaseInvoicesJurnalCSVData.ShipVia = '';
                purchaseInvoicesJurnalCSVData.TrackingNo = '';
                purchaseInvoicesJurnalCSVData.VendorRefNo = invoice.vendorRefNo;
                purchaseInvoicesJurnalCSVData.PurchaseNumber = invoice.purchaseNumber;
                purchaseInvoicesJurnalCSVData.Message = '';
                purchaseInvoicesJurnalCSVData.Memo = invoice.memo;
                purchaseInvoicesJurnalCSVData.ProductName = invoice.productName;
                purchaseInvoicesJurnalCSVData.Description = invoice.description;
                purchaseInvoicesJurnalCSVData.Quantity = invoice.quantity;
                purchaseInvoicesJurnalCSVData.UnitPrice = $filter('showsDecimal')(invoice.unitPrice);
                purchaseInvoicesJurnalCSVData.ProductDiscountRate = $filter('decimalNumber')(invoice.productDiscountRate, 2);
                purchaseInvoicesJurnalCSVData.PurchaseDiscountRate = $filter('decimalNumber')(invoice.purchaseDiscountRate, 2);
                purchaseInvoicesJurnalCSVData.TaxName = '';
                purchaseInvoicesJurnalCSVData.TaxRate = invoice.taxRate;
                purchaseInvoicesJurnalCSVData.ShippingFee = invoice.shippingFee;
                purchaseInvoicesJurnalCSVData.paidStatus = '';
                purchaseInvoicesJurnalCSVData.PaymentMethod = '';
                purchaseInvoicesJurnalCSVData.PaidFromAccountCode = '';
                purchaseInvoicesJurnalCSVData.Tags = '';
                purchaseInvoicesJurnalCSVData.WarehouseName = ''
                purchaseInvoicesJurnalCSVData.CurrencyCode = invoice.currencyCode
                var temp = angular.copy(purchaseInvoicesJurnalCSVData);
                purchaseInvoicesJurnalDataArray.push(temp);
                purchaseInvoicesJurnalCSVData = {};
            });
            return purchaseInvoicesJurnalDataArray;
        }


        // Jurnal export for supplier - invoice reports
        function getSalesInvoicesJurnalCSV(invoiceData) {
            var salesInvoicesJurnalDataArray = [];
            var salesInvoicesJurnalCSVData = {};
            _.forEach(invoiceData, function (invoice) {
                salesInvoicesJurnalCSVData.Customer = invoice.customer;
                salesInvoicesJurnalCSVData.Email = invoice.email;
                salesInvoicesJurnalCSVData.BillingAddress = invoice.billingAddress;
                salesInvoicesJurnalCSVData.ShippingAddress = invoice.shippingAddress;
                salesInvoicesJurnalCSVData.InvoiceDate = $filter('date')(invoice.invoiceDate, "dd/MM/yyyy");
                salesInvoicesJurnalCSVData.DueDate = $filter('date')(invoice.dueDate, "dd/MM/yyyy");
                salesInvoicesJurnalCSVData.ShippingDate = $filter('date')(invoice.shippingDate, "dd/MM/yyyy");
                salesInvoicesJurnalCSVData.ShipVia = '';
                salesInvoicesJurnalCSVData.TrackingNo = '';
                salesInvoicesJurnalCSVData.CustomerRefNo = invoice.customerRefNo;
                salesInvoicesJurnalCSVData.InvoiceNumber = invoice.invoiceNumber;
                salesInvoicesJurnalCSVData.Message = '';
                salesInvoicesJurnalCSVData.Memo = invoice.memo;
                salesInvoicesJurnalCSVData.ProductName = invoice.productName;
                salesInvoicesJurnalCSVData.Description = invoice.description;
                salesInvoicesJurnalCSVData.Quantity = invoice.quantity;
                salesInvoicesJurnalCSVData.UnitPrice = $filter('showsDecimal')(invoice.unitPrice);
                salesInvoicesJurnalCSVData.ProductDiscountRate = $filter('decimalNumber')(invoice.productDiscountRate, 2);
                salesInvoicesJurnalCSVData.InvoiceDiscountRate = $filter('decimalNumber')(invoice.purchaseDiscountRate, 2);
                salesInvoicesJurnalCSVData.TaxName = '';
                salesInvoicesJurnalCSVData.TaxRate = invoice.taxRate;
                salesInvoicesJurnalCSVData.ShippingFee = invoice.shippingFee;
                salesInvoicesJurnalCSVData.paidStatus = '';
                salesInvoicesJurnalCSVData.PaymentMethod = '';
                salesInvoicesJurnalCSVData.PaidToAccountCode = '';
                salesInvoicesJurnalCSVData.Tags = '';
                salesInvoicesJurnalCSVData.WarehouseName = ''
                var temp = angular.copy(salesInvoicesJurnalCSVData);
                salesInvoicesJurnalDataArray.push(temp);
                salesInvoicesJurnalCSVData = {};
            });
            return salesInvoicesJurnalDataArray;
        }

        /**
         * [export purchase invoice csv data]
         */
        function exportPurchaseInvoiceXeroCSV(purchaseInvoice) {
            var purchaseInvoiceDetailsCSVArray = [];
            var purchaseInvoiceDetailsCSVData = {};
            for (var i = 0; i < purchaseInvoice.items.length; i++) {
                purchaseInvoiceDetailsCSVData.contactName = purchaseInvoice.supplier.companyName;
                purchaseInvoiceDetailsCSVData.EmailAddress = purchaseInvoice.supplier.contactEmail;
                purchaseInvoiceDetailsCSVData.POAddressLine1 = purchaseInvoice.supplier.companyAddress;
                purchaseInvoiceDetailsCSVData.POAddressLine2 = "";
                purchaseInvoiceDetailsCSVData.POAddressLine3 = "";
                purchaseInvoiceDetailsCSVData.POAddressLine4 = "";
                purchaseInvoiceDetailsCSVData.POCity = "";
                purchaseInvoiceDetailsCSVData.PORegion = "";
                purchaseInvoiceDetailsCSVData.POPostalCode = "";
                purchaseInvoiceDetailsCSVData.POCountry = "";
                purchaseInvoiceDetailsCSVData.InvoiceNumber = getInvoiceNumber(purchaseInvoice, true);
                purchaseInvoiceDetailsCSVData.InvoiceDate = getInvoiceDate(purchaseInvoice, "dd/MM/yyyy");
                var dueDate = new Date(purchaseInvoice.ocrInvoiceDate ? purchaseInvoice.ocrInvoiceDate : (purchaseInvoice.invoiceStatus === 'PROCESSED' ? purchaseInvoice.invoice.invoiceDate : ''));
                dueDate = dueDate.setDate(dueDate.getDate() + 30);
                purchaseInvoiceDetailsCSVData.DueDate = $filter('date')(dueDate, "dd/MM/yyyy", timeZone);
                purchaseInvoiceDetailsCSVData.Total = "";
                purchaseInvoiceDetailsCSVData.InventoryItemCode = purchaseInvoice.items[i].itemCode;
                purchaseInvoiceDetailsCSVData.Description = purchaseInvoice.items[i].itemDescription;
                purchaseInvoiceDetailsCSVData.Quantity = purchaseInvoice.items[i].acceptedQuantity;
                purchaseInvoiceDetailsCSVData.UnitAmount = purchaseInvoice.items[i].price;
                purchaseInvoiceDetailsCSVData.AccountCode = purchaseInvoice.items[i].glCodeMappings.glCodes.code;
                purchaseInvoiceDetailsCSVData.TaxType = purchaseInvoice.supplier.tax == "Inactive" ? "No Tax (0%)" : "Standard-Rated Purchases (" + purchaseInvoice.gst + "%)";
                purchaseInvoiceDetailsCSVData.TaxAmount = "";
                purchaseInvoiceDetailsCSVData.TrackingName1 = "Business Unit";
                purchaseInvoiceDetailsCSVData.TrackingOption1 = purchaseInvoice.items[i].businessOutletCode;
                purchaseInvoiceDetailsCSVData.TrackingName2 = "Controllable";
                purchaseInvoiceDetailsCSVData.TrackingOption2 = "Controllable";
                purchaseInvoiceDetailsCSVData.Currency = purchaseInvoice.items[i].currency;
                var temp = angular.copy(purchaseInvoiceDetailsCSVData);
                purchaseInvoiceDetailsCSVArray.push(temp);
                purchaseInvoiceDetailsCSVData = {};
            }
            return purchaseInvoiceDetailsCSVArray;
        }

        /**
          * [export sales invoice csv data]
          */
        function exportSalesInvoiceXeroCSV(salesInvoiceDetails) {
            var salesInvoiceDetailsCSVData = {};
            salesInvoiceDetailsCSVData.contactName = salesInvoiceDetails.customerName;
            salesInvoiceDetailsCSVData.EmailAddress = salesInvoiceDetails.buyerEmail;
            salesInvoiceDetailsCSVData.POAddressLine1 = salesInvoiceDetails.billingAddress;
            salesInvoiceDetailsCSVData.POAddressLine2 = "";
            salesInvoiceDetailsCSVData.POAddressLine3 = "";
            salesInvoiceDetailsCSVData.POAddressLine4 = "";
            salesInvoiceDetailsCSVData.POCity = "";
            salesInvoiceDetailsCSVData.PORegion = "";
            salesInvoiceDetailsCSVData.POPostalCode = salesInvoiceDetails.shippingPostalCode;
            salesInvoiceDetailsCSVData.POCountry = salesInvoiceDetails.billingCountry;
            salesInvoiceDetailsCSVData.InvoiceNumber = salesInvoiceDetails.invoiceNumber;
            salesInvoiceDetailsCSVData.Reference = salesInvoiceDetails.invoiceNumber;
            salesInvoiceDetailsCSVData.InvoiceDate = $filter('date')(salesInvoiceDetails.invoiceDate, "dd/MM/yyyy", timeZone);
            var dueDate = new Date(salesInvoiceDetails.invoiceDate);
            dueDate = dueDate.setDate(dueDate.getDate() + 30);
            salesInvoiceDetailsCSVData.DueDate = $filter('date')(dueDate, "dd/MM/yyyy", timeZone);
            salesInvoiceDetailsCSVData.Total = "";
            salesInvoiceDetailsCSVData.InventoryItemCode = salesInvoiceDetails.itemCode;
            salesInvoiceDetailsCSVData.Description = salesInvoiceDetails.productDescription;
            salesInvoiceDetailsCSVData.Quantity = salesInvoiceDetails.quantity;
            salesInvoiceDetailsCSVData.UnitAmount = salesInvoiceDetails.unitPrice;
            salesInvoiceDetailsCSVData.AccountCode = salesInvoiceDetails.glCode;
            salesInvoiceDetailsCSVData.TaxType = salesInvoiceDetails.tax == 'Inactive' ? "No Tax (0%)" : "Standard-Rated Purchases (" + salesInvoiceDetails.gst + "%)";
            salesInvoiceDetailsCSVData.TaxAmount = "";
            salesInvoiceDetailsCSVData.TrackingName1 = "";
            salesInvoiceDetailsCSVData.TrackingOption1 = "";
            salesInvoiceDetailsCSVData.TrackingName2 = "";
            salesInvoiceDetailsCSVData.TrackingOption2 = "";
            salesInvoiceDetailsCSVData.Currency = salesInvoiceDetails.itemCurrency;
            salesInvoiceDetailsCSVData.BrandingTheme = "";
            return salesInvoiceDetailsCSVData;
        }

        // Order template CSV export
        function getOrderTemplateCSVDataById(templateItem) {
            var templateItemDetailsCSVArray = [];
            var templateItemDetailsCSVData = {};
            for (var i = 0; i < templateItem.items.length; i++) {
                templateItemDetailsCSVData.templateName = templateItem.name;
                templateItemDetailsCSVData.itemCode = templateItem.items[i].itemCode;
                templateItemDetailsCSVData.itemName = templateItem.items[i].itemName;
                templateItemDetailsCSVData.itemGroup = templateItem.items[i].itemGroup.name;
                templateItemDetailsCSVData.uom = templateItem.items[i].uom.name;
                templateItemDetailsCSVData.quantity = "";
                templateItemDetailsCSVData.deliveryDate = "";
                templateItemDetailsCSVData.specialRequest = "";

                var temp = angular.copy(templateItemDetailsCSVData);
                templateItemDetailsCSVArray.push(temp);
                templateItemDetailsCSVData = {};
            }
            return templateItemDetailsCSVArray;
        }

        // Buy now - Order template CSV export
        function getBuyNowOrderTemplateCSVData(templateItem, itemList) {
            var templateItemDetailsCSVArray = [];
            var templateItemDetailsCSVData = {};
            for (var i = 0; i < itemList.length; i++) {
                templateItemDetailsCSVData.templateName = templateItem.name;
                templateItemDetailsCSVData.itemCode = itemList[i].itemCode;
                templateItemDetailsCSVData.itemName = itemList[i].itemName;
                templateItemDetailsCSVData.itemGroup = itemList[i].itemGroupName;
                templateItemDetailsCSVData.uom = itemList[i].uomName;
                templateItemDetailsCSVData.quantity = itemList[i].quantity;
                templateItemDetailsCSVData.deliveryDate = $filter('date')(itemList[i].deliveryDate, "dd/MM/yyyy", timeZone);
                templateItemDetailsCSVData.specialRequest = itemList[i].specialRequest;
                var temp = angular.copy(templateItemDetailsCSVData);
                templateItemDetailsCSVArray.push(temp);
                templateItemDetailsCSVData = {};
            }
            return templateItemDetailsCSVArray;
        }

        /**
         * AR million order template csv export
         * @param {object} csvGenerationData
         */
        function generateARMillionOrderCSVData(responseData) {
            var millionOrderTemplateCSVArray = [];
            var millionOrderTemplateCSVData;
            for (var i = 0; i < responseData.length; i++) {
                millionOrderTemplateCSVData = {};
                millionOrderTemplateCSVData.accno = responseData[i].accountNo;
                millionOrderTemplateCSVData.doc_type = responseData[i].docType;
                millionOrderTemplateCSVData.doc_no = responseData[i].docNo;
                millionOrderTemplateCSVData.seq = responseData[i].sequence;
                millionOrderTemplateCSVData.doc_date = $filter('date')(responseData[i].docDate, "dd/MM/yyyy", timeZone);
                millionOrderTemplateCSVData.refno = responseData[i].referenceNo;
                millionOrderTemplateCSVData.refno2 = responseData[i].referenceNo2;
                millionOrderTemplateCSVData.refno3 = responseData[i].referenceNo3;
                millionOrderTemplateCSVData.desp = responseData[i].desp;
                millionOrderTemplateCSVData.desp2 = responseData[i].desp2;
                millionOrderTemplateCSVData.desp3 = responseData[i].desp3;
                millionOrderTemplateCSVData.desp4 = responseData[i].desp4;
                millionOrderTemplateCSVData.amount = $filter('decimalNumber')(responseData[i].amount ? responseData[i].amount : 0, 2);
                millionOrderTemplateCSVData.debit = $filter('decimalNumber')(responseData[i].debit ? responseData[i].debit : 0, 2);
                millionOrderTemplateCSVData.credit = $filter('decimalNumber')(responseData[i].credit ? responseData[i].credit : 0, 2);
                millionOrderTemplateCSVData.fx_amount = $filter('decimalNumber')(responseData[i].fxAmount ? responseData[i].fxAmount : 0, 2);
                millionOrderTemplateCSVData.fx_debit = $filter('decimalNumber')(responseData[i].fxDebit ? responseData[i].fxDebit : 0, 2);
                millionOrderTemplateCSVData.fx_credit = $filter('decimalNumber')(responseData[i].fxCredit ? responseData[i].fxCredit : 0, 2);
                millionOrderTemplateCSVData.fx_rate = $filter('decimalNumber')(responseData[i].fxRate ? responseData[i].fxRate : 0, 2);
                millionOrderTemplateCSVData.curr_code = responseData[i].currCode;
                millionOrderTemplateCSVData.taxcode = responseData[i].taxCode;
                millionOrderTemplateCSVData.taxable = responseData[i].taxable;
                millionOrderTemplateCSVData.fx_taxable = $filter('decimalNumber')(responseData[i].fxTaxable ? responseData[i].fxTaxable : 0, 2);
                millionOrderTemplateCSVData.link_seq = responseData[i].linkSeq;
                millionOrderTemplateCSVData.billtype = responseData[i].billType;
                millionOrderTemplateCSVData.remark1 = responseData[i].remark1;
                millionOrderTemplateCSVData.remark2 = responseData[i].remark2;
                millionOrderTemplateCSVData.batchno = responseData[i].batchNo;
                millionOrderTemplateCSVData.projcode = responseData[i].projectCode;
                millionOrderTemplateCSVData.deptcode = responseData[i].deptCode;
                millionOrderTemplateCSVData.accmgr_id = responseData[i].accountManagerId;
                millionOrderTemplateCSVData.cheque_no = responseData[i].chequeNo;
                var temp = angular.copy(millionOrderTemplateCSVData);
                millionOrderTemplateCSVArray.push(temp);
            }
            return millionOrderTemplateCSVArray;
        }

        /**
         * million order template csv export
         * @param {object} templateItem
         */
        function getMillionOrderTemplateCSVData(templateItem) {
            var millionOrderTemplateCSVArray = [];
            var millionOrderTemplateCSVData = {};
            for (var i = 0; i < templateItem.length; i++) {
                millionOrderTemplateCSVData.accno = templateItem[i].accountNo;
                millionOrderTemplateCSVData.doc_type = templateItem[i].docType
                millionOrderTemplateCSVData.doc_no = templateItem[i].docNo;
                millionOrderTemplateCSVData.seq = templateItem[i].sequence;
                millionOrderTemplateCSVData.doc_date = $filter('date')(templateItem[i].docDate, "dd/MM/yyyy", timeZone);
                millionOrderTemplateCSVData.refno = templateItem[i].referenceNo;
                millionOrderTemplateCSVData.refno2 = templateItem[i].referenceNo2;
                millionOrderTemplateCSVData.refno3 = templateItem[i].referenceNo3;
                millionOrderTemplateCSVData.desp = templateItem[i].desp;
                millionOrderTemplateCSVData.desp2 = templateItem[i].desp2;
                millionOrderTemplateCSVData.desp3 = templateItem[i].desp3;
                millionOrderTemplateCSVData.desp4 = templateItem[i].desp4;
                millionOrderTemplateCSVData.amount = $filter('decimalNumber')(templateItem[i].amount ? templateItem[i].amount : 0, 2);
                millionOrderTemplateCSVData.debit = $filter('decimalNumber')(templateItem[i].debit ? templateItem[i].debit : 0, 2);
                millionOrderTemplateCSVData.credit = $filter('decimalNumber')(templateItem[i].credit ? templateItem[i].credit : 0, 2);
                millionOrderTemplateCSVData.fx_amount = $filter('decimalNumber')(templateItem[i].fxAmount ? templateItem[i].fxAmount : 0, 2);
                millionOrderTemplateCSVData.fx_debit = $filter('decimalNumber')(templateItem[i].fxDebit ? templateItem[i].fxDebit : 0, 2);
                millionOrderTemplateCSVData.fx_credit = $filter('decimalNumber')(templateItem[i].fxCredit ? templateItem[i].fxCredit : 0, 2);
                millionOrderTemplateCSVData.fx_rate = $filter('decimalNumber')(templateItem[i].fxRate ? templateItem[i].fxRate : 0, 2);
                millionOrderTemplateCSVData.curr_code = templateItem[i].currCode;
                millionOrderTemplateCSVData.taxcode = templateItem[i].taxCode;
                millionOrderTemplateCSVData.taxable = templateItem[i].taxable;
                millionOrderTemplateCSVData.fx_taxable = $filter('decimalNumber')(templateItem[i].fxTaxable ? templateItem[i].fxTaxable : 0, 2);
                millionOrderTemplateCSVData.link_seq = templateItem[i].linkSeq;
                millionOrderTemplateCSVData.billtype = templateItem[i].billType;
                millionOrderTemplateCSVData.remark1 = templateItem[i].remark1;
                millionOrderTemplateCSVData.remark2 = templateItem[i].remark2;
                millionOrderTemplateCSVData.batchno = templateItem[i].batchNo;
                millionOrderTemplateCSVData.projcode = templateItem[i].projectCode;
                millionOrderTemplateCSVData.deptcode = templateItem[i].deptCode;
                millionOrderTemplateCSVData.accmgr_id = templateItem[i].accountManagerId;
                millionOrderTemplateCSVData.cheque_no = templateItem[i].chequeNo;
                var temp = angular.copy(millionOrderTemplateCSVData);
                millionOrderTemplateCSVArray.push(temp);
                millionOrderTemplateCSVData = {};
            }
            return millionOrderTemplateCSVArray;
        }

        // Peppol invoice CSV$filter('date')(itemList[i].deliveryDate, "dd/MM/yyyy", timeZone);
        function getPeppolInvoiceCSV(invoices) {
            var invoiceCSVArray = [];
            var invoiceCSVData = {};
            _.forEach(invoices, function (invoice) {
                invoiceCSVData.Invoice_Id = invoice.invoiceNumber;
                invoiceCSVData.Buyer_Organisation_Name = invoice.buyerInformationAddressOrganisationName;
                invoiceCSVData.Invoice_Issue_Date = $filter('date')(invoice.invoiceIssueDate, "dd/MM/yyyy", timeZone);
                invoiceCSVData.Actual_Delivery_Date = $filter('date')(invoice.actualDeliveryDate, "dd/MM/yyyy", timeZone);
                invoiceCSVData.Payable_Amount = invoice.payableAmount;
                invoiceCSVData.Invoice_Status = invoice.peppolTransmissionStatus;
                var temp = angular.copy(invoiceCSVData);
                invoiceCSVArray.push(temp);
                invoiceCSVData = {};
            })
            return invoiceCSVArray;
        }

        // Peppol po CSV$filter('date')(itemList[i].deliveryDate, "dd/MM/yyyy", timeZone);
        function getPeppolPoCSV(pos) {
            var poCSVArray = [];
            var poCSVData = {};
            _.forEach(pos, function (po) {
                poCSVData.Po_Id = po.poNumber;
                poCSVData.Supplier_Organisation_Name = po.supplierInformationAddressOrganisationName;
                poCSVData.po_Issue_Date = $filter('date')(po.poIssueDate, "dd/MM/yyyy", timeZone);
                poCSVData.Payable_Amount = po.payableAmount;
                poCSVData.Po_Status = po.peppolTransmissionStatus;
                var temp = angular.copy(poCSVData);
                poCSVArray.push(temp);
                poCSVData = {};
            })
            return poCSVArray;
        }

        //getSupplierItemMappingCSV Report
        function getSupplierItemMappingCSV(selectedItems) {
            var ItemBuyerMappingCSVDataArray = [];
            var ItemBuyerMappingCSVData = {};
            for (var i = 0; i < selectedItems.length; i++) {
                var buyerItemCode = selectedItems[i].buyerItemCode;
                var buyerItemName = selectedItems[i].buyerItemName;
                var data = selectedItems[i].items;
                ItemBuyerMappingCSVData.S_no = i + 1;
                ItemBuyerMappingCSVData.id = data.id;
                ItemBuyerMappingCSVData.supplier = data.supplier.companyName;
                //ItemBuyerMappingCSVData.category=" ";
                ItemBuyerMappingCSVData.subCategory = "";
                if (buyerItemCode) {
                    ItemBuyerMappingCSVData.itemCode = buyerItemCode;
                } else {
                    ItemBuyerMappingCSVData.itemCode = data.itemCode;
                }

                if (buyerItemName) {
                    ItemBuyerMappingCSVData.itemName = buyerItemName;
                } else {
                    ItemBuyerMappingCSVData.itemName = data.itemName;
                }

                if (selectedItems[i].inventoryUom) {
                    ItemBuyerMappingCSVData.uom = selectedItems[i].inventoryUom;
                } else {
                    ItemBuyerMappingCSVData.uom = data.uom.name;
                }

                ItemBuyerMappingCSVData.price = data.price;
                ItemBuyerMappingCSVData.minLevel = " ";
                ItemBuyerMappingCSVData.maxLevel = " ";
                ItemBuyerMappingCSVData.reOrderLevel = " ";
                ItemBuyerMappingCSVData.reOrderQuantity = " ";
                ItemBuyerMappingCSVData.location = " ";
                ItemBuyerMappingCSVData.warehouse = " ";
                ItemBuyerMappingCSVData.bin = " ";
                ItemBuyerMappingCSVData.batchManagement = " ";
                var temp = angular.copy(ItemBuyerMappingCSVData);
                ItemBuyerMappingCSVDataArray.push(temp);
            }
            return ItemBuyerMappingCSVDataArray;
        }

        function getSAGA50CSV(poDetails) {
            var getSAGA50CSVArray = [];
            var getSAGA50CSVData = {};
            for (var i = 0; i < poDetails.length; i++) {
                getSAGA50CSVData.Vendor_ID = poDetails[i].vendorId ? poDetails[i].vendorId : '';
                getSAGA50CSVData.Invoice_No = poDetails[i].invoiceNumber ? poDetails[i].invoiceNumber: '';
                getSAGA50CSVData.Credit_Memo = poDetails[i].creditMemo ? poDetails[i].creditMemo: '';
                getSAGA50CSVData.Date =  $filter('date')(poDetails[i].invoiceDate, "dd/MM/yyyy", timeZone) ;
                getSAGA50CSVData.Date_Due = $filter('date')(poDetails[i].dateDue, "dd/MM/yyyy", timeZone) ;
                getSAGA50CSVData.Accounts_Payable_Account = poDetails[i].accountsPayableAccount;
                getSAGA50CSVData.NumberofDistributions =  poDetails[i].distribution;
                getSAGA50CSVData.InvoiceCMDistribution = poDetails[i].invoiceDistribution;
                getSAGA50CSVData.Apply_Invoice_Distribution = poDetails[i].applyToInvoiceDistribution;
                getSAGA50CSVData.Description = poDetails[i].description;
                getSAGA50CSVData.GLAccount = poDetails[i].glAccount;
                getSAGA50CSVData.Amount = poDetails[i].amount;
                var temp = angular.copy(getSAGA50CSVData);
                getSAGA50CSVArray.push(temp);
                getSAGA50CSVData = {};
            };
            return getSAGA50CSVArray;
        }
        function generateexportSAGACSVData(poDetails) {
            var getSAGA50CSVArray = [];
            var getSAGA50CSVData = {};
            for (var i = 0; i < poDetails.length; i++) {
                getSAGA50CSVData.customerId = poDetails[i].customerId ? poDetails[i].customerId : '';
                getSAGA50CSVData.Invoice_No = poDetails[i].invoiceNumber ? poDetails[i].invoiceNumber : '';
                getSAGA50CSVData.Credit_Memo = poDetails[i].creditMemo ? poDetails[i].creditMemo : '';
                getSAGA50CSVData.Date = "'" + $filter('date')(poDetails[i].invoiceDate, "dd/MM/yyyy", timeZone) + "'";
                getSAGA50CSVData.Date_Due = "'" + $filter('date')(poDetails[i].dateDue, "dd/MM/yyyy", timeZone) + "'";
                getSAGA50CSVData.Accounts_Payable_Account = poDetails[i].accountsPayableAccount;
                getSAGA50CSVData.NumberofDistributions =  poDetails[i].distribution;
                getSAGA50CSVData.InvoiceCMDistribution = poDetails[i].invoiceDistribution;
                getSAGA50CSVData.Apply_Invoice_Distribution = poDetails[i].applyToInvoiceDistribution;
                getSAGA50CSVData.Description = poDetails[i].description;
                getSAGA50CSVData.GLAccount = poDetails[i].glAccount;
                getSAGA50CSVData.Amount = poDetails[i].amount;
                getSAGA50CSVData.TaxType = poDetails[i].taxType;
                var temp = angular.copy(getSAGA50CSVData);
                getSAGA50CSVArray.push(temp);
                getSAGA50CSVData = {};
            };
            return getSAGA50CSVArray;
        }

        //Get Consolidated PurchaseRequest CSV
        function getConsolidatedPurchaseRequestCSV(consolidatedPurchaseOrderDetails) {
            var POarrayList = [];
            _.forEach(consolidatedPurchaseOrderDetails.processOrder, function (poData) {
                poData.items = poData.itemsDetails;
                _.forEach(poData.items, function (individualItem) {
                    if (individualItem.item.freeItem) {
                        individualItem.item.requiredAmount = 'Free Item';
                        individualItem.item.supplierAmount = 'Free Item';
                    }
                    else {
                        individualItem.item.requiredAmount = individualItem.requiredAmount;
                        individualItem.item.supplierAmount = individualItem.supplierAmount;
                    }
                    individualItem = individualItem.item;
                });
                var POarray = getPurchaseOrderCSV(poData);
                //flatten response from service
                _.forEach(POarray, function (item) {
                    POarrayList.push(item);
                });
            });
            return POarrayList;
        }
        function getStockTransferCSVData(data, stockCostView){
            var stockTransferArray = [];
            var stockTransferObj = {}
            _.forEach(data, function(item, index){
            stockTransferObj.slNo =  index + 1;
            stockTransferObj.documentNumber = item.transferRequest.documentNumber ? item.transferRequest.documentNumber : '';
            stockTransferObj.documentDate = item.transferRequest.documentDate ? $filter('date')(item.transferRequest.documentDate,"dd/MM/yyyy",timeZone) : '';
            stockTransferObj.frombusinessOutletName = item.transferRequest.fromOutlet.businessOutletName ? item.transferRequest.fromOutlet.businessOutletName : '';
            stockTransferObj.tobusinessOutletName = item.transferRequest.toOutlet.businessOutletName ? item.transferRequest.toOutlet.businessOutletName : '';
            stockTransferObj.transferQuantity = item.totalQty ?  item.totalQty : '';
            if(stockCostView){
                stockTransferObj.transferCost = item.totalCost ? item.totalCost : '';
            }
            stockTransferObj.remarks = item.transferRequest.remarks ? item.transferRequest.remarks : '';
            stockTransferObj.status = item.transferRequest.status ? item.transferRequest.status : '';
            var temp = angular.copy(stockTransferObj);
            stockTransferArray.push(temp);
            stockTransferObj = {};
            });
            return stockTransferArray;
        }


        function getEGiroFundTransferCSV(data) {
            var eGiroArray = [];
            var eGiroObj = {}
            _.forEach(data, function (each, index) {
                eGiroObj.slNo = index + 1;
                eGiroObj.payer = each.payer || 'NA';
                eGiroObj.payee = each.payee || 'NA';
                eGiroObj.payeeAccountName = each.payeeAccountName || 'NA';
                eGiroObj.payeeAccountNumber = each.payeeAccountNum || 'NA';
                eGiroObj.payeeBicCode = each.payeeBankBicCode || 'NA';
                eGiroObj.amount = each.amount || 'NA';
                eGiroObj.collectionReferenceNumber = each.creditRefNumber || 'NA';
                eGiroObj.paymentReferenceNumber = each.paymentRefNumber || 'NA';
                eGiroObj.eGiroInitiativeDate = each.egiroInitiatedDate || 'NA';
                eGiroObj.remittanceStatus = each.remittanceStatus || 'NA';
                eGiroObj.giroDescriptor = each.giroDescriptorName || 'NA';
                eGiroObj.eGiroStatus = each.egiroStatus || 'NA';
                var temp = angular.copy(eGiroObj);
                eGiroArray.push(temp);
                eGiroObj = {};
            });
            return eGiroArray;
        }

        function getReplicateItemsCSV(data){
            var replicateItemsArray = [];
            var replicateItemsObj = {}
            _.forEach(data, function (each, index) {
                replicateItemsObj.itemCode = each.items.itemCode || '';
                replicateItemsObj.itemName = each.items.itemName || '';
                replicateItemsObj.itemDescription = each.items.itemDescription || '';
                replicateItemsObj.itemKeyword = each.items.itemKeyword || '';
                replicateItemsObj.itemGroup = each.items.itemGroup.name || '';
                replicateItemsObj.moq = each.items.moq || '';
                replicateItemsObj.multiplierFactor = each.items.multiplierFactor || '';
                replicateItemsObj.uom = each.items.uom.name || '';
                replicateItemsObj.currency = each.items.currency || '';
                replicateItemsObj.price = each.items.price || '';
                replicateItemsObj.packaging = each.packaging || '';
                replicateItemsObj.buyerItemCode = each.buyerItemCode || '';
                replicateItemsObj.buyerItemName = each.buyerItemName || '';
                replicateItemsObj.buyerItemGroup = (each.buyerItemGroup) ? each.buyerItemGroup.name : '';
                replicateItemsObj.supplierName = each.items.supplier.companyName || '';
                var temp = angular.copy(replicateItemsObj);
                replicateItemsArray.push(temp);
                replicateItemsObj = {};
            });
            return replicateItemsArray;
        }

        function getConsolidatedCSVPO(purchaseOrderDetails) {
            var purchaseOrderCSVDataArray = [];
            var purchaseOrderCSVData = {};
            if(purchaseOrderDetails && purchaseOrderDetails.items.length > 0){
                purchaseOrderDetails.items.sort(function(a, b) {
                    if(!a.item && !b.item){
                        return a.buyerRequirement.id - b.buyerRequirement.id;
                    }
                    else{
                        return a.item.buyerRequirement.id - b.item.buyerRequirement.id;
                    }
                });
            }
            _.forEach(purchaseOrderDetails.items, function (item) {
                if (item.item) {
                    item = item.item;
                }
                purchaseOrderCSVData.PONUmber = purchaseOrderDetails.purchaseOrderNumber;
                purchaseOrderCSVData.buyerCode = item.buyerIdentificationCode ? item.buyerIdentificationCode : item.buyerRequirement.buyerUser.buyer.buyerCode;
                purchaseOrderCSVData.businessOutletName = item.buyerRequirement.buyerBusiness.businessOutletName;
                purchaseOrderCSVData.supplier = item.buyerRequirement.items.supplier.companyName;
                if (item.buyerRequirement.processOrderStatus === 'ADHOC_PO_ON_BOARD' || item.buyerRequirement.processOrderStatus === 'ADHOC_PO_OFF_BOARD') {
                    purchaseOrderCSVData.orderDate = $filter('date')(item.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                }
                else {
                    purchaseOrderCSVData.orderDate = $filter('date')(item.buyerRequirement.createdDate, "dd/MM/yyyy", timeZone);
                }
                purchaseOrderCSVData.itemCode = item.buyerRequirement.items.itemCode;
                purchaseOrderCSVData.itemName = item.buyerRequirement.items.itemName;
                if (item.buyerRequirement.glCodeMappings === null) {
                    purchaseOrderCSVData.glSpecificItemGroups = "N/A";
                }
                else {
                    purchaseOrderCSVData.glSpecificItemGroups = item.buyerRequirement.glCodeMappings.glSpecificItemGroups.name;
                }
                purchaseOrderCSVData.uom = item.buyerRequirement.items.uom.name;
                purchaseOrderCSVData.quantityRequired = item.buyerRequirement.quantity;
                purchaseOrderCSVData.quantitySupplier = item.supplierResponses ? item.supplierResponses.quantity : item.buyerRequirement.quantity;
                if (item.buyerRequirement.items.uom.weightRequired === false) {
                    purchaseOrderCSVData.weight = "N/A";
                }
                else {
                    purchaseOrderCSVData.weight = item.supplierResponses ? item.supplierResponses.weight : item.buyerRequirement.weight;
                }

                if (accessForPriceDisplay) {
                    purchaseOrderCSVData.unitPrice = item.buyerRequirement.freeItem ? 'Free Item' : item.buyerRequirement.price;
                    if(item.adhocPoGstEnabled && item.processOrderStatus == 'ADHOC_PO_OFF_BOARD'){
                        purchaseOrderCSVData.unitPrice += GLOBAL_CONSTANTS.inclusiveOfTax;
                    }
                    purchaseOrderCSVData.amountRequired = item.buyerRequirement.items.uom.weightRequired || item.buyerRequirement.freeItem ? '---' : $filter('number')(item.buyerRequirement.quantity * item.buyerRequirement.price, 2);
                    if (item.buyerRequirement.freeItem) {
                        purchaseOrderCSVData.amountSupplier = 'Free Item';
                    }
                    else{
                        purchaseOrderCSVData.amountSupplier = item.supplierAmount;
                        purchaseOrderCSVData.amountSupplier = $filter('number')(purchaseOrderCSVData.amountSupplier, 4);
                    }
                }
                purchaseOrderCSVData.deliveryDateRequired = $filter('date')(item.buyerRequirement.deliveryDate, "dd/MM/yyyy", timeZone);
                purchaseOrderCSVData.deliveryDateSupplier = $filter('date')(item.supplierResponses ? item.supplierResponses.deliveryDate : item.buyerRequirement.deliveryDate, "dd/MM/yyyy", timeZone);
                purchaseOrderCSVData.specificRequest = item.buyerRequirement.specialRequest ? item.buyerRequirement.specialRequest : '';
                purchaseOrderCSVData.purchaseOrderDate = $filter('date')(item.purchaseOrderDate, "dd/MM/yyyy", timeZone);
                purchaseOrderCSVData.remark = item.supplierResponses ? item.supplierResponses.supplierRemark : item.buyerRequirement.supplierResponse;
                var temp = angular.copy(purchaseOrderCSVData);
                purchaseOrderCSVDataArray.push(temp);
                purchaseOrderCSVData = {};
            });
            return purchaseOrderCSVDataArray;
        }

        /*
        *convert to string, to show only last 8 digits of PO number  
        */
        function formatPurchaseOrderNumber(data){            
            const txt = ''+ data.slice(-8);  
            return txt;
        }

        function getPurchaseRequestListingCSV(purchaseRequestDetails) {
            var purchaseRequestCSVDataArray = [];
            var purchaseRequestCSVData = {};
            _.forEach(purchaseRequestDetails, function (poItem, index) {
                purchaseRequestCSVData.slNo = index + 1;
                purchaseRequestCSVData.itemRequestNumber = poItem.itemRequestNumber;
                //corrently outlet name not needed in csv that 's we commenting code if in future needed outlet name we will uncomment those changes
              //  purchaseRequestCSVData.outletName = poItem.outletName;
                purchaseRequestCSVData.itemName = poItem.itemName;
                purchaseRequestCSVData.uom = poItem.uom;
                purchaseRequestCSVData.quantity = poItem.quantity;
                purchaseRequestCSVData.price = poItem.price;
                purchaseRequestCSVData.deliveryDate = $filter('date')(poItem.deliveryDate, "dd/MM/yyyy", timeZone);                
                purchaseRequestCSVData.supplier = poItem.supplierName;
                purchaseRequestCSVData.requester = poItem.requester;
                purchaseRequestCSVData.totalPrice = $filter('decimalNumber')(poItem.totalAmount , 2);
                purchaseRequestCSVData.currency = poItem.currency;
                var temp = angular.copy(purchaseRequestCSVData);
                purchaseRequestCSVDataArray.push(temp);
                purchaseRequestCSVData = {};
            });

            return purchaseRequestCSVDataArray;
        }
        function getStockNeedReportsCSVData(stockLists)
        {
        var arr=[];
        var stockListsData= stockLists.data;
        for (var index = 0; index < stockListsData.length; index++) {
            var stockData=stockListsData[index];
            var row={'#':stockData.itemId,'Category':stockData.categoryName==null?'':stockData.categoryName,'Material':stockData.itemName,'UOM':stockData.uom};
            for (var i = 0; i < stockData.outletStockDTOList.length; i++){
                const ele = stockData.outletStockDTOList[i];
                row[ele.outletName]=ele.quantity==null?'':ele.quantity;
            }
            row["Total"]=stockData.totalQuantity==null?'':stockData.totalQuantity;
            arr.push(row);
        }
        return arr;
    }


    function validateInvoiceStatus(poList, actionType) {
        var validSelection = true;
        _.forEach(poList, function (po, key) {
            if (validSelection) {
                if (po.purchaseOrder != undefined && po.purchaseOrder.invoiceStatus !== 'PROCESSED') {
                    validSelection = false;
                    toastr.error('Invoice processing for POs is still pending', '' + actionType + ' Export Error');
                }
            }
        });
        return validSelection;
        }

        function getSubscriptionManagementReportsCSVData(data, type) {
            var arr = [];
            var subscriptionReportData = data;
            for (var index = 0; index < subscriptionReportData.length; index++) {
                var csvData = subscriptionReportData[index];
                var row = {};
                if(type === 'buyer'){
                    row = {
                        'Company_Name' : csvData.companyName,
                        'Brand_Name' : csvData.brandName,
                        'Business_Outlet_Name' : csvData.businessOutletName,
                        'Outlet_Type' : csvData.outletType,
                        'Buyer_Type' : csvData.accountType,
                        'Billing_Type' : csvData.billingType,
                        'Billing_Status' : csvData.billable===true?'BILLABLE':'NON-BILLABLE',
                        'Subscription_Amount' : csvData.subscriptionAmount,
                        'Subscription_Currency': csvData.subscriptionCurrency,                    
                        'Subscription_Start_Date' : csvData.subscriptionStartDate,
                        'Subscription_End_Date' : csvData.subscriptionEndDate,
                        'Status' : csvData.active===true?'ACTIVE':'INACTIVE',
                        'Remarks' : csvData.remarks
                     };
                }else if(type === 'supplier'){
                    row = { 
                        'Company_Name' : csvData.companyName,
                        'Billing_Type' : csvData.billingType,
                        'Billing_Status' : csvData.billable===true?'BILLABLE':'NON-BILLABLE',
                        'Supplier_Type': csvData.accountType,
                        'Supplier_Visibility_Type' : csvData.supplierType,
                        'Subscription_Amount' : csvData.subscriptionAmount,
                        'Subscription_Currency': csvData.subscriptionCurrency,                   
                        'Subscription_Start_Date' : csvData.subscriptionStartDate,
                        'subscription_End_Date' : csvData.subscriptionEndDate,
                        'Status' : csvData.active===true?'ACTIVE':'INACTIVE',
                        'Remarks' : csvData.remarks
                     };
                } 
                arr.push(row);
            }
            return arr;
        }

    function validateInvoiceStatus(poList, actionType) {
        var validSelection = true;
        _.forEach(poList, function (po, key) {
            if (validSelection) {
                if (po.purchaseOrder != undefined && po.purchaseOrder.invoiceStatus !== 'PROCESSED') {
                    validSelection = false;
                    toastr.error('Invoice processing for POs is still pending', '' + actionType + ' Export Error');
                }
            }
        });
        return validSelection;
    }
        function getBuyerSubscriptionListingCSV(buyerCSVDetails) {
            var buyerCSVDataArray = [];
            var buyerCSVData = {};
            _.forEach(buyerCSVDetails, function (csvData) {
                buyerCSVData.buyerCompanyName = csvData.companyName;
                buyerCSVData.brandName = csvData.brandName;
                buyerCSVData.outletName = csvData.businessOutletName;
                buyerCSVData.billingStatus = csvData.billable===true?'BILLABLE':'NON-BILLABLE';
                buyerCSVData.billingType = csvData.billingType;
                buyerCSVData.subscriptionStartDate = $filter('date')(csvData.subscriptionStartDate, "dd/MM/yyyy", timeZone);                
                buyerCSVData.subscriptionEndDate = $filter('date')(csvData.subscriptionEndDate, "dd/MM/yyyy", timeZone);
                buyerCSVData.subscriptionAmount = csvData.subscriptionAmount;
                buyerCSVData.currency = csvData.subscriptionCurrency;
                buyerCSVData.remarks = csvData.remarks;
                var temp = angular.copy(buyerCSVData);
                buyerCSVDataArray.push(temp);
                buyerCSVData = {};
            });

            return buyerCSVDataArray;
        }

        function getSupplierSubscriptionListingCSV(supplierCSVDetails) {
            var supplierCSVDataArray = [];
            var supplierCSVData = {};
            _.forEach(supplierCSVDetails, function (csvData) {
                supplierCSVData.supplierCompanyName = csvData.companyName;
                supplierCSVData.billingStatus = csvData.billable===true?'BILLABLE':'NON-BILLABLE';
                supplierCSVData.billingType = csvData.billingType;                
                supplierCSVData.subscriptionStartDate = $filter('date')(csvData.subscriptionStartDate, "dd/MM/yyyy", timeZone);                
                supplierCSVData.subscriptionEndDate = $filter('date')(csvData.subscriptionEndDate, "dd/MM/yyyy", timeZone);
                supplierCSVData.subscriptionAmount = csvData.subscriptionAmount;
                supplierCSVData.currency = csvData.subscriptionCurrency;
                supplierCSVData.remarks = csvData.remarks;
                var temp = angular.copy(supplierCSVData);
                supplierCSVDataArray.push(temp);
                supplierCSVData = {};
            });

            return supplierCSVDataArray;
        }

        function getSQLCSV(poDetails) {
            var arr = [];
            var currentDocNo = null;
            var seqCounter = 1;  
            for (var i = 0; i < poDetails.length; i++) {
                var excelData = poDetails[i];
                seqCounter = (excelData.docNum !== currentDocNo) ? (currentDocNo = excelData.docNum, 1) : seqCounter + 1;       
                var row = {
                    'DocDate': $filter('date')(excelData.docDate, "dd/MM/yyyy", timeZone),
                    'DocNo(20)': excelData.docNum,
                    'Code(10)': excelData.vendorCode,
                    'CompanyName(100)': excelData.vendorName,
                };
                row['Description_HDR(200)'] =  excelData.descriptionHDR !== "null" ? excelData.descriptionHDR : "Purchase (Imported)";
                row['SEQ'] = seqCounter;
                row['ItemCode(30)'] = excelData.buyerItemCode !== "null"?excelData.buyerItemCode:excelData.itemCode;
                row['Description_DTL(200)'] = excelData.descriptionDTL;
                row['QTY'] = $filter('showsDecimal')(excelData.quantity);
                row['UOM(10)'] = excelData.uomName;
                row['UnitPrice'] = excelData.price;
                row['Amount'] = $filter('decimalNumber')(excelData.quantity * excelData.price, 2);
                row['Tax(10)'] = excelData.taxClass !== 1 ? "NR" : (excelData.gst != null ? "TX" : "NR");
                row['TaxInclusive'] = 0;
                row['TaxAmt'] = excelData.taxClass === 1 ? $filter('decimalNumber')(row['Amount'] * (excelData.gst / 100), 2) : 0;
        
                arr.push(row);
            }
            return arr;
        }
        function getAutoCountCSV(poDetails) {
            var arr = [];
        
            for (var i = 0; i < poDetails.length; i++) {
                var excelData = poDetails[i];
        
                // Creating row object with mapped fields from excelData
                var row = {
                    docNo: "<<New>>",  // Document Number
                    docDate: $filter('date')(excelData.docDate, "dd/MM/yyyy", timeZone)|| "",  // Document Date
                    creditorCode: excelData.creditorCode || "", // Creditor Code
                    supplierInvoiceNo: excelData.supplierInvoiceNo || "", // Supplier Invoice Number
                    journalType: excelData.journalType || "", // Journal Type (AP-INV or AP-CN)
                    displayTerm: excelData.displayTerm || "", // Display Term (e.g., Net 30 days)
                    purchaseAgent: excelData.purchaseAgent || "", // Purchase Agent
                    description:"PURCHASE INVOICE" ,
                    currencyRate: excelData.currencyRate || "", // Currency Rate
                    refNo2: excelData.refNo2 || "", // Reference Number 2
                    note: excelData.note || "", // Notes
                    inclusiveTax: excelData.inclusiveTax || false, // Inclusive Tax (Boolean)
                    accNo: excelData.accNo || "", // Account Number
                    toAccountRate: (excelData.toAccountRate ? parseFloat(excelData.toAccountRate).toFixed(2) : ""), // To Account Rate with 2 decimal places
                    detailDescription:(excelData.description || ""), // Detailed Description
                    projNo: excelData.projNo || "", // Project Number
                    deptNo: excelData.deptNo || "", // Department Number
                    taxType: excelData.taxType || "", // Tax Type
                    taxableAmt:excelData.taxType=="TX"?excelData.poAmount:excelData.grnAmount, // Taxable Amount
                    taxAdjustment: excelData.taxAdjustment || "", // Tax Adjustment
                    amount: excelData.amount || "", // Amount
                };
        
                // Add row to array
                arr.push(row);
            }
        
            return arr;
        }
        

        
    }
})();
